import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../api.js';
import UserContext from './UserContext.js';

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const { isLoggedIn } = useContext(UserContext);
    const [notificationsArray, setNotificationsArray] = useState([]);

    const connect = () => {
        const newSocket = new WebSocket(
            process.env.REACT_APP_WS_URL + '/ws/notifications/'
        );
        newSocket.onopen = () => {
            setSocket(newSocket);
        };
    };

    const emit = (eventName, data) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ event: eventName, data }));
        }
    };

    const messageListener = eventListeners => {
        if (socket)
            socket.addEventListener('message', event => {
                const data = JSON.parse(event.data);
                const matchingListener = eventListeners[data.event];
                if (matchingListener) {
                    matchingListener(data);
                }
            });
    };

    const fetchNotifications = async () => {
        try {
            const response = await api.get('/users/notifications/');
            setNotificationsArray(response.data.notifications);
        } catch (error) {
            console.error('fetchNotifications -> error', error);
        }
    };


    useEffect(() => {
        if (isLoggedIn === false) return;
        connect();
        fetchNotifications();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [isLoggedIn]);

    function disconnectNotifications() {
        if (socket) {
            socket.close();
            setSocket(null);
        }
    }

    return (
        <SocketContext.Provider
            value={{
                socket,
                connect,
                emit,
                messageListener,
                notificationsArray,
                setNotificationsArray,
                disconnectNotifications,
            }}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketContext;
