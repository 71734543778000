import '../../../../App.css';
import React, { useContext, useState } from 'react';
import styles from '../../Game.module.css';
import TicTacToe from '../../components/TicTacToe/TicTacToe';
import OpponentsPanel from '../../components/OpponentsPanel/OpponentsPanel';
import GameLogs from '../../components/GameLogs/GameLogs';
import UserContext from '../../../../context/UserContext';
import mobileStyle from '../PingPongOnline/PingPongOnline.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function TicTacToeLocal() {
    const [userScore, setUserScore] = useState(0);
    const [comScore, setComScore] = useState(0);
    const [history, setHistory] = useState([]);
    const { selectedBackground } = useContext(UserContext);
    const colorRegex = /rgba\(\s*(\d+),\s*(\d+),\s*(\d+),\s*([0-9.]+)\s*\)/;
    const match = colorRegex.exec(selectedBackground);
    const { t } = useTranslation(['game', 'common']);
    const navigate = useNavigate();
    const [round, setRound] = useState(1);

    
    return (
        <div className={styles.games}>
            <div className={`${styles.gamesContainer} background_secondary`}>
                <div className={styles.gamesAreaHolder}>
                    <div
                        className={`${styles.gameArea}`}
                        style={{
                            background: selectedBackground,
                            border: `2px solid rgba(${match[1]}, ${match[2]}, ${match[3]}, 0.5)`
                        }}>
                        <div
                            className={`${styles.gameAreaBanner} ${styles.gameAreaBannerTicTacToe}`}>
                            <div>
                                <p className={styles.time}>{round}</p>

                                <p className={styles.time_remaining}>
                                    {t('game:tic_tac_toe_round')}
                                </p>
                            </div>
                        </div>
                        <TicTacToe
                            setUserScore={setUserScore}
                            setComScore={setComScore}
                            userScore={userScore}
                            comScore={comScore}
                            setHistory={setHistory}
                            history={history}
                            round={round}
                            setRound={setRound}
                        />
                    </div>
                </div>
                <div className={styles.panel_holder}>
                    <div className={`${styles.panel} background_primary`}>
                        <h3 className={styles.panel_title}>{t('common:tictactoe')}</h3>
                        <p className={styles.panel_subtitle}>{t('game:match_recap')}</p>
                        <OpponentsPanel userScore={userScore} comScore={comScore} />
                        <GameLogs history={history} />
                        <button
                            className={styles.panel_button}
                            onClick={e => {
                                e.preventDefault();
                                navigate('/play');
                            }}>
                            {t('game:give_up')}
                        </button>
                    </div>
                </div>
                <div className={mobileStyle.panel_button_mobile_container}>
                    <button
                        className={mobileStyle.panel_button_mobile}
                        onClick={e => {
                            e.preventDefault();
                            navigate('/play');
                        }}>
                        {t('game:give_up')}
                    </button>
                </div>
            </div>
        </div>
    );
}
