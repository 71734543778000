import '../../../../App.css';
import React, { useContext, useState } from 'react';
import styles from '../../Game.module.css';
import mobileStyles from '../PingPongOnline/PingPongOnline.module.css';
import GameLogs from '../../components/GameLogs/GameLogs';
import PingPong from '../../components/PingPong/PingPong';
import UserContext from '../../../../context/UserContext';
import OpponentsPanel from '../../components/OpponentsPanel/OpponentsPanel';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PingPongLocal() {
    const [userScore, setUserScore] = useState(0);
    const [comScore, setComScore] = useState(0);
    const [history, setHistory] = useState([]);
    const navigate = useNavigate();
    const { selectedBackground } = useContext(UserContext);

    const { t } = useTranslation(['game', 'common']);

    return (
        <div className={styles.games}>
            <div className={`${styles.gamesContainer} background_secondary`}>
                <div className={styles.gamesAreaHolder}>
                    <div
                        className={styles.gameArea}
                        style={{
                            background: `${selectedBackground}`,
                            display: 'flex',
                            height: '100%',
                            width: '100%'
                        }}>
                        <div
                            className={`${styles.gameAreaBanner} ${styles.gameAreaBannerTicTacToe}`}>
                            <div>
                                <p className={styles.time}>{t('common:local')}</p>
                                <p className={styles.time_remaining}>
                                    {t('common:pingpong')}
                                </p>
                            </div>
                        </div>
                        <PingPong
                            setUserScore={setUserScore}
                            setComScore={setComScore}
                            userScore={userScore}
                            comScore={comScore}
                            setHistory={setHistory}
                            history={history}
                        />
                    </div>
                </div>
                <div className={styles.panel_holder}>
                    <div className={`${styles.panel} background_primary`}>
                        <h3 className={styles.panel_title}>{t('common:pingpong')}</h3>
                        <p className={styles.panel_subtitle}>{t('game:match_recap')}</p>
                        <OpponentsPanel userScore={userScore} comScore={comScore} />
                        <GameLogs history={history} />
                        <button
                            className={styles.panel_button}
                            onClick={e => {
                                e.preventDefault();
                                navigate('/play');
                            }}>
                            {t('game:game_exit')}
                        </button>
                    </div>
                </div>
                <div className={mobileStyles.panel_button_mobile_container}>
                    <button
                        className={mobileStyles.panel_button_mobile}
                        onClick={() => {
                            navigate('/play');
                        }}>
                        {t('game:give_up')}
                    </button>
                </div>
            </div>
        </div>
    );
}
