import '../../../../App.css';
import React, { useState } from 'react';
import classes from './HostTournament.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import TabLayoutSelector from '../../../../components/TabLayoutSelector/TabLayoutSelector';
import api from '../../../../api';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function HostTournament({ Host, setHost }) {
    const banners = [
        {
            id: 0,
            src: 'https://g.top4top.io/p_3120ivnx31.jpg',
            alt: 'banner'
        },
        {
            id: 1,
            src: 'https://c.top4top.io/p_3120jby491.jpeg',
            alt: 'banner'
        },
        {
            id: 2,
            src: 'https://d.top4top.io/p_3120umhgn2.jpeg',
            alt: 'banner'
        }
    ];

    const { t } = useTranslation(['tournaments', 'common']);
    const [selectedGame, setSelectedGame] = useState(0);
    const [tournamentName, setTournamentName] = useState('');
    const [nickname, setNickname] = useState('');
    const [bannerIndex, setBannerIndex] = useState(0);
    const [bannerUrl, setBannerUrl] = useState(banners[0].src);

    return (
        <div className={`${classes.HostTournament} background_primary`}>
            <div className={classes.title_container}>
                <h1>{t('tournaments:host_tournament')}</h1>
                <Imagify
                    onClick={() => {
                        setHost(!Host);
                    }}
                    src='/assets/icons/ic_exit.svg'
                    alt='exit'
                />
            </div>
            <div className={classes.banner_selector_container}>
                <div className={classes.banner_selector_images}>
                    {banners.map(banner => (
                        <Imagify
                            id={banner.id}
                            src={banner.src}
                            alt={banner.alt}
                            key={banner.id}
                        />
                    ))}
                </div>
                <a
                    href={'#' + bannerIndex}
                    className={classes.banner_selector_left}
                    onClick={() => {
                        if (bannerIndex > 0) {
                            setBannerIndex(bannerIndex - 1);
                            setBannerUrl(banners[bannerIndex - 1].src);
                        } else {
                            setBannerIndex(banners.length - 1);
                            setBannerUrl(banners[bannerIndex].src);
                        }
                    }}>
                    <Imagify src='/assets/icons/ic_scroll_arrow.svg' alt='arrow' />
                </a>
                <a
                    href={'#' + bannerIndex}
                    onClick={() => {
                        if (bannerIndex < banners.length - 1) {
                            setBannerIndex(bannerIndex + 1);
                            setBannerUrl(banners[bannerIndex + 1].src);
                        } else {
                            setBannerIndex(0);
                            setBannerUrl(banners[bannerIndex].src);
                        }
                    }}
                    className={classes.banner_selector_right}>
                    <Imagify src='/assets/icons/ic_scroll_arrow.svg' alt='arrow' />
                </a>
            </div>
            <div className={classes.form_container}>
                <h1 className={classes.option_label}>
                    {t('tournaments:tournament_title')}
                </h1>
                <div className={classes.input_box}>
                    <Imagify src='/assets/icons/ic_joystick.svg' alt='Joystick' />
                    <input
                        maxLength={20}
                        type='text'
                        value={tournamentName}
                        onChange={e => {
                            setTournamentName(e.target.value);
                        }}
                    />
                </div>
                <h1 className={classes.option_label}>
                    {t('tournaments:choose_nickname')}
                </h1>
                <div className={classes.input_box}>
                    <Imagify src='/assets/icons/ic_joystick.svg' alt='Joystick' />
                    <input
                        maxLength={20}
                        type='text'
                        value={nickname}
                        onChange={e => {
                            setNickname(e.target.value);
                        }}
                    />
                </div>

                <h1 className={classes.option_label}>
                    {t('tournaments:game_type_game')}
                </h1>
                <TabLayoutSelector
                    optionOneTitle={t('common:pingpong')}
                    optionOneIcon={'ic_pingpong'}
                    optionTwoTitle={t('common:tictactoe')}
                    optionTwoIcon={'ic_tictactoe'}
                    setSelectedOption={setSelectedGame}
                    orientation={'h'}
                />
            </div>

            <button
                className={classes.panel_button}
                onClick={e => {
                    e.preventDefault();
                    createTournament(
                        tournamentName,
                        selectedGame,
                        bannerUrl,
                        setHost,
                        t,
                        nickname
                    );
                }}>
                {t('common:create')}
            </button>
        </div>
    );
}

export async function createTournament(
    tournamentName,
    selectedGame,
    bannerUrl,
    setHost,
    t,
    nickname
) {
    await api
        .post('/tournaments/create_tournament/', {
            name: tournamentName,
            game: selectedGame,
            background_image: bannerUrl,
            userNickname: nickname
        })
        .then(() => {
            setHost(false);
        })
        .catch(error => {
            if (error.response.status === 400) {
                toast.error(t('tournaments:tournament_invalid_name'));
            } else if (error.response.status === 409) {
                toast.error(t('tournaments:tournament_already_exists'));
            } else {
                toast.error(
                    t('common:server_error', { reason: error.response.data.message })
                );
            }
        });
}

export default HostTournament;
