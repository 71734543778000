import '../../../../App.css';
import React, { useContext } from 'react';
import classes from './GameCustomize.module.css';
import UserContext from '../../../../context/UserContext';
import { useTranslation } from 'react-i18next';
import FriendCardInfo from '../../../../components/FriendCardInfo/FriendCardInfo';
import BackgroundSelector from './components/BackgroundSelector/BackgroundSelector';
import PreviewGameBoy from '../PreviewGameBoy/PreviewGameBoy';
import NoDataView from '../../../../components/NoDataView/NoDataView';
import TabLayoutSelector from '../../../../components/TabLayoutSelector/TabLayoutSelector';

export default function GameCustomize() {
    const {
        friendsList,
        isOnlineGame,
        setIsOnlineGame,
        selectedGame,
        setSelectedGame,
        setSelectedGameConnectivity,
        selectedGameConnectivity,
        selectedBackground,
        setSelectedBackground,
        setStartGame
    } = useContext(UserContext);
    const { t } = useTranslation(['common', 'game']);

    return (
        <div
            className={`${classes.container}  ${isOnlineGame ? classes.borderOnline : classes.borderOffline}`}>
            <div className={classes.title_offline_online}>
                <h1 className={classes.customizeTitle}>{t('game:customize_game')}</h1>
            </div>

            <div className={classes.selectorContainer}>
                <TabLayoutSelector
                    optionOneTitle={''}
                    optionTwoTitle={''}
                    optionOneIcon={'ic_online'}
                    optionTwoIcon={'ic_offline'}
                    setSelectedOption={setSelectedGameConnectivity}
                    selectedOption={selectedGameConnectivity}
                    optionType={'online'}
                    orientation={'h'}
                    extraClass={classes.maxWidthOnline}
                    setIsOnlineGame={setIsOnlineGame}
                />
                <TabLayoutSelector
                    optionOneTitle={t('pingpong')}
                    optionTwoTitle={t('tictactoe')}
                    optionOneIcon={'ic_pingpong'}
                    optionTwoIcon={'ic_tictactoe'}
                    setSelectedOption={setSelectedGame}
                    selectedOption={selectedGame}
                    optionType={'game'}
                    orientation={'h'}
                    extraClass={classes.maxWidthGames}
                />
            </div>
            <h1 className={classes.titleText}>{t('game:choose_board')}</h1>
            <BackgroundSelector
                Selected={selectedBackground}
                setSelected={setSelectedBackground}
            />
            <div className={classes.friendAndPreview}>
                <div className={classes.friends}>
                    <h1 className={classes.titleText}>{t('game:invite_friend')}</h1>
                    <div className={classes.friendsContainer}>
                        {friendsList.length > 0 ? (
                            friendsList.map((friend) => (
                                <FriendCardInfo
                                    friendInfo={friend}
                                    isFriendRequest={false}
                                    hideMessageButton={true}
                                    gameType={selectedGame}
                                    gameBackground={selectedBackground}
                                    setStartGame={setStartGame}
                                    key={friend.id}
                                />
                            ))
                        ) : (
                            <NoDataView
                                title={t('common:no_friends')}
                                subtitle={t('common:no_friends_desc')}
                            />
                        )}
                    </div>
                </div>

                <div className={classes.line}></div>

                <div className={classes.previewContainer}>
                    <h1 className={classes.titleText}>{t('game:final_game')}</h1>
                    <div className={classes.preview}>
                        <div className={classes.gameBoyContainer}>
                            {selectedGame === 'PingPong' ? (
                                <img
                                    src='/assets/icons/ic_pingpong.svg'
                                    alt='Ping Pong'
                                    className={classes.logoIcon}></img>
                            ) : (
                                <img
                                    src='/assets/icons/ic_tictactoe.svg'
                                    alt='Tic Tac Toe'
                                    className={classes.logoIcon}></img>
                            )}
                            <div className={classes.gameBoyView}>
                                <PreviewGameBoy
                                    game_type={selectedGame}
                                    theme={selectedBackground}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
