import '../../../../../App.css';
import React from 'react';
import classes from './UserRankView.module.css';
import Imagify from '../../../../../components/Imagify/Imagify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { enUS, fr, es, ar } from 'date-fns/locale';

const localeMap = {
    en: enUS,
    fr: fr,
    es: es,
    ar: ar
}

export default function UserRankView({ player, rank }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const locale = localeMap[i18n.language] || enUS;

    return (
        <div onClick={() => navigate(`/dashboard/${player.username}`)}>
            <li
                className={`${classes.ListItem} ${rank === 0 ? classes.firstPlace : rank === 1 ? classes.secondPlace : rank === 2 ? classes.thirdPlace : classes.rest}`}>
                <Imagify alt='user' src={player.profile_picture} />
                <div className={classes.PlayerInfo}>
                    <p>{player.full_name}</p>
                    <p>
                        {player
                            ? t('user_exp_date', {
                                  exp: player.exp,
                                  time: formatDistanceToNow(
                                      new Date(player.last_activity),
                                      {
                                          addSuffix: true,
                                          locale: locale
                                      }
                                  )
                              })
                            : ''}
                    </p>
                </div>
                {rank === 0 ? (
                    <Imagify
                        className={classes.medal}
                        alt='rank'
                        src='/assets/icons/ic_rank_gold.svg'
                    />
                ) : rank === 1 ? (
                    <Imagify
                        className={classes.medal}
                        alt='rank'
                        src='/assets/icons/ic_rank_silver.svg'
                    />
                ) : rank === 2 ? (
                    <Imagify
                        className={classes.medal}
                        alt='rank'
                        src='/assets/icons/ic_rank_bronze.svg'
                    />
                ) : (
                    <div className={classes.RankingNumber} alt='rank'>
                        <h1>{rank + 1}</h1>
                    </div>
                )}
            </li>
        </div>
    );
}
