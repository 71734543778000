import '../../App.css';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './FriendCardInfo.module.css';
import api from '../../api';
import UserContext from '../../context/UserContext';
import Imagify from '../Imagify/Imagify';
import ChatSocketContext from '../../context/ChatSocketContext';
import SocketContext from '../../context/SocketContext';
import Pending from './Pending/Pending';
import TimeTracker from './TimeTracker/TimeTracker';
import { formatDistanceToNow } from 'date-fns';

function FriendCardInfo({ friendInfo, isFriendRequest, hideMessageButton = false }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        setFriendRequests,
        friendsList,
        playingWith,
        setPlayingWith,
        setSelectedGameConnectivity,
        setIsOnlineGame,
        user
    } = useContext(UserContext);
    const { emit, conversationsList, setConversationsList } =
        useContext(ChatSocketContext);

    const { setNotificationsArray } = useContext(SocketContext);

    const [unseenMessagesArray, setUnseenMessagesArray] = useState([]);

    useEffect(() => {
        api.get('/chat/conversations/')
            .then(res => {
                setConversationsList(res.data);
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (conversationsList.length > 0) {
            const unseenMessages = conversationsList.map(conversation => {
                return {
                    unseen_messages: conversation.conversation.unseen_messages,
                    participant: conversation.conversation.participant
                };
            });
            setUnseenMessagesArray(unseenMessages);
        }
    }, [conversationsList]);

    const friendUnseenMessages = unseenMessagesArray.find(
        conversation => conversation.participant.username === friendInfo.username
    )?.unseen_messages;

    return (
        <div
            className={`${classes.friend_item_container} ${isFriendRequest ? classes.animation : ''} ${playingWith && playingWith.username === friendInfo.username ? classes.selected : ''}`}>
            <Imagify
                src={friendInfo && friendInfo.profile_picture}
                alt='profile'
                className={classes.friend_profile_img}
                onClick={e => {
                    e.preventDefault();
                    navigate(`/dashboard/${friendInfo && friendInfo.username}`);
                }}
            />

            <div
                style={{
                    background: friendInfo && friendInfo.is_online ? '#00d315' : '#d2d2d2'
                }}
                className={classes.dropdown__content__status}></div>

            <div
                className={classes.friend_info_container}
                onClick={e => {
                    e.preventDefault();
                    navigate(`/dashboard/${friendInfo && friendInfo.username}`);
                }}>
                <h1 className={classes.friend_name}>
                    {friendInfo && friendInfo.full_name}
                </h1>
                {isFriendRequest ? (
                    <TimeTracker timestamp={friendInfo && friendInfo.timestamp} />
                ) : (
                    <p className={classes.friend_experience}>
                        {friendInfo
                            ? t('user_exp_date', {
                                  exp: friendInfo.experience,
                                  time: formatDistanceToNow(new Date(friendInfo.last_activity), {
                                        addSuffix: true
                                    })
                              })
                            : ''}
                    </p>
                )}
            </div>

            {friendInfo && friendInfo.username !== user.username && (
                <div className={classes.friend_action_container}>
                    {isFriendRequest &&
                    friendInfo &&
                    friendInfo.notify_type === 'Pending' ? (
                        <Pending />
                    ) : (
                        <img
                            onClick={e => {
                                e.preventDefault();
                                if (isFriendRequest) {
                                    acceptF(
                                        friendInfo,
                                        setFriendRequests,
                                        emit,
                                        setNotificationsArray
                                    );
                                } else {
                                    if (playingWith === null) {
                                        setPlayingWith(friendInfo);
                                        setSelectedGameConnectivity('online');
                                        setIsOnlineGame(true);
                                    } else {
                                        setPlayingWith(null);
                                    }
                                    navigate('/play');
                                }
                            }}
                            src={`${isFriendRequest ? '/assets/icons/ic_done.svg' : '/assets/icons/ic_challenge.svg'}`}
                            alt={`${isFriendRequest ? 'accept friend request' : 'challenge'}`}
                        />
                    )}
                    {hideMessageButton === false && (
                        <div className={classes.message_button_container}>
                            <img
                                onClick={e => {
                                    e.preventDefault();
                                    if (isFriendRequest)
                                        rejectF(
                                            friendInfo,
                                            setFriendRequests,
                                            setNotificationsArray
                                        );
                                    else navigate(`/messages/${friendInfo.username}`);
                                }}
                                src={`${isFriendRequest ? '/assets/icons/ic_close.svg' : '/assets/icons/ic_chat_friend.svg'}`}
                                alt={`${isFriendRequest ? 'remove friend request' : 'message'}`}
                            />
                            {friendUnseenMessages > 0 &&
                                friendInfo &&
                                friendsList.find(
                                    friend => friend.username === friendInfo.username
                                ) && (
                                    <span className={classes.unseen_message_count}>
                                        {friendUnseenMessages}
                                    </span>
                                )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export async function rejectF(friendInfo, setFriendRequests, setNotificationsArray) {
    await api
        .post('/users/friends-request/handle/', {
            action: 'REJECT',
            sender: friendInfo.username,
            id: friendInfo.id
        })
        .then(() => {
            document.querySelector(`.${classes.friend_item_container}`);
            setNotificationsArray(prevResults =>
                prevResults.filter(request => request.id !== friendInfo.id)
            );
            setFriendRequests(prevResults =>
                prevResults.filter(request => request.id !== friendInfo.id)
            );
        })
        .catch(error => {
            console.error('Reject friend request error: ', error);
        });
}

export async function acceptF(
    friendInfo,
    setFriendRequests,
    emit,
    setNotificationsArray
) {
    await api
        .post('/users/friends-request/handle/', {
            action: 'ACCEPT',
            sender: friendInfo.username,
            id: friendInfo.id
        })
        .then(() => {
            emit('create_conversation', {
                receiver: friendInfo.username
            });
            setNotificationsArray(prevResults =>
                prevResults.filter(request => request.id !== friendInfo.id)
            );
            setFriendRequests(prevResults =>
                prevResults.filter(request => request.id !== friendInfo.id)
            );
        })
        .catch(error => {
            console.error('Accept friend request error: ', error);
        });
}

export default FriendCardInfo;
