import classes from './LevelContainer.module.css';
import api from '../../../../api';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/FriendsButton/FriendsButton';
import ChatSocketContext from '../../../../context/ChatSocketContext';

export default function LevelContainer({ usernameSearchQuery }) {
    const { user, userData, friendsList, userFriendsList } = useContext(UserContext);
    const { emit } = useContext(ChatSocketContext);
    const { t } = useTranslation(['dashboard', 'common']);
    const navigate = useNavigate();
    const [isFriend, setIsFriend] = useState(false);

    const handelclick = e => {
        e.preventDefault();
        navigate(`/messages/${userData.username}`);
    };

    useEffect(() => {
        if (!userData) return;
        if (friendsList.some(friend => friend.username === userData.username))
            setIsFriend(true);
        else setIsFriend(false);
    }, [userData, friendsList]);

    const profileData = userData && usernameSearchQuery !== 'me' ? userData : user;

    return (
        <div className={classes.container}>
            <div className={classes.user_level_container}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        color: 'white'
                    }}>
                    <h1>
                        {t('lvl_cover', {
                            level: profileData && profileData.level,
                            exp: profileData && profileData.experience
                        })}
                    </h1>{' '}
                    {/* current level */}
                    <p>
                        {t('lvl_cover', {
                            level: profileData && profileData.level + 1,
                            exp: profileData && 1000 * profileData.level
                        })}
                    </p>{' '}
                    {/* next level */}
                </div>
                <div className={classes.user_level_progress}>
                    <div
                        className={classes.user_level_progress_bar}
                        style={{
                            '--final-width': `${user && user.progress_percentage}%`
                        }}
                    />
                </div>
            </div>
            {usernameSearchQuery !== 'me' &&
            usernameSearchQuery !== (user && user.username) &&
            userData ? (
                <div className={classes.buttons_container}>
                    <Button
                        Color1={`${isFriend ? '#FE4343' : '#4441ba'}`}
                        Color2={`${isFriend ? '#FE4343' : '#4441ba'}`}
                        Icon={`${isFriend ? '/assets/icons/ic_remove_friend.svg' : '/assets/icons/ic_add_friend.svg'}`}
                        Text={`${isFriend ? t('remove_friend') : t('send_friend_request')}`}
                        onClick={() => {
                            if (
                                friendsList.some(
                                    friend => friend.username === userData.username
                                )
                            ) {
                                removeFriend(userData && userData.username, emit, user);
                                userFriendsList.splice(
                                    userFriendsList.indexOf(userData.username),
                                    1
                                );
                            } else sendFriendRequest(userData && userData.id);
                        }}
                    />
                    <Button
                        Color2={'#5c5c5c'}
                        Color1={'#5c5c5c'}
                        Icon={'/assets/icons/ic_chat.svg'}
                        Text={t('send_message')}
                        onClick={handelclick}
                    />
                </div>
            ) : null}
        </div>
    );
}

export async function sendFriendRequest(id) {
    await api
        .post('users/send-friend-request/', {
            username: id
        })
        .then(() => {})
        .catch(error => {
            console.error('Error sending friend request: ', error);
        });
}

export async function removeFriend(Username, emit, user) {
    await api
        .post('/users/friends-remove/', {
            username: Username
        })
        .then(() => {
            emit('remove_conversation', { sender: user.username, participant: Username });
        })
        .catch(error => {
            console.error('Error removing friend: ', error);
        });
}
