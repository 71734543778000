import React from 'react';
import styles from './UsersAdminCard.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import { formatDistanceToNow } from 'date-fns';
import { enUS, fr, es, ar } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const localeMap = {
    en: enUS,
    fr: fr,
    es: es,
    ar: ar
};

export default function UsersAdminCard({ user }) {
    const { i18n } = useTranslation();
    const locale = localeMap[i18n.language] || enUS;

    return (
        <div
            className={styles.container}
            onClick={() => {
                window.open('https://profile.intra.42.fr/users/' + user.username);
            }}>
            <Imagify
                className={styles.picture}
                src={user && user.profile_picture}
                alt={user.username}
            />
            <div className={styles.user_data_view}>
                <h1 className={styles.full_name}>{user && user.full_name}</h1>
                <p>
                    {user && 'Joined ' +
                        formatDistanceToNow(new Date(user.date_joined), {
                            addSuffix: true,
                            locale: locale
                        })}
                </p>
            </div>
        </div>
    );
}
