import '../../../../App.css';
import React, { useEffect, useState, useContext } from 'react';
import classes from './Ranking.module.css';
import TabLayout from '../../../../components/TabLayout/TabLayout';
import UserRankView from './componenets/UserRankView';
import api from '../../../../api';
import Imagify from '../../../../components/Imagify/Imagify';
import UserContext from '../../../../context/UserContext';
import Skeleton from 'react-loading-skeleton';
import ShimmerList from '../../../../components/ShimmerList/ShimmerList';
import { useTranslation } from 'react-i18next';

function Ranking() {
    const { user } = useContext(UserContext);
    const [players, setPlayers] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const { t, i18n } = useTranslation(['tournaments', 'common']);
    const [dir, setDir] = useState('ltr');

    useEffect(() => {
        const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
        setDir(direction);
    }, [i18n.language]);

    useEffect(() => {
        async function fetchPlayers() {
            try {
                const response = await api.get('/tournaments/get_rankings/');
                setPlayers(response.data);
            } catch (error) {
                console.error('Failed to fetch players:', error);
            }
        }
        fetchPlayers();
    }, []);

    if (!players) {
        return (
            <div className={`${classes.Ranking} background_primary`}>
                <div className={classes.RankingTitle}>
                    <h1>{t('tournaments:ranking_title')}</h1>
                </div>

                <div style={{ width: '100%', marginTop: '1.5rem' }}>
                    <Skeleton
                        height={40}
                        width='100%'
                        borderRadius={60}
                        style={{ marginBottom: '1.5rem' }}
                    />
                    <ShimmerList count={10} />
                </div>

                <div className={classes.MyRanking}>
                    <li className={classes.ListItem}>
                        <Imagify alt='profile' src={user && user.profile_picture} />
                        <div className={classes.PlayerInfo}>
                            <p>{user && `${user.first_name} ${user.last_name}`}</p>
                            <p>{t('common:user_exp', { exp: user.game_pingpong_exp })}</p>
                        </div>
                        <div className={classes.RankingNumber}>
                            <h1>_</h1>
                        </div>
                    </li>
                </div>
            </div>
        );
    }

    return (
        <div className={`${classes.Ranking} background_primary`}>
            <div className={classes.RankingTitle} dir={dir}>
                <h1>{t('tournaments:ranking_title')}</h1>
            </div>
            <TabLayout
                firstTab={t('common:pingpong')}
                secondTab={t('common:tictactoe')}
                ClassName={'mtb-20'}
                selectedTab={activeTab}
                setSelectedTab={setActiveTab}
            />
            <div className={classes.RankingList} dir={dir}>
                <div className={classes.BestPlayers}>
                    <p
                        style={{
                            color: '#746f88',
                            fontSize: '1rem',
                            margin: '5px 5px 10px 5px'
                        }}>
                        {t('tournaments:best_players')}
                    </p>
                    {activeTab === 1
                        ? fetchTopPlayers({ game_type: 'pingpong', players })
                        : fetchTopPlayers({ game_type: 'tictactoe', players })}
                </div>
                <div className={classes.BestPlayers}>
                    <p
                        style={{
                            color: '#746f88',
                            fontSize: '1rem',
                            margin: '25px 5px 10px 5px'
                        }}>
                        {t('tournaments:rest_of_players')}
                    </p>

                    {activeTab === 1
                        ? fetchRestOfPlayers({ game_type: 'pingpong', players })
                        : fetchRestOfPlayers({ game_type: 'tictactoe', players })}
                </div>
            </div>
            <div className={classes.MyRanking}>
                <li className={classes.ListItem}>
                    <Imagify alt='profile' src={user && user.profile_picture} />
                    <div className={classes.PlayerInfo}>
                        <p>{user && `${user.first_name} ${user.last_name}`}</p>
                        {activeTab === 1 ? (
                            <p>{t('common:user_exp', { exp: user.game_pingpong_exp })}</p>
                        ) : (
                            <p>
                                {t('common:user_exp', { exp: user.game_tictactoe_exp })}
                            </p>
                        )}
                    </div>
                    <div className={classes.RankingNumber}>
                        {activeTab === 1 ? (
                            <h1>{players.my_current_ranks.pingpong}</h1>
                        ) : (
                            <h1>{players.my_current_ranks.tictactoe}</h1>
                        )}
                    </div>
                </li>
            </div>
        </div>
    );
}

function fetchTopPlayers({ game_type, players }) {
    const playersToShow = players[game_type].top_players.map((player, index) => (
        <UserRankView player={player} rank={index} key={index} />
    ));
    return playersToShow;
}

function fetchRestOfPlayers({ game_type, players }) {
    const playersToShow = players[game_type].other_players.map((player, index) => (
        <UserRankView player={player} rank={index + 3} key={index} />
    ));
    return playersToShow;
}

export default Ranking;
