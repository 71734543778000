import '../../../../App.css';
import React, { useContext, useState, useEffect } from 'react';
import OpponentsPanel from '../../components/OpponentsPanel/OpponentsPanel';
import GameLogs from '../../components/GameLogs/GameLogs';
import UserContext from '../../../../context/UserContext';
import GameContext from '../../../../context/GameContext';
import TicTacToeO from '../../components/TicTacToeO/TicTacToeO';
import TimerDown from '../PingPongOnline/TimerDown/TimerDown';
import styles from '../PingPongOnline/PingPongOnline.module.css';
import BeforeGameGameBoy from '../../components/BeforeGameGameBoy/BeforeGameGameBoy';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function TicTacToeOnline() {
    // eslint-disable-next-line no-unused-vars
    const [userScore, setUserScore] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [comScore, setComScore] = useState(0);
    const [history, setHistory] = useState([]);
    const { selectedBackground } = useContext(UserContext);
    const { gameSocket, GameListener, setOpponent, emitGame, requestGame } =
        useContext(GameContext);
    const { t } = useTranslation(['game', 'common']);
    const { user, setSelectedGame } = useContext(UserContext);
    const colorRegex = /rgba\(\s*(\d+),\s*(\d+),\s*(\d+),\s*([0-9.]+)\s*\)/;
    const match = colorRegex.exec(selectedBackground);
    const navigate = useNavigate();
    const [StartGame, setStartGame] = useState(false);

    const handleStartGame = data => {
        setStartGame(true);
        setOpponent(
            data.data.player1.username === user.username
                ? data.data.player2
                : data.data.player1
        );
    };

    const [userSeconds, setUserSeconds] = useState(30);
    const [currentPlayer, setCurrentPlayer] = useState(null);

    useEffect(() => {
        if (!gameSocket) return;
        GameListener({
            tic_tac_toe_start_game: handleStartGame,
            tic_tac_toe_timer: data => {
                setUserSeconds(data.data.timer);
                setCurrentPlayer(data.data.current_player);
                if (StartGame === false) {
                    setOpponent(
                        data.data.player1.username === user.username
                            ? data.data.player2
                            : data.data.player1
                    );
                    setStartGame(true);
                }
            }
        });
    }, [GameListener, gameSocket]);

    useEffect(() => {
        return () => {
            emitGame('disconnect_tic_tac_toe', { game: 'tic_tac_toe' });
            setSelectedGame('TicTacToe');
        };
    }, [gameSocket]);

    useEffect(() => {
        if (!requestGame) {
            navigate('/play');
        }
    }, [requestGame]);

    return (
        <div className={styles.games}>
            <div className={`${styles.gamesContainer} background_secondary`}>
                <div className={styles.gamesAreaHolder}>
                    <div
                        className={styles.gameArea}
                        style={{
                            background: selectedBackground,
                            border: `2px solid rgba(${match[1]}, ${match[2]}, ${match[3]}, 0.5)`
                        }}>
                        <div
                            className={`${styles.gameAreaBanner} ${styles.gameAreaBannerTicTacToe}`}>
                            <div>
                                <p className={styles.time}>
                                    <TimerDown
                                        GameListener={GameListener}
                                        isTicTacToe={true}
                                        Seconds={userSeconds}
                                    />
                                </p>
                                {currentPlayer ? (
                                    <p className={styles.time_remaining}>
                                        {`${currentPlayer.username === user.username ? t('game:time_left_for_you') : t('game:time_left_for_opponent')}`}
                                    </p>
                                ) : (
                                    <p className={styles.time_remaining}>
                                        {t('game:time_remaining')}
                                    </p>
                                )}
                            </div>
                        </div>
                        {StartGame ? (
                            <TicTacToeO setHistory={setHistory} history={history} />
                        ) : (
                            <div className={styles.rules_container}>
                                <BeforeGameGameBoy />
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.panel_holder}>
                    <div className={`${styles.panel} background_primary`}>
                        <h3 className={styles.panel_title}>{t('common:tictactoe')}</h3>
                        <p className={styles.panel_subtitle}>{t('game:match_recap')}</p>
                        <OpponentsPanel userScore={userScore} comScore={comScore} />
                        <GameLogs history={history} />
                        <button
                            className={styles.panel_button}
                            onClick={e => {
                                e.preventDefault();
                                navigate('/play');
                            }}>
                            {t('game:give_up')}
                        </button>
                    </div>
                </div>
                <div className={styles.panel_button_mobile_container}>
                    <button
                        className={styles.panel_button_mobile}
                        onClick={e => {
                            e.preventDefault();
                            navigate('/play');
                        }}>
                        {t('game:give_up')}
                    </button>
                </div>
            </div>
        </div>
    );
}
