import '../../App.css';
import classes from './LogsCard.module.css';
import Imagify from '../Imagify/Imagify';
import React, { useState } from 'react';

function LogsCard({ Logs }) {
    const [gameTimestamp, setGameTimestamp] = useState('');

    return (
        <div
            className={classes.logs_container}
            onClick={() => {
                setGameTimestamp(formatDate(Logs.timestamp));
                setTimeout(() => {
                    setGameTimestamp('');
                }, 5000);
            }}>
            <div className={classes.logs_card_loser}></div>
            <div className={classes.logs_card_container}>
                <div className={classes.logs_card}>
                    <div className={classes.logsLeftSide}>
                        <Imagify
                            src={Logs && Logs.player1_picture}
                            alt='player'></Imagify>
                        <h1>{Logs.player1_score}</h1>
                    </div>
                    <div className={classes.middleContainer}>
                        <h1 className={classes.logs_card_middle__vs}>VS</h1>
                        <h1 className={classes.logs_card_middle__text}>
                            {gameTimestamp !== '' ? gameTimestamp : Logs.game_display}
                        </h1>
                    </div>

                    <div className={classes.logsRightSide}>
                        <h1>{Logs.player2_score}</h1>
                        <Imagify src={Logs.player2_picture} alt='player'></Imagify>
                    </div>
                </div>
            </div>
            <div className={classes.logs_card_winner}></div>
        </div>
    );
}

function formatDate(date) {
    let dateObj = new Date(date);
    let year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    let dt = dateObj.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return year + '-' + month + '-' + dt;
}

export default LogsCard;
