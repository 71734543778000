import React, { useContext } from 'react';
import classes from './GameLogs.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import UserContext from '../../../../context/UserContext';
import NoDataView from '../../../../components/NoDataView/NoDataView';
import { useTranslation } from 'react-i18next';

export default function GameLogs({ history }) {
    const { t } = useTranslation(['game']);
    const { user } = useContext(UserContext);

    return (
        <div className={classes.logs}>
            <h5 className={classes.logs_title}>{t('game:game_logs')}</h5>
            <div className={classes.logs_holder}>
                {history && history.length > 0 ? (
                    <>
                        {history.map((history_item, index) => (
                            <div key={index} className={classes.logs_log_holder}>
                                <div
                                    style={{
                                        backgroundColor:
                                            history_item.type === 'play'
                                                ? history_item.username === user.username
                                                    ? '#43C07D'
                                                    : '#F95A5A'
                                                : '#FFC806'
                                    }}
                                    className={classes.logs_log_line}></div>
                                <div className={classes.logs_log}>
                                    <Imagify
                                        alt='Avatar'
                                        src={history_item.image}
                                        className={classes.logs_log_image}
                                    />
                                    <p>{history_item.message}</p>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <NoDataView
                        title={t('game:no_game_logs')}
                        subtitle={t('game:no_game_logs_desc')}
                    />
                )}
            </div>
        </div>
    );
}
