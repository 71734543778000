import React from 'react';
import classes from './BackgroundSelector.module.css';

export default function BackgroundSelector({ Selected, setSelected, id = '' }) {
    const themes = {
        blue: 'radial-gradient(circle, rgba(32,31,85,1) 0%, rgba(1,0,35,1) 100%)',
        red: 'radial-gradient(circle, rgba(85, 37, 31, 1) 14%, rgba(35, 0, 0, 1) 100%)',
        green: 'radial-gradient(circle, rgba(31, 85, 36, 1) 0%, rgba(0, 35, 4, 1) 100%)'
    };

    const handleTab = (tab, param) => {
        setSelected(tab);
        if (id !== 'general_settings') return;
        const storedObject = JSON.parse(localStorage.getItem('settings_params'));
        if (storedObject) {
            storedObject[param] = tab;
            localStorage.setItem('settings_params', JSON.stringify(storedObject));
        }
    };

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.backgroundSelector}>
                <div
                    className={`${classes.bg1} `}
                    onClick={() => handleTab(themes.blue, 'selectedBackground')}>
                    {Selected === themes.blue && (
                        <div className={classes.selected}>
                            <img
                                src='/assets/icons/ic_chck_mark.svg'
                                alt='Check mark'></img>
                        </div>
                    )}
                </div>
                <div
                    className={`${classes.bg2} `}
                    onClick={() => handleTab(themes.red, 'selectedBackground')}>
                    {Selected === themes.red && (
                        <div className={classes.selected}>
                            <img
                                src='/assets/icons/ic_chck_mark.svg'
                                alt='Check mark'></img>
                        </div>
                    )}
                </div>
                <div
                    className={`${classes.bg3} `}
                    onClick={() => handleTab(themes.green, 'selectedBackground')}>
                    {Selected === themes.green && (
                        <div className={classes.selected}>
                            <img
                                src='/assets/icons/ic_chck_mark.svg'
                                alt='Check mark'></img>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
