import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api.js';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [friendRequests, setFriendRequests] = useState([]);
    const [friendsList, setFriendsList] = useState([]);
    const [userFriendsList, setUserFriendsList] = useState([]);
    const [isFriendsFetching, setIsFriendsFetching] = useState(true);
    const [playingWith, setPlayingWith] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userLoaded, setUserLoaded] = useState(false);
    const [winRate, setWinRate] = useState(0);
    const [selectedGame, setSelectedGame] = useState('PingPong');
    const [selectedBackground, setSelectedBackground] = useState(
        'radial-gradient(circle, rgba(32,31,85,1) 0%, rgba(1,0,35,1) 100%)'
    );
    const [selectedGameConnectivity, setSelectedGameConnectivity] = useState('online');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [profilePictureUrl, setProfilePictureUrl] = useState(null);
    const [coverImageUrl, setCoverImageUrl] = useState(null);
    const [showFriends, setShowFriends] = useState(false);
    const [gameHistory, setGameHistory] = useState([]);
    const [gameUserHistory, setGameUserHistory] = useState([]);
    const [isUserBlocked, setIsUserBlocked] = useState(true);
    const [blockedStatus, setBlockedStatus] = useState(false);
    const [activeTabSettings, setActiveTabSettings] = useState(0);
    const [isOnlineGame, setIsOnlineGame] = useState(true);
    const [requestResourceFetched, setRequestResourceFetched] = useState(false);
    const { t } = useTranslation(['dashboard', 'common', 'resources']);

    const fetchUser = async () => {
        try {
            setRequestResourceFetched(false);
            const response = await api.get('/users/me/');
            setUser(response.data);
            setWinRate(response.data.win_rate);
            setIsLoggedIn(true);
            setUserLoaded(true);
            setRequestResourceFetched(true);
            fetchFriends();
            setCoverImageUrl(
                t('resources:cover_images')[response.data.cover_picture_index]
            );
            fetchGameHistory();
        } catch (error) {
            console.error('fetchUser -> error', error);
            setUserLoaded(true);
            setUser(null);
        }
    };
    
    const fetchUserData = async function (
        setUserData,
        usernameSearchQuery,
        setCoverImageUrl = null,
        t = null,
        navigate
    ) {
        setRequestResourceFetched(false);
        await api
        .get('/users/' + usernameSearchQuery)
        .then(response => {
            setUserData(response.data);
            setRequestResourceFetched(true);
            if (setCoverImageUrl && t) {
                setCoverImageUrl(
                    t('resources:cover_images')[response.data.cover_picture_index]
                );
                }
            })
            .catch(error => {
                setRequestResourceFetched(true);
                console.error('Error fetching user data:', error);
                window.location.pathname = '/404';
                navigate('/404');
            });
    }

    const fetchGameHistory = async () => {
        try {
            const response = await api.get('/games/games-history/');
            setGameHistory(response.data);
        } catch (error) {
            console.error('fetchGameHistory -> error', error);
        }
    };

    const fetchUserGameHistory = async uid => {
        try {
            const response = await api.get('/games/user-history/' + uid + '/');
            setGameUserHistory(response.data);
        } catch (error) {
            console.error('fetchGameHistory -> error', error);
        }
    };

    const fetchFriends = async () => {
        await api
            .get('/users/friends-list/?username=me')
            .then(response => {
                setFriendsList(response.data.friends);
                setFriendRequests(response.data.requests);
                setIsFriendsFetching(false);
            })
            .catch(error => {
                console.error('fetchFriends Error: ', error);
                setIsFriendsFetching(false);
            });
    };

    async function fetchUserFriends(username) {
        await api
            .get('/users/friends-list/?username=' + username)
            .then(response => {
                setUserFriendsList(response.data.friends);
            })
            .catch(error => {
                console.error('fetchFriends Error: ', error);
            });
    }

    useEffect(() => {
        if (user) {
            const settings_params = localStorage.getItem('settings_params');
            if (settings_params) {
                setSelectedGame(JSON.parse(settings_params).selectedGame);
                setSelectedBackground(JSON.parse(settings_params).selectedBackground);
                setSelectedGameConnectivity(
                    JSON.parse(settings_params).selectedGameConnectivity
                );
            } else {
                localStorage.setItem(
                    'settings_params',
                    JSON.stringify({
                        selectedGame: selectedGame,
                        selectedBackground: selectedBackground,
                        selectedGameConnectivity: selectedGameConnectivity
                    })
                );
            }
        }
    }, [user]);

    return (
        <UserContext.Provider
            value={{
                user,
                friendRequests,
                friendsList,
                userFriendsList,
                selectedBackground,
                playingWith,
                selectedGame,
                profilePictureUrl,
                coverImageUrl,
                userData,
                isLoggedIn,
                isOnlineGame,
                showFriends,
                blockedStatus,
                userLoaded,
                isFriendsFetching,
                isUserBlocked,
                gameHistory,
                gameUserHistory,
                winRate,
                selectedGameConnectivity,
                activeTabSettings,
                requestResourceFetched,
                setRequestResourceFetched,
                setWinRate,
                setIsOnlineGame,
                setSelectedGame,
                fetchFriends,
                fetchUserFriends,
                fetchUser,
                fetchUserData,
                setPlayingWith,
                setUser,
                setFriendsList,
                setUserFriendsList,
                setFriendRequests,
                setSelectedBackground,
                setUserData,
                setIsLoggedIn,
                setProfilePictureUrl,
                setCoverImageUrl,
                setShowFriends,
                setGameHistory,
                setUserLoaded,
                setIsFriendsFetching,
                fetchGameHistory,
                fetchUserGameHistory,
                setGameUserHistory,
                setIsUserBlocked,
                setBlockedStatus,
                setActiveTabSettings,
                setSelectedGameConnectivity
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
