import '../../../../../App.css';
import React from 'react';
import style from '../GameOver.module.css';

export default function GameOverButtons({ Picture, firstText, secondText, onClick }) {
    return (
        <div className={style.button} onClick={onClick}>
            <img src={Picture} alt={Picture} />
            <div className={style.buttonTextContainer}>
                <p>{firstText}</p>
                <h1>{secondText}</h1>
            </div>
        </div>
    );
}
