import '../../App.css';
import classes from './Sidebar.module.css';
import UserContext from '../../context/UserContext';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

function Sidebar() {
    const currentUrl = window.location.pathname;
    const { setShowFriends } = useContext(UserContext);

    const [selected, setSelected] = useState(
        currentUrl === '/dashboard/me'
            ? 1
            : currentUrl === '/messages'
              ? 2
              : currentUrl === '/play'
                ? 3
                : currentUrl === '/tournaments'
                  ? 4
                  : currentUrl === '/settings'
                    ? 6
                    : 0
    );

    const handleClick = () => {
        setSelected(1);
        setShowFriends(false);
    };

    return (
        <div className={classes.sidebar}>
            <div className={classes.logo} onClick={handleClick}>
                <Link to='/dashboard/me'>
                    <img
                        alt='Logo'
                        src='/assets/images/main_logo.svg'
                        className={classes.website_logo}
                    />
                </Link>
            </div>

            <Link
                to='/dashboard/me'
                className={`${classes.item} ${selected === 1 ? classes.bg_effect : ''}`}
                onClick={handleClick}>
                <img src='/assets/icons/ic_dashboard.svg' alt='Dashboard' />
                <div className={selected === 1 ? classes.selector : ''} />
            </Link>

            <Link
                to='messages'
                className={`${classes.item} ${selected === 2 ? classes.bg_effect : ''}`}
                onClick={() => setSelected(2)}>
                <img src='/assets/icons/ic_chat.svg' alt='Messages' />
                <div className={selected === 2 ? classes.selector : ''} />
            </Link>

            <Link
                to='play'
                className={`${classes.item} ${selected === 3 ? classes.bg_effect : ''}`}
                onClick={() => setSelected(3)}>
                <img src='/assets/icons/ic_game.svg' alt='Play' />
                <div className={selected === 3 ? classes.selector : ''} />
            </Link>

            <Link
                to='tournaments'
                className={`${classes.item} ${selected === 4 ? classes.bg_effect : ''}`}
                onClick={() => setSelected(4)}>
                <img src='/assets/icons/ic_tournaments.svg' alt='Tournaments' />
                <div className={selected === 4 ? classes.selector : ''} />
            </Link>

            <Link
                to='settings'
                className={`${classes.item} ${selected === 6 ? classes.bg_effect : ''}`}
                onClick={() => {
                    setSelected(6);
                }}>
                <img src='/assets/icons/ic_settings.svg' alt='Settings' />
                <div className={selected === 6 ? classes.selector : ''} />
            </Link>
        </div>
    );
}

export default Sidebar;
