import React from 'react';
import style from './Pending.module.css';
export default function Pending() {
    return (
        <div className={style.loader}>
            <li className={style.ball}></li>
            <li className={style.ball}></li>
            <li className={style.ball}></li>
        </div>
    );
}
