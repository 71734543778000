import React, { useContext } from 'react';
import classes from './OpponentsPanel.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import UserContext from '../../../../context/UserContext';
import GameContext from '../../../../context/GameContext';

export default function OpponentsPanel({ userScore, comScore }) {
    const { user } = useContext(UserContext);
    const { Opponent } = useContext(GameContext);

    return (
        <div className={classes.panel_opponents_holder}>
            <div className={classes.panel_opponents_line}></div>
            <div className={classes.panel_opponents}>
                <div className={classes.line}></div>
                <div className={classes.panel_opponents_player}>
                    <Imagify
                        alt='Avatar'
                        src={user && user.profile_picture}
                        className={classes.panel_opponents_player_image}
                    />
                    <p className={classes.panel_opponents_player_score}>{userScore}</p>
                </div>
                <div className={classes.panel_opponents_vs__holder}>
                    <div className={classes.panel_opponents_vs}>
                        <p>VS</p>
                    </div>
                </div>
                <div className={classes.panel_opponents_player}>
                    <p className={classes.panel_opponents_player_score}>{comScore}</p>
                    <Imagify
                        alt='Avatar'
                        src={`${Opponent !== null ? Opponent.profile_picture : '/assets/images/img_unknown_user.svg'}`}
                        className={classes.panel_opponents_player_image}
                    />
                </div>
            </div>
            <div className={classes.panel_opponents_line}></div>
        </div>
    );
}
