import React, { useEffect, useContext, useState } from 'react';
import classes from './SettingPicProfile.module.css';
import Skeleton from 'react-loading-skeleton';
import UserContext from '../../../../../../context/UserContext';
import Imagify from '../../../../../../components/Imagify/Imagify';
import api from '../../../../../../api';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function SettingPicProfile(props) {
    const [profilePicture, setProfilePicture] = useState(null);
    const { user, setProfilePictureUrl } = useContext(UserContext);
    const isFromProfile = props.caller === 'UserProfile';
    const [currentIndex, setCurrentIndex] = useState(-1);
    const { t } = useTranslation(['resources', 'settings']);
    const images = t('cover_images');
    let activeToastId = null;

    const prevImage = () => {
        setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
    };

    const nextImage = () => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    const handleFileChange = async event => {
        const file = event.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                activeToastId = toast(t('settings:msg_uploading_image'), {
                    autoClose: false,
                    isLoading: true,
                    draggable: true,
                    position: 'top-center'
                });

                const response = await api.post('utilities/upload_file/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.status === 201) {
                    const uploadedImageUrl = response.data.url;
                    setProfilePictureUrl(uploadedImageUrl);
                    setProfilePicture(uploadedImageUrl);
                    toast.update(activeToastId, {
                        render: t('settings:msg_pfp_image_uploaded'),
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000
                    });
                }
            } catch (error) {
                toast.update(activeToastId, {
                    render: t('settings:msg_pfp_image_upload_failed'),
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000
                });
            }
        }
    };

    useEffect(() => {
        if (!user) return;
        const defaultProfilePicture = user && user.profile_picture;
        setProfilePicture(defaultProfilePicture);
        setCurrentIndex(user.cover_picture_index);
    }, [user]);

    useEffect(() => {
        if (props.coverImageIndex) {
            props.coverImageIndex(currentIndex);
        }
    }, [currentIndex]);

    return (
        <div
            className={classes.pictures}
            style={{
                '--background-height': `${props.backgroundHeight}px`,
                backgroundImage: `url(${images[currentIndex]})`
            }}>
            {isFromProfile && (
                <div className={classes.button_container}>
                    <button className={classes.edit_button} onClick={prevImage}>
                        <div className={classes.icon}>
                            <Imagify alt='Background' src='/assets/icons/ic_back.svg' />
                        </div>
                    </button>
                    <button className={classes.edit_button_2} onClick={nextImage}>
                        <div className={`${classes.icon} ${classes.icon_rotate}`}>
                            <Imagify alt='Background' src='/assets/icons/ic_back.svg' />
                        </div>
                    </button>
                </div>
            )}
            <div
                className={classes.profile_picture}
                style={{
                    '--picture-width': `${props.width}px`,
                    '--picture-height': `${props.height}px`
                }}>
                {isFromProfile && (
                    <>
                        <label
                            className={`${classes.label_item} ${classes.profile}`}
                            htmlFor='profileFile'>
                            <span className={classes.label_icon}>
                                <div className={classes.icon}>
                                    <Imagify
                                        alt='Edit'
                                        src='/assets/icons/ic_edit_profile.svg'
                                    />
                                </div>
                            </span>
                            <input
                                type='file'
                                accept='image/png, image/jpeg, image/gif'
                                id='profileFile'
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </label>
                    </>
                )}
                {profilePicture ? (
                    <Imagify
                        className={classes.profile_picture_img}
                        alt='Avatar'
                        src={profilePicture}
                    />
                ) : (
                    <Skeleton
                        className={classes.profile_picture_img}
                        width={props.width}
                        height={props.height}
                    />
                )}
            </div>
            {currentIndex === -1 && (
                <Skeleton
                    className={classes.pictures}
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0'
                    }}
                />
            )}
        </div>
    );
}

export default SettingPicProfile;
