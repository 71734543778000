import '../../../../App.css';
import classes from './Notifications.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import SocketContext from '../../../../context/SocketContext';
import UserContext from '../../../../context/UserContext';
import GameContext from '../../../../context/GameContext';
import ChatSocketContext from '../../../../context/ChatSocketContext';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { acceptF, rejectF } from '../../../FriendCardInfo/FriendCardInfo';
import { useTranslation } from 'react-i18next';

function Notifications({ notifications }) {
    const navigate = useNavigate();
    const { t } = useTranslation(['common']);
    const {
        setFriendsList,
        friendsList,
        setFriendRequests,
        setGameHistory,
        setWinRate,
        setUser,
        user,
        fetchUserFriends
    } = useContext(UserContext);
    const { emit } = useContext(ChatSocketContext);
    const { socket, messageListener, notificationsArray, setNotificationsArray } =
        useContext(SocketContext);

    const {
        setTournamentRooms,
        emitGame,
        gameSocket,
        GameListener,
        setReadyToStart,
        setConnectionLost,
        setOpponent,
        setRequestGame
    } = useContext(GameContext);

    useEffect(() => {
        if (!gameSocket) return;
        GameListener({
            initiate_game: data => {
                setReadyToStart(true);
                setConnectionLost(false);
                if (data.data.player1.username === (user && user.username)) {
                    setOpponent(data.data.player2);
                } else {
                    setOpponent(data.data.player1);
                }
            }
        });
    }, [gameSocket]);

    useEffect(() => {
        if (socket === null) return;
        const handleNotification = data => {
            setNotificationsArray(currentNotifications => [
                ...currentNotifications,
                data.data
            ]);
        };
        const handleReceive = data => {
            setFriendRequests(currentFriendRequests => [
                ...currentFriendRequests,
                data.data
            ]);
            setNotificationsArray(currentNotifications => [
                ...currentNotifications,
                data.data
            ]);
        };

        const handleAccept = data => {
            fetchUserFriends(data.data.username);
            setFriendsList(currentFriends => [...currentFriends, data.data]);
            setFriendRequests(currentFriendRequests =>
                currentFriendRequests.filter(
                    friendRequest => friendRequest.id !== data.data.id
                )
            );
            setNotificationsArray(currentNotifications =>
                currentNotifications.filter(
                    notification => notification.id !== data.data.id
                )
            );
        };
        const handleRemoveRequest = data => {
            setNotificationsArray(currentNotifications =>
                currentNotifications.filter(
                    notification => notification.id !== data.data.id
                )
            );
            setFriendRequests(currentFriendRequests =>
                currentFriendRequests.filter(
                    friendRequest => friendRequest.id !== data.data.id
                )
            );
            
            fetchUserFriends(data.data.username);
            
        };

        const handleOnlineStatus = data => {
            friendsList.forEach(friend => {
                if (friend.username === data.data.username) {
                    setFriendsList(currentFriends =>
                        currentFriends.map(friend =>
                            friend.username === data.data.username
                                ? { ...friend, is_online: data.data.is_online }
                                : friend
                        )
                    );
                }
            });
        };

        const handleTournamentCreation = data => {
            setTournamentRooms(currentRooms => [data.data, ...currentRooms]);
        };

        const handleMatchUpdate = data => {
            setGameHistory(prevResults => [...prevResults, data.data]);
            setWinRate(
                data.data.player1_username === (user && user.username)
                    ? data.data.player1_win_rate
                    : data.data.player2_win_rate
            );
        };

        const handleTournamentDeleted = data => {
            setTournamentRooms(currentRooms =>
                currentRooms.filter(room => room.id !== data.data.id)
            );
        };

        const handleExperienceUpdate = data => {
            if (data.data.game === 'TicTacToe') {
                setUser({
                    ...user,
                    game_tictactoe_exp: data.data.experience
                });
            }
            if (data.data.game === 'PingPong') {
                setUser({
                    ...user,
                    game_pingpong_exp: data.data.experience
                });
            }
        };

        const handleRemoveFriend = data => {
            fetchUserFriends(data.data.username);
            setFriendsList(
                friendsList.filter(friend => friend.username !== data.data.username)
            );
        };

        if (socket !== null)
            messageListener({
                notification: handleNotification,
                send_friend_request: handleReceive,
                accept_friend_request: handleAccept,
                remove_request: handleRemoveRequest,
                online_status: handleOnlineStatus,
                tournament_created: handleTournamentCreation,
                match_update: handleMatchUpdate,
                tournament_deleted: handleTournamentDeleted,
                send_game_experience: handleExperienceUpdate,
                remove_friend: handleRemoveFriend
            });

        return () => {
            if (socket) {
                socket.onmessage = null;
            }
        };
    }, [socket]);

    return (
        <div
            className={`${classes.notifications} ${notifications ? `${classes.active}` : ''}`}>
            <div className={classes.notifications__holder}>
                {notificationsArray && notificationsArray.length > 0 ? (
                    notificationsArray.map(
                        (notification, index) =>
                            notification.notify_type !== 'Pending' && (
                                <div key={index} className={classes.notification}>
                                    <Imagify
                                        className={classes.notification__img}
                                        src={notification.profile_picture}
                                        alt='notification'
                                    />
                                    <div className={classes.notification__text__holder}>
                                        <h5>{notification.notify_type}</h5>
                                        <p>{notification.message}</p>
                                        <div className={classes.buttonsContainer}>
                                            <button
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (
                                                        notification.notify_type ===
                                                        'Friend Request'
                                                    ) {
                                                        acceptF(
                                                            notification,
                                                            setFriendRequests,
                                                            emit,
                                                            setNotificationsArray
                                                        );
                                                    } else {
                                                        setNotificationsArray(
                                                            prevResults =>
                                                                prevResults.filter(
                                                                    request =>
                                                                        request.id !==
                                                                        notification.id
                                                                )
                                                        );
                                                        emitGame(
                                                            notification.game_type ===
                                                                'TicTacToe'
                                                                ? 'private_tic_tac_toe_game'
                                                                : 'private_ping_pong_game',
                                                            {
                                                                room_name:
                                                                    notification.room_name,
                                                                game: notification.game_type,
                                                                receiver:
                                                                    notification.sender
                                                            }
                                                        );
                                                        setRequestGame(true);
                                                        navigate(
                                                            notification.game_type ===
                                                                'TicTacToe'
                                                                ? '/play/tic-tac-toe-online'
                                                                : '/play/ping-pong-online'
                                                        );
                                                    }
                                                }}
                                                className={classes.notification__button}>
                                                {t('common:accept')}
                                            </button>
                                            <button
                                                className={classes.notification__button}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (
                                                        notification.notify_type ===
                                                        'Friend Request'
                                                    ) {
                                                        rejectF(
                                                            notification,
                                                            setFriendRequests,
                                                            setNotificationsArray
                                                        );
                                                    } else {
                                                        setNotificationsArray(
                                                            prevResults =>
                                                                prevResults.filter(
                                                                    request =>
                                                                        request.sender !==
                                                                        notification.sender
                                                                )
                                                        );
                                                    }
                                                }}>
                                                {t('common:decline')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                    )
                ) : (
                    <div className={classes.no_notifications}>
                        <Imagify src='/assets/icons/ic_checkmark.svg' alt='check Mark' />
                        <p>{t('common:no_notifications')}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Notifications;
