import React, { useEffect, useState, useContext } from 'react';
import styles from './TicTacToe.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import GameContext from '../../../../context/GameContext';
import UserContext from '../../../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BeforeGameGameBoy from '../BeforeGameGameBoy/BeforeGameGameBoy';

function TicTacToe({
    userScore,
    setUserScore,
    comScore,
    setComScore,
    history,
    setHistory,
    round,
    setRound
}) {
    const [gameCells, setGameCells] = useState({
        c_1: '',
        c_2: '',
        c_3: '',
        c_4: '',
        c_5: '',
        c_6: '',
        c_7: '',
        c_8: '',
        c_9: ''
    });
    const { user } = useContext(UserContext);
    const allowedWords = ['X', 'O'];
    const [isX, setIsX] = useState(true);
    const { t } = useTranslation(['game']);
    const navigate = useNavigate();
    const { setGameOver, setGameResult } = useContext(GameContext);
    const [initiateGame, setInitiateGame] = useState(false);

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            setInitiateGame(true);
        }, 5000);

        setHistory(prevHistory => [
            {
                image: '/assets/icons/ic_draw.svg',
                message: t('game:local_turns', {
                    player: user.first_name,
                    mark: 'X'
                })
            },
            ...prevHistory
        ]);
        return () => clearInterval(timeoutId);
    }, []);

    const calculateWinner = () => {
        const lines = [
            [gameCells.c_1, gameCells.c_2, gameCells.c_3],
            [gameCells.c_4, gameCells.c_5, gameCells.c_6],
            [gameCells.c_7, gameCells.c_8, gameCells.c_9],
            [gameCells.c_1, gameCells.c_4, gameCells.c_7],
            [gameCells.c_2, gameCells.c_5, gameCells.c_8],
            [gameCells.c_3, gameCells.c_6, gameCells.c_9],
            [gameCells.c_1, gameCells.c_5, gameCells.c_9],
            [gameCells.c_3, gameCells.c_5, gameCells.c_7]
        ];

        for (let i = 0; i < lines.length; i++) {
            if (
                lines[i][0] &&
                lines[i][0] === lines[i][1] &&
                lines[i][0] === lines[i][2]
            ) {
                if (round === 3) {
                    setGameOver(true);
                    setGameResult({
                        winner: userScore > comScore ? user.username : 'Opponent',
                        player1: {
                            username: user.username,
                            profile_picture: user.profile_picture,
                            full_name: user.full_name,
                            score: lines[i][0] === 'X' ? userScore + 1 : userScore
                        },
                        player2: {
                            username: 'Opponent',
                            profile_picture: '/assets/images/img_unknown_user.svg',
                            full_name: 'Opponent',
                            score: lines[i][0] === 'O' ? comScore + 1 : comScore
                        },
                        game_mode: 'local',
                        game_type: 'tic_tac_toe'
                    });
                    navigate('/play');
                }
                setRound(round + 1);
                return lines[i][0];
            }
        }
        return null;
    };

    const allCellsFilled = () => {
        return Object.values(gameCells).every(cell => allowedWords.includes(cell));
    };

    const handleClick = e => {
        if (gameCells[e.target.name] !== '') {
            return;
        }
        if (isX) {
            setGameCells({ ...gameCells, [e.target.name]: 'X' });
        } else {
            setGameCells({ ...gameCells, [e.target.name]: 'O' });
        }
        setHistory(prevHistory => [
            {
                type: 'play',
                username: `${isX ? user && user.username : 'Opponent'}`,
                image: isX
                    ? user && user.profile_picture
                    : '/assets/images/img_unknown_user.svg',
                message: isX
                    ? t('game:log_tic_you_play', {
                          cell: e.target.name.replace('c_', '')
                      })
                    : t('game:log_tic_opponent_play', {
                          opponent: 'Player 2',
                          cell: e.target.name.replace('c_', '')
                      })
            },
            ...prevHistory
        ]);
        setIsX(!isX);
    };

    useEffect(() => {
        const winner = calculateWinner();
        if (winner) {
            winner === 'X' ? setUserScore(userScore + 1) : setComScore(comScore + 1);
            setGameCells({
                c_1: '',
                c_2: '',
                c_3: '',
                c_4: '',
                c_5: '',
                c_6: '',
                c_7: '',
                c_8: '',
                c_9: ''
            });
            setHistory([
                {
                    image:
                        winner === 'X'
                            ? user && user.profile_picture
                            : '/assets/images/img_unknown_user.svg',
                    message:
                        winner === 'X'
                            ? user &&
                              t('game:online_opponent_scored', {
                                  opponent: user.first_name
                              })
                            : t('game:local_opponent_scored')
                },
                ...history
            ]);
            setIsX(true);
        }
        else if (allCellsFilled()) {
            setGameCells({
                c_1: '',
                c_2: '',
                c_3: '',
                c_4: '',
                c_5: '',
                c_6: '',
                c_7: '',
                c_8: '',
                c_9: ''
            });
            setHistory([
                {
                    image: '/assets/icons/ic_draw.svg',
                    message: t('game:game_draw')
                },
                ...history
            ]);
            setIsX(true);
        }
    });

    if (!initiateGame) {
        return (
            <div className={styles.before_game_container}>
                <BeforeGameGameBoy />
            </div>
        );
    }

    return (
        <div className={styles.tictactoe}>
            <div className={styles.tictactoe__board}>
                <div className={styles.tictactoe__row}>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_1'
                        onClick={handleClick}>
                        {gameCells.c_1 === 'X' || gameCells.c_1 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_1 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_2'
                        onClick={handleClick}>
                        {gameCells.c_2 === 'X' || gameCells.c_2 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_2 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_3'
                        onClick={handleClick}>
                        {gameCells.c_3 === 'X' || gameCells.c_3 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_3 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                </div>
                <div className={styles.border_bottom__one}></div>
                <div className={styles.border_top__one}></div>
                <div className={styles.tictactoe__row}>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_4'
                        onClick={handleClick}>
                        {gameCells.c_4 === 'X' || gameCells.c_4 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_4 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_5'
                        onClick={handleClick}>
                        {gameCells.c_5 === 'X' || gameCells.c_5 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_5 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_6'
                        onClick={handleClick}>
                        {gameCells.c_6 === 'X' || gameCells.c_6 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_6 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                </div>
                <div className={styles.border_bottom__two}></div>
                <div className={styles.border_top__two}></div>
                <div className={styles.tictactoe__row}>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_7'
                        onClick={handleClick}>
                        {gameCells.c_7 === 'X' || gameCells.c_7 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_7 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_8'
                        onClick={handleClick}>
                        {gameCells.c_8 === 'X' || gameCells.c_8 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_8 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_9'
                        onClick={handleClick}>
                        {gameCells.c_9 === 'X' || gameCells.c_9 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_9 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TicTacToe;
