import '../../../../App.css';
import React, { useEffect, useState } from 'react';
import classes from './Gameboy.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import PingPongAuth from './PingPongAuth';
import TicTacToeAuth from './TicTacToeAuth';
import { useTranslation } from 'react-i18next';

function Gameboy() {
    const { t } = useTranslation(['authentication', 'messages']);
    const [startTheGame, setStartTheGame] = useState(false);
    const [gameBoySelectedGame, setGameBoySelectedGame] = useState('PingPong');
    const [vibrateStart, setVibrateStart] = useState(false);
    const [vibrateGames, setVibrateGames] = useState(false);
    const [clickedGameboyArrow, setClickedGameboyArrow] = useState(null);

    function handleClicked(type) {
        if (type === 1) {
            setVibrateStart(true);
            setTimeout(() => {
                setVibrateStart(false);
            }, 500);
        } else {
            setVibrateGames(true);
            setTimeout(() => {
                setVibrateGames(false);
            }, 500);
        }
    }
    useEffect(() => {
        const upArrowButton = document.getElementById('up_arrow_button');
        const downArrowButton = document.getElementById('down_arrow_button');

        const handleMouseDownUp = () => {
            setClickedGameboyArrow('up');
        };

        const handleMouseUpUp = () => {
            setClickedGameboyArrow(null);
        };

        const handleMouseDownDown = () => {
            setClickedGameboyArrow('down');
        };

        const handleMouseUpDown = () => {
            setClickedGameboyArrow(null);
        };

        if (upArrowButton) {
            upArrowButton.addEventListener('mousedown', handleMouseDownUp);
            upArrowButton.addEventListener('mouseup', handleMouseUpUp);
        }

        if (downArrowButton) {
            downArrowButton.addEventListener('mousedown', handleMouseDownDown);
            downArrowButton.addEventListener('mouseup', handleMouseUpDown);
        }

        return () => {
            if (upArrowButton) {
                upArrowButton.removeEventListener('mousedown', handleMouseDownUp);
                upArrowButton.removeEventListener('mouseup', handleMouseUpUp);
            }

            if (downArrowButton) {
                downArrowButton.removeEventListener('mousedown', handleMouseDownDown);
                downArrowButton.removeEventListener('mouseup', handleMouseUpDown);
            }
        };
    }, []);

    return (
        <div className={classes.gameboy_holder}>
            <div className={classes.gameboy_frame}>
                <div className={classes.gameboy_screen_frame}>
                    {!startTheGame && (
                        <div className={classes.notStarted}>
                            <div className={classes.logo_progress_holder}>
                                <div className={classes.logo_holder}>
                                    <img alt='Logo' src='/assets/images/main_logo.svg' />
                                    <h1>{t('common:app_name')}</h1>
                                </div>

                                <div className={classes.progress_bar}></div>
                            </div>

                            <div className={classes.start_switch_holder}>
                                <p
                                    className={`${classes.press_start_text} ${vibrateStart ? classes.vibrate : ''}`}>
                                    {t('common:press_start')}
                                </p>
                                <p
                                    className={`${classes.switch_game_text} ${vibrateGames ? classes.vibrate : ''}`}>
                                    <span>▶ </span>
                                    {t('common:switch_game')}
                                    <span> ◀</span>
                                </p>
                            </div>
                        </div>
                    )}
                    <div className={classes.frameVideo}>
                        <video autoPlay loop muted width='100%' height='100%'>
                            <source src='/assets/videos/ping_pong.mp4' type='video/mp4' />
                            {t('messages:msg_video_not_supported')}
                        </video>
                    </div>
                    {startTheGame === true ? (
                        gameBoySelectedGame === 'PingPong' ? (
                            <PingPongAuth
                                startTheGame={startTheGame}
                                clickedGameboyArrow={clickedGameboyArrow}
                            />
                        ) : (
                            <TicTacToeAuth startTheGame={startTheGame} />
                        )
                    ) : (
                        ''
                    )}
                </div>

                <div className={classes.gameboy_text_holder}>
                    {gameBoySelectedGame === 'PingPong' ? (
                        <>
                            <h1>{t('common:pingpong')}</h1>
                            <h2>{t('authentication:gameboy_pong_msg')}</h2>
                        </>
                    ) : (
                        <>
                            <h1>{t('common:tictactoe')}</h1>
                            <h2>{t('authentication:gameboy_tic_msg')}</h2>
                        </>
                    )}
                </div>

                <div className={classes.gameboy_top_buttons_holder}>
                    <div className={classes.gameboy_arrow_container}>
                        <img
                            alt='Navigation'
                            src='/assets/images/gameboy_left_buttons.svg'
                        />
                        <button
                            className={classes.left_arrow_button}
                            onClick={e => {
                                e.preventDefault();
                                if (gameBoySelectedGame === 'PingPong')
                                    setGameBoySelectedGame('TicTacToe');
                                else setGameBoySelectedGame('PingPong');
                            }}></button>
                        <button
                            className={classes.right_arrow_button}
                            onClick={e => {
                                e.preventDefault();
                                if (gameBoySelectedGame === 'PingPong')
                                    setGameBoySelectedGame('TicTacToe');
                                else setGameBoySelectedGame('PingPong');
                            }}></button>
                        <button
                            id='up_arrow_button'
                            className={classes.up_arrow_button}
                            onClick={e => {
                                e.preventDefault();
                                handleClicked(0);
                            }}></button>
                        <button
                            id='down_arrow_button'
                            className={classes.down_arrow_button}
                            onClick={e => {
                                e.preventDefault();
                                handleClicked(0);
                            }}></button>
                    </div>
                    <Imagify
                        alt='Navigation'
                        src='/assets/images/gameboy_right_buttons.svg'
                        onClick={e => {
                            e.preventDefault();
                            handleClicked(1);
                        }}
                    />
                </div>
                <div className={classes.gameboy_bottom_buttons_holder}>
                    <div className={classes.start_button_container}>
                        <img
                            alt='Start'
                            src='/assets/images/gameboy_bottom_buttons.svg'
                        />

                        <button
                            className={classes.start_button}
                            onClick={e => {
                                e.preventDefault();
                                setStartTheGame(true);
                            }}></button>
                        <button
                            className={classes.pause_button}
                            onClick={e => {
                                e.preventDefault();
                                setStartTheGame(false);
                            }}></button>
                    </div>
                    <Imagify
                        alt='Sound holes'
                        src='/assets/images/gameboy_sound_holes.svg'
                    />
                </div>
            </div>
        </div>
    );
}

export default Gameboy;

// 448
// 297.6
