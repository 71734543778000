import '../../../../App.css';
import React, { useContext, useState, useEffect } from 'react';
import styles from './PingPongOnline.module.css';
import OpponentsPanel from '../../components/OpponentsPanel/OpponentsPanel';
import GameLogs from '../../components/GameLogs/GameLogs';
import UserContext from '../../../../context/UserContext';
import PingPongO from '../../components/PingPongO/PingPongO';
import GameContext from '../../../../context/GameContext';
import TimerDown from './TimerDown/TimerDown';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PingPongOnline() {
    const [userScore, setUserScore] = useState(0);
    const [comScore, setComScore] = useState(0);
    const [history, setHistory] = useState([]);
    const navigate = useNavigate();
    const { selectedBackground, user, setSelectedGame } = useContext(UserContext);
    const {
        gameSocket,
        Opponent,
        GameListener,
        setGameOver,
        emitGame,
        requestGame,
        setRequestGame,
        setReadyToStart,
        setConnectionLost
    } = useContext(GameContext);
    const [historyHolder, setHistoryHolder] = useState(null);
    const { t } = useTranslation(['game', 'common']);

    useEffect(() => {
        return () => {
            emitGame('disconnect_ping_pong', { game: 'ping_pong' });
            setSelectedGame('PingPong');
            setRequestGame(false);
            setReadyToStart(false);
            setConnectionLost(false);
        };
    }, [gameSocket]);

    useEffect(() => {
        if (!historyHolder) return;
        if (historyHolder.username === user.username) {
            historyHolder.message = t('game:online_you_scored');
        } else {
            historyHolder.message = t('game:online_opponent_scored', {
                opponent: Opponent.first_name
            });
        }
        setHistory([historyHolder, ...history]);
    }, [historyHolder]);

    const updateHistory = data => {
        setHistoryHolder(data.data.history);
    };

    useEffect(() => {
        if (!gameSocket) return;
        GameListener({
            game_history_update: updateHistory
        });
    }, [GameListener, gameSocket]);

    useEffect(() => {
        if (!requestGame) {
            navigate('/play');
        }
    }, [requestGame]);

    return (
        <div className={styles.games}>
            <div className={styles.gamesContainer}>
                <div className={styles.gamesAreaHolder}>
                    <div
                        className={styles.gameArea}
                        style={{
                            background: `${selectedBackground}`
                        }}>
                        <div className={`${styles.gameAreaBanner}`}>
                            <div>
                                <p className={styles.time}>
                                    <TimerDown GameListener={GameListener} />
                                </p>
                                <p className={styles.time_remaining}>
                                    {t('game:time_remaining')}
                                </p>
                            </div>
                        </div>
                        <PingPongO
                            setComScore={setComScore}
                            setUserScore={setUserScore}
                            comScore={comScore}
                            setHistory={setHistory}
                            userScore={userScore}></PingPongO>
                    </div>
                </div>
                <div className={styles.panel_holder}>
                    <div className={`${styles.panel} background_primary`}>
                        <h3 className={styles.panel_title}>{t('common:pingpong')}</h3>
                        <p className={styles.panel_subtitle}>{t('game:match_recap')}</p>
                        <OpponentsPanel userScore={userScore} comScore={comScore} />
                        <GameLogs history={history} setHistory={setHistory} />
                        <button
                            className={styles.panel_button}
                            onClick={e => {
                                e.preventDefault();
                                setGameOver(false);
                                navigate('/play');
                            }}>
                            {t('game:give_up')}
                        </button>
                    </div>
                </div>
                <div className={styles.panel_button_mobile_container}>
                    <button
                        className={styles.panel_button_mobile}
                        onClick={e => {
                            e.preventDefault();
                            setGameOver(false);
                            navigate('/play');
                        }}>
                        {t('game:give_up')}
                    </button>
                </div>
            </div>
        </div>
    );
}
