import '../../../../App.css';
import classes from './ProfilePreview.module.css';
import SettingPicProfile from '../UserProfile/components/SettingPicProfile/SettingPicProfile';
import TabLayout from '../../../../components/TabLayout/TabLayout';
import React, { useState } from 'react';
import FriendCardInfo from '../../../../components/FriendCardInfo/FriendCardInfo';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import UserContext from '../../../../context/UserContext';
import Imagify from '../../../../components/Imagify/Imagify';
import NoDataView from '../../../../components/NoDataView/NoDataView';
import LogsCard from '../../../../components/LogsCard/LogsCard';

function ProfilePreview() {
    const { t } = useTranslation(['settings', 'common']);
    const [activeTab, setActiveTab] = useState(1);
    const { user, friendsList, gameHistory } = useContext(UserContext);

    return (
        <div className={classes.ProfileSettings_container}>
            <div className={classes.ProfilePreviewLabel}>
                <Imagify alt='Avatar' src='/assets/icons/ic_lightOn.svg' />
                <h1>{t('settings:preview_title')}</h1>
            </div>
            <div className={classes.ProfilePreviewPic}>
                <SettingPicProfile
                    width={125}
                    height={125}
                    backgroundHeight={150}
                    caller={'Preview'}
                />
            </div>
            <h1 className={classes.ProfilePreviewName}>
                {`${user && `${user.first_name} ${user.last_name}`}`}
            </h1>
            <div className={classes.username_field}>
                <div className={classes.username_parent}>
                    <label className={classes.label_item}>{t('common:username')}</label>
                    <div className={classes.username_item}>
                        <div className={classes.icon}>
                            <Imagify
                                alt='Username'
                                src='/assets/icons/ic_email.svg'></Imagify>
                        </div>
                        <input
                            readOnly={true}
                            type='text'
                            value={`${user && user.username}`}
                        />
                    </div>
                </div>
                <div className={classes.navDiv}>
                    <div className={classes.nav}>
                        <TabLayout
                            firstTab={t('common:friends')}
                            secondTab={t('common:history')}
                            selectedTab={activeTab}
                            setSelectedTab={setActiveTab}
                            Id={'0'}
                        />
                    </div>
                </div>
                <div className={classes.contact_matches_history_card}>
                    {activeTab === 1 ? (
                        <ul id='ContactsList_ul' className={classes.contacts_ul}>
                            {friendsList.length > 0 ? (
                                friendsList.map(friend => (
                                    <FriendCardInfo
                                        key={friend.id}
                                        friendInfo={friend}
                                        isFriendRequest={false}
                                    />
                                ))
                            ) : (
                                <NoDataView
                                    title={t('common:no_friends')}
                                    subtitle={t('common:no_friends_desc')}
                                />
                            )}
                        </ul>
                    ) : (
                        <div
                            className={`${classes.historyContainer} background_secondary`}>
                            {gameHistory.length > 0 ? (
                                gameHistory.map((game, index) => (
                                    <LogsCard key={index} Logs={game} />
                                ))
                            ) : (
                                <NoDataView
                                    title={t('common:no_history')}
                                    subtitle={t('common:no_history_desc')}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProfilePreview;
