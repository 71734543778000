import '../../App.css';
import classes from './Navbar.module.css';
import Dropdown from './components/Dropdown/Dropdown';
import SearchBar from './components/SearchBar/SearchBar';
import UserContext from '../../context/UserContext';
import ChatSocketContext from '../../context/ChatSocketContext';
import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
    const [showDropDownMenu, setShowDropDownMenu] = useState(false);
    const [notifications, setNotifications] = useState(false);
    const { user, setShowFriends, setIsUserBlocked, setBlockedStatus } = useContext(UserContext);

    const {
        socket,
        on,
        emit,
        messages,
        setMessages,
        conversationsList,
        setConversationsList,
		setConversationLoading,
        setReadOnly,
        chattingWith,
        setChattingWith,
        setMessageLoading,
        setIsTyping,
    } = useContext(ChatSocketContext);

    const location = useLocation();

    useEffect(() => {
        if (location !== '/messages/') {
            setChattingWith(null);
        }
    }, [location]);

    useEffect(() => {
        const handleUpdateConversations = () => {
            emit('update_conversations', {});
        };

        const handleMarkMessagesSeen = data => {
			setConversationsList(conversationsList.map(conversation => {
				if (conversation.conversation.conversation_id === data.conversation_id) {
					conversation.conversation.unseen_messages = 0;
				}
				return conversation;
			}));
        };

        const handleUnseenMessages = data => {
            if (data.sender === chattingWith) {
                emit('mark_messages_seen', {
                    conversation_id: data.conversation_id,
                    messagesOf: data.sender,
                    sender: user.username
                });
            }

			setConversationsList(conversationsList.map(conversation => {
				if (conversation.conversation.conversation_id === data.conversation_id) {
					conversation.conversation.unseen_messages = data.unseen_messages;
				}
				return conversation;
			}));
        };

        const handleMessages = data => {
			if (data.conversation.participant.username === chattingWith) {
				setConversationLoading(false);
                setMessageLoading(false);
                setMessages([
                    {
                        _id: messages.length + 1,
                        message: data.message,
                        created_at: data.message.created_at,
                        sender: data.sender
                    },
                    ...messages
                ]);
            }

            const existingUserIndex = conversationsList.findIndex(conversation => conversation.conversation.conversation_id === data.conversation.conversation_id);

            if (existingUserIndex !== -1) {
				setConversationsList(conversationsList.map(conversation => {
					if (conversation.conversation.conversation_id === data.conversation.conversation_id) {
						conversation.conversation.latest_message = data.conversation.latest_message;
					}
					return conversation;
					}).sort((a, b) => {
					const timeA = new Date(a.conversation.latest_message.created_at);
					const timeB = new Date(b.conversation.latest_message.created_at);
					return timeB - timeA;
				}));
            } else {
                setConversationsList([...conversationsList, data].sort((a, b) => {
					const timeA = new Date(a.conversation.latest_message.created_at);
					const timeB = new Date(b.conversation.latest_message.created_at);
					return timeB - timeA;
				}));
            }

            if (data.sender !== user.username) {
                emit('unseen_messages', {
                    message_sender: data.sender,
                    sender: user.username,
                    conversation_id: data.conversation.conversation_id
                });
				return ;
            }
			setReadOnly(false);
        };

        const handleIsTyping = data => {
			if (data.sender === chattingWith) setIsTyping(data.typing);
        };

		const handleBlockUser = data => {
			if (data.data.blocker_username === user.username && data.data.blocked_username === chattingWith) setBlockedStatus(true);
			setIsUserBlocked(true);
		};

		const handleUnBlockUser = data => {
			if (data.data.unblocker_username === user.username && data.data.unblocked_username === chattingWith) setBlockedStatus(false);
			setIsUserBlocked(data.data.is_blocked);
		};

        if (socket !== null)
            on({
                chat_message: handleMessages,
                update_conversations: handleUpdateConversations,
                unseen_messages: handleUnseenMessages,
                mark_messages_seen: handleMarkMessagesSeen,
                typing: handleIsTyping,
				block_user: handleBlockUser,
				unblock_user: handleUnBlockUser
            });

        return () => {
            if (socket) {
                socket.onmessage = null;
            }
        };
    }, [socket, on]);

    return (
        <div className={classes.navbar}>
            <div className={classes.logo} onClick={() => setShowFriends(false)}>
                <Link to='/dashboard/me'>
                    <img
                        alt='Logo'
                        src='/assets/images/main_logo.svg'
                        className={classes.website_logo}
                    />
                </Link>
                <Link to='/dashboard/me'>
                    <p>
                        Pinger
                        <br />
                        War
                    </p>
                </Link>
            </div>
            <SearchBar />
            <div className={classes.dropdown_holder}>
                <Dropdown
                    showDropDownMenu={showDropDownMenu}
                    setShowDropDownMenu={setShowDropDownMenu}
                    notifications={notifications}
                    setNotifications={setNotifications}
                />
            </div>
        </div>
    );
}

export default Navbar;
