import '../../App.css';
import React, { useEffect, useContext } from 'react';
import classes from './Chat.module.css';
import ChatMessages from './components/ChatMessages/ChatMessages';
import ContactProfile from './components/ContactProfile/ContactProfile';
import Contacts from './components/Contacts/Contacts';
import ChatNotFound from './components/ChatNotFound/ChatNotFound';
import NotFriend from './components/NotFriend/NotFriend';
import UserContext from '../../context/UserContext';
import ChatSocketContext from '../../context/ChatSocketContext';
import api from '../../api';
import { useParams } from 'react-router-dom';

function Chat() {
    const { chattingWithQuery } = useParams();
    const { friendsList, userData, isFriendsFetching } = useContext(UserContext);
    const {
        setConversationsList,
        setChattingWith,
        setLoaded,
        loaded,
        setIsTyping,
        getMessages,
        setOffset,
        setMessages
    } = useContext(ChatSocketContext);

    useEffect(() => {
        api.get('/chat/conversations/')
            .then(res => {
                setLoaded(true);
                setConversationsList(res.data);
            })
            .catch(() => {
                setLoaded(true);
            });
    }, []);

    const isFriend = friendsList.find(friend => friend.username === chattingWithQuery);
    useEffect(() => {
        if (!chattingWithQuery) return;
        getMessages(isFriend, chattingWithQuery);
    }, [loaded, chattingWithQuery]);

    useEffect(() => {
        setChattingWith(chattingWithQuery);
        setIsTyping(false);
    }, [chattingWithQuery]);

    useEffect(() => {
        return () => {
            setChattingWith(null);
            setOffset(0);
            setMessages([]);
        };
    }, []);

    return (
        <div className={classes.root_view}>
            <div
                className={`${classes.main_holder} ${!chattingWithQuery || (chattingWithQuery && !isFriend) ? '' : classes.add_background}`}>
                <Contacts />
                {chattingWithQuery != null && !isFriendsFetching ? (
                    isFriend ? (
                        <>
                            <ChatMessages />
                            <ContactProfile />
                        </>
                    ) : (
                        <NotFriend userData={userData} />
                    )
                ) : (
                    <ChatNotFound />
                )}
            </div>
        </div>
    );
}
export default Chat;
