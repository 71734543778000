import '../../App.css';
import classes from './Imagify.module.css';
import React, { useState } from 'react';

const Imagify = props => {
    const [loaded, setLoaded] = useState(false);

    const handleImageLoaded = () => {
        setLoaded(true);
    };

    return (
        <img
            id={props.id}
            src={props.src}
            alt={props.alt}
            onLoad={handleImageLoaded}
            className={
                loaded ? `${props.className} ${classes.fadeIn}` : `${props.className}`
            }
            onClick={props.onClick}
            style={{ opacity: loaded ? 1 : 0, ...props.style }}
        />
    );
};

export default Imagify;
