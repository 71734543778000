import Imagify from '../../../../components/Imagify/Imagify';
import UserContext from '../../../../context/UserContext';
import classes from './UserBasicInfo.module.css';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect } from 'react';

export default function UserBasicInfo({ usernameSearchQuery }) {
    const { t } = useTranslation(['dashboard', 'common']);
    const { user, userData } = useContext(UserContext);
    const [greeting, setGreeting] = useState('');
    const date = new Date();
    const hours = date.getHours();

    useEffect(() => {
        if (hours >= 4 && hours < 12) {
            setGreeting(t('greeting_morning_msg'));
        } else if (hours >= 12 && hours < 17) {
            setGreeting(t('greeting_afternoon_msg'));
        } else if (hours >= 17 && hours < 21) {
            setGreeting(t('greeting_evening_msg'));
        } else {
            setGreeting(t('greeting_night_msg'));
        }
    }, [hours]);

    return (
        <div className={classes.user_basic_info_container}>
            <div className={classes.info_container}>
                <h1>
                    <span>
                        {greeting}
                        <br />
                        {userData &&
                        userData.username &&
                        userData.username === usernameSearchQuery
                            ? userData.first_name
                            : (user && user.first_name) || (
                                  <Skeleton width={150} height={25} />
                              )}
                    </span>
                </h1>

                <Imagify
                    alt='profile'
                    src={
                        userData &&
                        userData.profile_picture &&
                        userData.username === usernameSearchQuery
                            ? userData.profile_picture
                            : user && user.profile_picture
                    }
                />
            </div>
        </div>
    );
}
