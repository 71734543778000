import classes from './ProfilePerformance.module.css';
import AnimatedCounter from '../../../../components/AnimatedCounter/AnimatedCounter';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../context/UserContext';
import SocketContext from '../../../../context/SocketContext';

export default function ProfilePerformance({ t, is_mine, user }) {
    const { friendsList, gameHistory, gameUserHistory, winRate } =
        useContext(UserContext);
    const { messageListener, socket } = useContext(SocketContext);
    const [profileVisitors, setProfileVisitors] = useState(0);

    useEffect(() => {
        if (!socket) return;
        messageListener({
            profile_visitors: () => {
                setProfileVisitors(profileVisitors + 1);
            }
        });
    }, [messageListener, socket]);

    useEffect(() => {
        if (!user) return;
        setProfileVisitors(user.profile_visitors);
    }, [user]);

    return (
        <div className={classes.user_general_stats}>
            <div className={classes.user_general_stats_1}>
                <div>
                    <h1>{t('user_visitors')}</h1>
                    <AnimatedCounter endValue={profileVisitors} />
                </div>
                <div>
                    <h1>{t('user_friend_count')}</h1>
                    <AnimatedCounter endValue={friendsList && friendsList.length} />
                </div>
            </div>

            <div className={classes.user_general_stats_2}>
                <div>
                    <h1>{t('user_win_rate')}</h1>
                    <AnimatedCounter endValue={winRate} />
                </div>
                <div>
                    <h1>{t('user_match_count')}</h1>
                    <AnimatedCounter
                        endValue={
                            is_mine
                                ? `${gameHistory && gameHistory.length}`
                                : `${gameUserHistory && gameUserHistory.length}`
                        }
                    />
                </div>
            </div>
        </div>
    );
}
