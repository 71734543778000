import '../../../../App.css';
import React, { useContext, useEffect, useState, useRef } from 'react';
import classes from './TournamentSelection.module.css';
import GameContext from '../../../../context/GameContext';
import TournamentCard from '../GameInvitation/TournamentCard';
import SocketContext from '../../../../context/SocketContext';
import api from '../../../../api';
import NoDataView from '../../../../components/NoDataView/NoDataView';
import { useTranslation } from 'react-i18next';


function TournamentSelection({ setRoom, Host, setHost }) {
    const { tournamentRooms, setTournamentRooms } = useContext(GameContext);
    const { messageListener, socket } = useContext(SocketContext);
    const { t } = useTranslation(['tournaments', 'common']);
    const [onlineUsers, setOnlineUsers] = useState(0);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [filterText, setFilterText] = useState(t('tournaments:global_tournaments'));

    async function getTournamentRooms(filter = 'all') {
        await api
            .get('/tournaments/get_tournaments/' + filter + '/')
            .then(res => {
                setTournamentRooms(res.data.tournaments);
            })
            .catch(() => {});
    }

    useEffect(() => {
        if (!socket) return;
        messageListener({
            online_users: data => {
                setOnlineUsers(data.data);
            }
        });
    }, [messageListener, socket]);

    function myFunction() {
        setDropdownVisible(!isDropdownVisible);
    }

    const wrapperRef = useRef(null);

    useEffect(() => {
        function clickOutsideFilterBy(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        }
        document.addEventListener('mousedown', clickOutsideFilterBy);
    }, [setDropdownVisible, wrapperRef]);

    return (
        <div
            id='tournamentSelection'
            className={`${classes.TournamentSelection} ${Host && classes.host}`}>
            <h1 className={classes.TournamentSelection__title}>
                {t('tournaments:tournaments')}
            </h1>
            <div
                style={{
                    backgroundImage:
                        'url(/assets/images/tournament_banner_pingpong.webp)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center 20%',
                    borderRadius: '20px',
                    overflow: 'hidden'
                }}
                className={classes.TournamentSelection__banner}>
                <div className={classes.TournamentSelection__activePlayers}>
                    <h1 className={classes.tournament_title}>
                        {t('tournaments:tournament_card_title')}
                    </h1>
                    <div className={classes.TournamentSelection__container}>
                        <div
                            className={
                                classes.TournamentSelection__activePlayers__circle
                            }></div>
                        <p
                            style={{
                                color: 'var(--text-primary)',
                                fontSize: '1rem'
                            }}>
                            {t('tournaments:players_online', { count: onlineUsers })}
                        </p>
                    </div>
                </div>
                <div className={classes.TournamentSelection__hostTournament}>
                    <div
                        onClick={() => {
                            if (!Host) setHost(!Host);
                        }}
                        className={classes.TournamentSelection__hostButton}>
                        <img alt='Host new tournament' src='/assets/icons/ic_plus.svg' />
                        <h1>{t('tournaments:host_tournament')}</h1>
                    </div>
                </div>
                <div className={classes.TournamentSelection__banner__overlay}></div>
            </div>
            <div className={classes.TournamentSelection__filter__container}>
                <p className={classes.TournamentSelection__active_title}>{filterText}</p>
                <div ref={wrapperRef} className={classes.TournamentSelection_dropdown}>
                    <button
                        onClick={e => {
                            e.preventDefault();
                            myFunction();
                        }}
                        className={classes.TournamentSelection_dropbtn}>
                        <p>{t('tournaments:filter_by')}</p>
                        <div className={classes.line_image_container}>
                            <div className={classes.line}></div>
                            <img
                                className={`${classes.arrow} ${isDropdownVisible ? classes.rotate : ''}`}
                                src='/assets/icons/ic_expand_arrow.svg'
                                alt='Dropdown'></img>
                        </div>
                    </button>
                    <div
                        id='myDropdown'
                        className={`${classes.TournamentSelection_dropdown_content} ${isDropdownVisible ? classes.show : ''}`}>
                        <div
                            className={`${classes.btn} ${filterText === t('tournaments:global_tournaments') ? classes.currentBtn : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                getTournamentRooms('all');
                                setFilterText(t('tournaments:global_tournaments'));
                            }}>
                            <p>{t('tournaments:global_tournaments')}</p>
                        </div>
                        <div
                            className={`${classes.btn} ${filterText === t('tournaments:joined_tournaments') ? classes.currentBtn : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                getTournamentRooms('finished');
                                setFilterText(t('tournaments:joined_tournaments'));
                            }}>
                            <p>{t('tournaments:joined_tournaments')}</p>
                        </div>
                        <div
                            className={`${classes.btn} ${filterText === t('tournaments:active_tournaments') ? classes.currentBtn : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                getTournamentRooms('participating');
                                setFilterText(t('tournaments:active_tournaments'));
                            }}>
                            <p>{t('tournaments:active_tournaments')}</p>
                        </div>
                    </div>
                </div>
            </div>

            {tournamentRooms.length === 0 ? (
                <NoDataView
                    title={t('tournaments:no_tournaments_title')}
                    subtitle={t('tournaments:no_tournaments_description')}
                />
            ) : (
                <div className={classes.TournamentSelection__activeTournaments}>
                    {tournamentRooms.map((tournament, index) => (
                        <TournamentCard
                            key={index}
                            setRoom={setRoom}
                            tournament={tournament}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default TournamentSelection;
