import '../../App.css';
import classes from './TabLayout.module.css';
import React, { useEffect } from 'react';

export default function TabLayout({
    firstTab,
    secondTab,
    ClassName,
    setSelectedTab,
    selectedTab,
    Id = 'test'
}) {
    const handleTab = (tab, param) => {
        setSelectedTab(tab);
        const storedObject = JSON.parse(localStorage.getItem('settings_params'));
        if (storedObject) {
            storedObject[param] = tab;
            localStorage.setItem('settings_params', JSON.stringify(storedObject));
        }
    };

    useEffect(() => {
        if (Id === 'game' || Id === 'game_connectivity') {
            if (selectedTab === 'TicTacToe' || selectedTab === 'local') {
                document.getElementById('tabLayout' + Id).style.left =
                    'calc(86% - ((100% - 4px) / 6) - 2px)';
            }
        }
    }, []);
    return (
        <div className={`${classes.selector} ${ClassName} background_secondary`}>
            <div className={classes.buttons}>
                <div
                    className={classes.rightSide}
                    onClick={() => {
                        document.getElementById('tabLayout' + Id).style.left =
                            'calc(35.5% - ((100% - 4px) / 6))';
                        Id !== 'game' && Id !== 'game_connectivity' && setSelectedTab(1);

                        Id === 'game' && handleTab('PingPong', 'selectedGame');
                        Id === 'game_connectivity' &&
                            handleTab('online', 'selectedGameConnectivity');
                    }}>
                    <p className={classes.Text}>{firstTab}</p>
                </div>
                <div className={classes.separator}></div>
                <div
                    className={classes.leftSide}
                    onClick={() => {
                        document.getElementById('tabLayout' + Id).style.left =
                            'calc(86% - ((100% - 4px) / 6) - 2px)';
                        Id !== 'game' && Id !== 'game_connectivity' && setSelectedTab(2);

                        Id === 'game' && handleTab('TicTacToe', 'selectedGame');
                        Id === 'game_connectivity' &&
                            handleTab('local', 'selectedGameConnectivity');
                    }}>
                    <p className={classes.Text}>{secondTab}</p>
                </div>
            </div>
            <div className={classes.tabLayout} id={`tabLayout` + Id}></div>
        </div>
    );
}
