import '../../../../App.css';
import React, { useEffect, useContext, useState } from 'react';
import classes from './BeforeGameGameBoy.module.css';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../../context/UserContext';
import Imagify from '../../../../components/Imagify/Imagify';

function BeforeGameGameBoy() {
    const { t } = useTranslation(['common', 'game']);
    const { selectedBackground, selectedGame, isOnlineGame } = useContext(UserContext);
    const [currentGameImageRules, setCurrentImageRules] = useState('');
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        if (selectedGame === 'PingPong') {
            if (isOnlineGame) {
                setCurrentImageRules('/assets/images/img_online_pong_rules.svg');
            } else {
                setCurrentImageRules('/assets/images/img_local_pong_rules.svg');
            }
        } else {
            setCurrentImageRules('/assets/images/img_tic_tac_toe_rules.svg');
        }
    }, [selectedGame, isOnlineGame]);

    useEffect(() => {
        if (countdown === 0 || isOnlineGame) return;

        const timer = setTimeout(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearTimeout(timer);
    }, [countdown]);

    let frame_theme = {
        background: selectedBackground
    };

    return (
        <div className={classes.BeforeGameGameBoy_holder}>
            <div className={classes.BeforeGameGameBoy_frame}>
                <div
                    className={classes.BeforeGameGameBoy_screen_frame}
                    style={frame_theme}>
                    {!isOnlineGame && <h1>{countdown > 0 ? countdown : 'GO'}</h1>}
                    <Imagify src={currentGameImageRules} />
                </div>

                <div className={classes.BeforeGameGameBoy_text_holder}>
                    <h1>{t('game:rules_how_to_title')}</h1>
                    {selectedGame === 'PingPong' ? (
                        isOnlineGame ? (
                            <h2>{t('game:rules_how_to_online_pong')}</h2>
                        ) : (
                            <h2>{t('game:rules_how_to_local_pong')}</h2>
                        )
                    ) : (
                        <h2>{t('game:rules_how_to_tic')}</h2>
                    )}
                </div>

                <div className={classes.BeforeGameGameBoy_top_buttons_holder}>
                    <img alt='Navigation' src='/assets/images/gameboy_left_buttons.svg' />
                    <img
                        alt='Navigation'
                        src='/assets/images/gameboy_right_buttons.svg'
                    />
                </div>
                <div className={classes.BeforeGameGameBoy_bottom_buttons_holder}>
                    <img alt='Start' src='/assets/images/gameboy_bottom_buttons.svg' />
                    <img alt='Sound holes' src='/assets/images/gameboy_sound_holes.svg' />
                </div>
            </div>
        </div>
    );
}

export default BeforeGameGameBoy;
