import React, { useEffect, useState, useContext } from 'react';
import UserContext from '../../../../../../context/UserContext';
import api from '../../../../../../api';
import profileStyles from './ProfileForm.module.css';
import commonStyles from '../common.module.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const styles = { ...profileStyles, ...commonStyles };

export default function ProfileForm(props) {
    const { user, fetchUser, profilePictureUrl, setIsLoggedIn } = useContext(UserContext);
    const { t } = useTranslation(['settings', 'common', 'messages', 'authentication']);
    const [isDeviceRemembered, setIsDeviceRemembered] = useState(
        user && user.remember_device
    );

    useEffect(() => {
        if (user === null) return;
        document.getElementById('input_first_name').value = user.first_name;
        document.getElementById('input_last_name').value = user.last_name;
        document.getElementById('input_username').value = user.username;
        document.getElementById('remember_device').checked = user.remember_device;
    }, [user]);

    const handleDeviceRememberChange = () => {
        setIsDeviceRemembered(isDeviceRemembered);
    };

    const handleSaveUserChanges = async e => {
        e.preventDefault();
        saveUserChanges(props, fetchUser, profilePictureUrl, user, t);
    };

    return (
        <div className={styles.profile_root_container}>
            <form className={styles.form_container} onSubmit={handleSaveUserChanges}>
                <div className={styles.first_last_name_container}>
                    <div className={styles.input_layout_container}>
                        <label>{t('common:first_name')}</label>
                        <div className={styles.info_item}>
                            <img alt='Avatar' src='/assets/icons/ic_user.svg' />
                            <input id='input_first_name' type='text' />
                        </div>
                    </div>

                    <div className={styles.input_layout_container}>
                        <label>{t('common:last_name')}</label>
                        <div className={styles.info_item}>
                            <img alt='Avatar' src='/assets/icons/ic_user.svg' />
                            <input id='input_last_name' type='text' />
                        </div>
                    </div>
                </div>

                <div className={styles.input_layout_container}>
                    <label>{t('common:username')}</label>
                    <div className={styles.info_item}>
                        <img alt='Uername' src='/assets/icons/ic_email.svg' />
                        <input id='input_username' type='text' disabled={user && user.is_intra_user}/>
                    </div>
                </div>

                <div className={styles.remember_device_container}>
                    <label className={styles.switch}>
                        <input
                            id='remember_device'
                            type='checkbox'
                            onChange={() => handleDeviceRememberChange()}
                        />
                        <span className={styles.slider} />
                    </label>
                    <h1>{t('authentication:2fa_remember_device')}</h1>
                </div>

                <button className={styles.save_changes_button} type='submit'>
                    <img alt='Done' src='/assets/icons/ic_check.svg' />
                    <h1>{t('common:save_changes')}</h1>
                </button>
            </form>

            <div className={styles.danger_zone}>
                <h1 className={styles.danger_zone_title}>
                    {t('settings:danger_zone_title')}
                </h1>

                <div className={styles.danger_zone_content}>
                    <div
                        className={styles.danger_zone_item}
                        onClick={e => {
                            e.preventDefault();
                            manageAccountDiscovery('delete_account', t, setIsLoggedIn);
                        }}>
                        <h1> {t('settings:danger_item1_title')}</h1>
                        <p> {t('settings:danger_item1_desc')}</p>
                    </div>
                    <div
                        className={styles.danger_zone_item}
                        onClick={e => {
                            e.preventDefault();
                            manageAccountDiscovery('anonymize_account', t, setIsLoggedIn);
                        }}>
                        <h1> {t('settings:danger_item2_title')}</h1>
                        <p> {t('settings:danger_item2_desc')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

async function saveUserChanges(props, fetchUser, profilePictureUrl, user, t) {
    toast.dismiss();
    const updatedData = {};
    if (user.first_name !== document.getElementById('input_first_name').value) {
        updatedData.first_name = document.getElementById('input_first_name').value;
    }
    if (user.last_name !== document.getElementById('input_last_name').value) {
        updatedData.last_name = document.getElementById('input_last_name').value;
    }
    if (user.username !== document.getElementById('input_username').value) {
        updatedData.username = document.getElementById('input_username').value;
    }
    if (profilePictureUrl !== user.profile_picture && profilePictureUrl !== null) {
        updatedData.profile_picture = profilePictureUrl;
    }
    if (props.coverImageIndex !== user.cover_picture_index) {
        updatedData.cover_picture_index = props.coverImageIndex;
    }
    if (user.remember_device !== document.getElementById('remember_device').checked) {
        updatedData.remember_device = document.getElementById('remember_device').checked;
        if (updatedData.remember_device === false) {
            localStorage.removeItem('previous_user');
        }
    }

    await api
        .patch('/users/me/update_details/', updatedData)
        .then(res => {
            if (res.status === 200) {
                toast.success(t('settings:msg_info_saved'));
                fetchUser();
            }
        })
        .catch(err => {
            if (err.response.status === 304) {
                toast.error(t('messages:msg_error_no_change'));
                return;
            }
            if (err.response.data.error_code === 2) {
                toast.error(t('messages:msg_error_username_number_must'));
                return;
            }
            if (err.response.data.error_code === 3) {
                toast.error(t('messages:msg_error_username_len'));
                return;
            }
            if (err.response.data.error_code === 4) {
                toast.error(t('messages:msg_error_first_name_len'));
                return;
            }
            if (err.response.data.error_code === 5) {
                toast.error(t('messages:msg_error_last_name_len'));
                return;
            }
            if (err.response.data.error_code === 6) {
                toast.error(t('messages:msg_error_no_usrnm_intra'));
                return;
            }
            toast.error(t('messages:msg_error_cant_update_user'));
        });
}

async function manageAccountDiscovery(option, t, setIsLoggedIn) {
    toast.dismiss();
    let message =
        option === 'delete_account'
            ? t('settings:msg_confirm_deletion')
            : t('settings:msg_confirm_anonymization');
    const toastId = toast.warn(message, {
        onClick: () => {
            toast.dismiss(toastId);
            api.delete(`/users/me/${option}/`)
                .then(res => {
                    if (res.status === 200) {
                        localStorage.clear();
                        setIsLoggedIn(false);
                    }
                })
                .catch((err) => {
                    if (err.response.status === 423) {
                        toast.error(t('messages:msg_error_del_disabled'));
                        return;
                    }
                    toast.error(t('messages:msg_cant_delete_account'));
                });
        },
        autoClose: 7000
    });
}
