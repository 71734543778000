import '../../../../App.css';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../api';
import twoFAStyles from './2FAForm.module.css';
import commonStyles from '../common.module.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import React, { useState, useContext } from 'react';
import UserContext from '../../../../context/UserContext';

const styles = { ...commonStyles, ...twoFAStyles };

export default function TwoFactorAuthForm({ authStage, setAuthStage, userCredentials }) {
    const { t } = useTranslation(['authentication', 'common']);
    const [isDeviceRemembered, setIsDeviceRemembered] = useState(true);
    const { setIsLoggedIn } = useContext(UserContext);

    const handleDeviceRememberChange = () => {
        setIsDeviceRemembered(!isDeviceRemembered);
    };

    return (
        <div
            className={`${styles.auth_root_container} ${authStage === 2 ? styles.active : styles.inActive}`}>
            <div className={styles.header_container}>
                <div className={styles.logo_container}>
                    <img alt='Logo' src='/assets/images/main_logo.svg' />
                    <h1>{t('common:app_name')}</h1>
                </div>
                <h2 className='fs-2rem clr-white'>{t('authentication:2fa_title')}</h2>
                <h3 className='fs-1d2rem clr-white'>{t('authentication:2fa_desc')}</h3>
            </div>

            <form
                className={styles.auth_form}
                onSubmit={e => {
                    e.preventDefault();
                    validate2FACode(userCredentials, t, setIsLoggedIn);
                }}>
                <div className={styles.auth_form_content}>
                    <div className={styles.input_layout_container}>
                        <label>{t('authentication:2fa_enter_code')}</label>
                        <div>
                            <img alt='OTP Key' src='/assets/icons/ic_key.svg' />
                            <input
                                id='2fa_code'
                                required
                                placeholder='●●●●●●'
                                type='text'
                                autoComplete='one-time-code'
                                inputMode='numeric'
                                pattern='[0-9]*'
                                maxLength='6'
                            />
                        </div>
                    </div>

                    <div className={styles.remember_device_container}>
                        <label className={styles.switch}>
                            <input
                                id='remember_device'
                                checked={isDeviceRemembered}
                                type='checkbox'
                                onChange={handleDeviceRememberChange}
                            />
                            <span className={styles.slider} />
                        </label>
                        <h1>{t('authentication:2fa_remember_device')}</h1>
                    </div>

                    <button type='submit' className={styles.submit_btn}>
                        <h2>{t('common:verify')}</h2>
                    </button>
                </div>

                <div className={styles.or_divider}>
                    <div className={styles.divider_line}></div>
                    <h2>{t('common:or')}</h2>
                    <div className={styles.divider_line}></div>
                </div>

                <div onClick={() => setAuthStage(0)} className={styles.login_intra42_btn}>
                    <img alt='back' src='/assets/icons/ic_back.svg' />
                    <h2>{t('authentication:2fa_back_to_login')}</h2>
                </div>
            </form>
        </div>
    );
}

function validate2FACode(userCredentials, t, setIsLoggedIn) {
    const code = document.getElementById('2fa_code').value;
    const remember_device = document.getElementById('remember_device').checked;

    if (!code) {
        toast.error(t('authentication:2fa_enter_code'));
        return;
    }
    api.post('/auth/validate_2fa/', {
        code: code,
        username: userCredentials.username,
        password: userCredentials.password,
        remember_device: remember_device
    })
        .then(response => {
            if (response.status === 200) {
                if (response.data.previous_user) {
                    localStorage.setItem('previous_user', response.data.previous_user);
                }
                toast.dismiss();
                setTimeout(() => setIsLoggedIn(true), 500);
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                toast.error(t('authentication:2fa_invalid_code'));
            } else {
                toast.error(t('authentication:2fa_invalid_code_request'));
            }
        });
}
