import '../../App.css';
import styles from './Authentication.module.css';
import Gameboy from './Components/Gameboy/Gameboy';
import AuthForm from './Components/AuthForm/AuthForm';
import SignUpForm from './Components/SignUpForm/SignUpForm';
import TwoFactorAuthForm from './Components/2FAForm/2FAForm';
import React, { useContext, useState, useEffect } from 'react';
import GameContext from '../../context/GameContext';
import ChatSocketContext from '../../context/ChatSocketContext';
import SocketContext from '../../context/SocketContext';

function Authentication() {
    const [authStage, setAuthStage] = useState(0);
    const [userCredentials, setUserCredentials] = useState({
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        email: ''
    });

    const {disconnectGame} = useContext(GameContext)
    const {disconnectChat} = useContext(ChatSocketContext)
    const {disconnectNotifications} = useContext(SocketContext)

    useEffect(() => {
        disconnectGame();
        disconnectChat();
        disconnectNotifications();
    }, []);

    return (
        <div className={styles.root_container}>
            <div className={styles.content_container}>
                <div className={styles.authentication_holder}>
                    <AuthForm
                        authStage={authStage}
                        setAuthStage={setAuthStage}
                        userCredentials={userCredentials}
                        setUserCredentials={setUserCredentials}
                    />
                    <SignUpForm
                        authStage={authStage}
                        setAuthStage={setAuthStage}
                        userCredentials={userCredentials}
                        setUserCredentials={setUserCredentials}
                    />
                    <TwoFactorAuthForm
                        authStage={authStage}
                        setAuthStage={setAuthStage}
                        userCredentials={userCredentials}
                    />
                </div>
                <Gameboy />
            </div>
        </div>
    );
}

export default Authentication;
