import React, { useEffect, useState } from 'react';
import styles from './TicTacToeAuth.module.css';
import Imagify from '../../../../components/Imagify/Imagify';

function TicTacToe({ startTheGame }) {
    const [gameCells, setGameCells] = useState({
        c_1: '',
        c_2: '',
        c_3: '',
        c_4: '',
        c_5: '',
        c_6: '',
        c_7: '',
        c_8: '',
        c_9: ''
    });
    const allowedWords = ['X', 'O'];
    const [isX, setIsX] = useState(true);

    const calculateWinner = () => {
        const lines = [
            [gameCells.c_1, gameCells.c_2, gameCells.c_3],
            [gameCells.c_4, gameCells.c_5, gameCells.c_6],
            [gameCells.c_7, gameCells.c_8, gameCells.c_9],
            [gameCells.c_1, gameCells.c_4, gameCells.c_7],
            [gameCells.c_2, gameCells.c_5, gameCells.c_8],
            [gameCells.c_3, gameCells.c_6, gameCells.c_9],
            [gameCells.c_1, gameCells.c_5, gameCells.c_9],
            [gameCells.c_3, gameCells.c_5, gameCells.c_7]
        ];

        for (let i = 0; i < lines.length; i++) {
            if (
                lines[i][0] &&
                lines[i][0] === lines[i][1] &&
                lines[i][0] === lines[i][2]
            ) {
                return lines[i][0];
            }
        }
        return null;
    };

    const allCellsFilled = () => {
        return Object.values(gameCells).every(cell => allowedWords.includes(cell));
    };

    const handleClick = e => {
        if (!startTheGame) return;
        if (gameCells[e.target.name] !== '') {
            return;
        }
        if (isX) {
            setGameCells({ ...gameCells, [e.target.name]: 'X' });
        } else {
            setGameCells({ ...gameCells, [e.target.name]: 'O' });
        }

        setIsX(!isX);
    };

    useEffect(() => {
        const winner = calculateWinner();
        if (winner) {
            setGameCells({
                c_1: '',
                c_2: '',
                c_3: '',
                c_4: '',
                c_5: '',
                c_6: '',
                c_7: '',
                c_8: '',
                c_9: ''
            });
        }
        if (allCellsFilled()) {
            setGameCells({
                c_1: '',
                c_2: '',
                c_3: '',
                c_4: '',
                c_5: '',
                c_6: '',
                c_7: '',
                c_8: '',
                c_9: ''
            });
        }
    });

    return (
        <div className={styles.tictactoe}>
            <div className={styles.tictactoe__board}>
                <div className={styles.tictactoe__row}>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_1'
                        onClick={handleClick}>
                        {gameCells.c_1 === 'X' || gameCells.c_1 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_1 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_2'
                        onClick={handleClick}>
                        {gameCells.c_2 === 'X' || gameCells.c_2 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_2 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_3'
                        onClick={handleClick}>
                        {gameCells.c_3 === 'X' || gameCells.c_3 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_3 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                </div>
                <div className={styles.border_bottom__one}></div>
                <div className={styles.border_top__one}></div>
                <div className={styles.tictactoe__row}>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_4'
                        onClick={handleClick}>
                        {gameCells.c_4 === 'X' || gameCells.c_4 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_4 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_5'
                        onClick={handleClick}>
                        {gameCells.c_5 === 'X' || gameCells.c_5 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_5 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_6'
                        onClick={handleClick}>
                        {gameCells.c_6 === 'X' || gameCells.c_6 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_6 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                </div>
                <div className={styles.border_bottom__two}></div>
                <div className={styles.border_top__two}></div>
                <div className={styles.tictactoe__row}>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_7'
                        onClick={handleClick}>
                        {gameCells.c_7 === 'X' || gameCells.c_7 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_7 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_8'
                        onClick={handleClick}>
                        {gameCells.c_8 === 'X' || gameCells.c_8 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_8 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={styles.tictactoe__cell}
                        name='c_9'
                        onClick={handleClick}>
                        {gameCells.c_9 === 'X' || gameCells.c_9 === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    gameCells.c_9 === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TicTacToe;
