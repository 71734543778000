import '../../App.css';
import React, { useContext } from 'react';
import classes from './Dashboard.module.css';
import TabLayout from '../../components/TabLayout/TabLayout';
import FriendCardInfo from '../../components/FriendCardInfo/FriendCardInfo';
import ProfilePerformance from './Components/ProfilePerformance/ProfilePerformance';
import BalanceProgress from './Components/BalanceProgress/BalanceProgress';
import UserBasicInfo from './Components/UserBasicInfo/UserBasicInfo';
import LevelContainer from './Components/LevelContainer/LevelContainer';
import UserContext from '../../context/UserContext';
import Imagify from '../../components/Imagify/Imagify';
import NoDataView from '../../components/NoDataView/NoDataView';
import ChartView from './Components/ChartView/ChartView';
import LogsCard from '../../components/LogsCard/LogsCard';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UsersAdminCard from './Components/UsersAdminCard/UsersAdminCard';

function Dashboard() {
    const { t } = useTranslation(['dashboard', 'common', 'resources']);
    const { usernameSearchQuery } = useParams();

    const {
        user,
        friendRequests,
        friendsList,
        userFriendsList,
        fetchUserFriends,
        setUserData,
        userData,
        fetchUser,
        fetchUserData,
        coverImageUrl,
        setCoverImageUrl,
        showFriends,
        setShowFriends,
        gameHistory,
        gameUserHistory,
        fetchUserGameHistory,
        setWinRate,
        requestResourceFetched
    } = useContext(UserContext);

    const [loadMore, setLoadMore] = useState(3);
    const [selectedTab, setSelectedTab] = useState(1);
    const navigate = useNavigate();
    const [AllUsers, setAllUsers] = useState([]);

    useEffect(() => {
        if (
            !user ||
            (usernameSearchQuery !== 'me' && user.username !== usernameSearchQuery)
        )
            return;
        if (user.remember_device) {
            savePreviousUser(user);
        } else {
            localStorage.removeItem('previous_user');
        }
        setCoverImageUrl(t('resources:cover_images')[user.cover_picture_index]);
    }, [user, usernameSearchQuery]);

    useEffect(() => {
        if (
            user &&
            (user.username === usernameSearchQuery || usernameSearchQuery === 'me')
        ) {
            setUserData(null);
            fetchUser();
            setAllUsers(user.all_users);
            return;
        }
        fetchUserData(setUserData, usernameSearchQuery, setCoverImageUrl, t, navigate);
        setCoverImageUrl(
            userData && t('resources:cover_images')[userData.cover_picture_index]
        );
    }, [usernameSearchQuery]);

    useEffect(() => {
        if (userData && userData.username === user && user.username) return;
        if (userData) {
            fetchUserGameHistory(userData.id);
            fetchUserFriends(userData.username);
            setWinRate(userData.win_rate);
        }
    }, [userData]);

    const handleShowFriends = () => {
        setShowFriends(true);
    };

    if (!requestResourceFetched) return <ScreenLoading />;

    return (
        <div className={classes.dashboard_container_root}>
            <div
                className={
                    showFriends
                        ? classes.user_stats_card_root_hidden
                        : classes.user_stats_card_root
                }>
                <div className={classes.announcement_banner}>
                    <h1>
                        The website is significantly different from running on localhost.
                        You may experience bugs and lag since we are using free services
                        to host the website. If you encounter any issues, please let one
                        of the team members know.
                    </h1>
                </div>
                <Imagify
                    alt='cover'
                    className={classes.user_background_cover}
                    src={coverImageUrl}
                />
                <div className={classes.user_info_container}>
                    <UserBasicInfo usernameSearchQuery={usernameSearchQuery} />
                    <LevelContainer usernameSearchQuery={usernameSearchQuery} />
                    <ChartView />
                    <button className={classes.friends_btn} onClick={handleShowFriends}>
                        {t('dashboard:user_friends_history')}
                    </button>
                    <div className={classes.user_stats_grid}>
                        <div className={classes.user_performance_title}>
                            <h1>{t('dashboard:user_perf')}</h1>
                        </div>

                        <div className={classes.user_progress_title}>
                            <h1>{t('dashboard:user_progress')}</h1>
                        </div>
                        <ProfilePerformance
                            is_mine={usernameSearchQuery === 'me'}
                            user={
                                userData && usernameSearchQuery !== 'me' ? userData : user
                            }
                            t={t}></ProfilePerformance>
                        <BalanceProgress
                            user={
                                userData && usernameSearchQuery !== 'me' ? userData : user
                            }
                            t={t}
                        />
                        {user.username === 'moulmoud' ||
                        user.username === 'isalama' ||
                        user.username === 'hel-mamo' ||
                        user.username === 'abahsine' ? (
                            <div className={classes.footer_2}>
                                <h1
                                    style={{
                                        minWidth: '100px'
                                    }}>
                                    Total users: {AllUsers.length}
                                </h1>
                                {AllUsers.map((usser, index) => (
                                    <UsersAdminCard key={index} user={usser} />
                                ))}
                            </div>
                        ) : (
                            <div className={classes.footer}>
                                {t('dashboard:user_credit')}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div
                className={
                    showFriends
                        ? classes.user_fh_container
                        : classes.user_fh_container_hidden
                }>
                <h1 className={classes.fh_title}>
                    {t('dashboard:user_friends_history')}
                </h1>
                <div
                    style={{
                        margin: '0.8rem'
                    }}>
                    <TabLayout
                        firstTab={t('common:friends')}
                        secondTab={t('common:history')}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                </div>
                {selectedTab === 1 ? (
                    <>
                        {friendRequests.length > 0 && (
                            <div className={classes.friends_container}>
                                <h1 className='clr-white font-weight-light'>
                                    {t('dashboard:user_friend_requests')}
                                </h1>
                                {friendRequests.map(
                                    (friendInfo, index) =>
                                        index < loadMore && (
                                            <FriendCardInfo
                                                key={friendInfo.id}
                                                friendInfo={friendInfo}
                                                isFriendRequest={true}
                                            />
                                        )
                                )}
                                {friendRequests.length > 3 && (
                                    <div
                                        className={`${classes.friend_request_see_more} background_secondary`}
                                        onClick={e => {
                                            e.preventDefault();
                                            setLoadMore(loadMore + 3);
                                        }}>
                                        <div className={classes.arrowHolder}>
                                            <Imagify
                                                className={classes.Arrow}
                                                src='/assets/icons/ic_expand_arrow.svg'
                                                alt='arrow'
                                            />
                                        </div>

                                        <h1 className='clr-white font-weight-light'>
                                            {t('common:see_more')}
                                        </h1>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className={classes.friends_container}>
                            {usernameSearchQuery === 'me' ||
                            user.username === usernameSearchQuery
                                ? friendsList.length > 0 && (
                                      <h1 className='clr-white font-weight-light'>
                                          {t('common:friends')}
                                      </h1>
                                  )
                                : userFriendsList.length > 0 && (
                                      <h1 className='clr-white font-weight-light'>
                                          {t('common:friends')}
                                      </h1>
                                  )}

                            {usernameSearchQuery === 'me' ||
                            user.username === usernameSearchQuery ? (
                                friendsList.length > 0 ? (
                                    friendsList.map(friendInfo => (
                                        <FriendCardInfo
                                            key={friendInfo.id}
                                            friendInfo={friendInfo}
                                            isFriendRequest={false}
                                        />
                                    ))
                                ) : (
                                    <NoDataView
                                        title={t('common:no_friends')}
                                        subtitle={t('common:no_friends_desc')}
                                    />
                                )
                            ) : userFriendsList.length > 0 ? (
                                userFriendsList.map(friendInfo => (
                                    <FriendCardInfo
                                        key={friendInfo && friendInfo.id}
                                        friendInfo={friendInfo}
                                        isFriendRequest={false}
                                    />
                                ))
                            ) : (
                                <NoDataView
                                    title={t('common:no_friends_user', {
                                        name: userData && userData.first_name
                                    })}
                                    subtitle={t('common:no_friends_desc_user')}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <div className={`${classes.historyContainer}`}>
                        {usernameSearchQuery === 'me' ||
                        user.username === usernameSearchQuery ? (
                            gameHistory.length > 0 ? (
                                gameHistory.map((game, index) => (
                                    <LogsCard key={index} Logs={game} />
                                ))
                            ) : (
                                <NoDataView
                                    title={t('common:no_history')}
                                    subtitle={t('common:no_history_desc')}
                                />
                            )
                        ) : gameUserHistory.length > 0 ? (
                            gameUserHistory.map((game, index) => (
                                <LogsCard key={index} Logs={game} />
                            ))
                        ) : (
                            <NoDataView
                                title={t('common:no_history')}
                                subtitle={t('common:no_history_desc')}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

function savePreviousUser(user) {
    if (!Object.keys(user).length) return;

    const previousUser = localStorage.getItem('previous_user');
    if (!previousUser) return;

    let username = user.username;
    let profile_picture = user.profile_picture;
    let hash;

    try {
        const userInfo = JSON.parse(previousUser);
        if (!userInfo.hash) localStorage.removeItem('previous_user');
        hash = userInfo.hash;
    } catch (error) {
        hash = previousUser;
    }

    const data = {
        username: username,
        user_pic: profile_picture,
        hash: hash
    };

    localStorage.setItem('previous_user', JSON.stringify(data));
}

export default Dashboard;
