import '../../App.css';
import React, { useContext, useEffect, useState } from 'react';
import styles from './Settings.module.css';
import SideBar from './components/SideBarSettings/SideBarSettings';
import ProfilePreview from './components/ProfilePreview/ProfilePreview';
import UserProfile from './components/UserProfile/UserProfile';
import GeneralSettings from './components/GenaralSettings/GeneralSettings';
import UserContext from '../../context/UserContext';

function Settings() {
    const { activeTabSettings } = useContext(UserContext);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.settings_container}>
            <div
                className={`${styles.settings} ${(activeTabSettings === 0 || activeTabSettings === 1) && styles.open_active_area}`}>
                <SideBar />
                {(activeTabSettings === 0 ||
                    (activeTabSettings === 2 && windowWidth >= 998)) && <UserProfile />}
                {activeTabSettings === 1 && <GeneralSettings />}
                <ProfilePreview />
            </div>
        </div>
    );
}

export default Settings;
