import '../../../../App.css';
import React, { useState, useContext } from 'react';
import styles from './UserProfile.module.css';
import settingsStyle from '../../Settings.module.css';
import TabLayout from '../../../../components/TabLayout/TabLayout';
import ProfileForm from './components/ProfileForm/ProfileForm';
import SecurityForm from './components/SecurityForm/SecurityForm';
import SettingPicProfile from './components/SettingPicProfile/SettingPicProfile';
import UserContext from '../../../../context/UserContext';
import { useTranslation } from 'react-i18next';

function UserProfile() {
    const { t } = useTranslation(['common', 'settings']);
    const [activeTab, setActiveTab] = useState(1);
    const [coverImageIndex, setCoverImageIndex] = useState(0);
    const { setActiveTabSettings } = useContext(UserContext);

    return (
        <div className={styles.user_profile_root_container}>
            <div className={styles.title_container}>
                <button
                    className={styles.back_icon}
                    onClick={() => setActiveTabSettings(2)}>
                    <img src='/assets/icons/ic_back.svg' alt='Back' />
                </button>
                <h1 className={styles.page_title}>
                    {t('settings:account_settings_title')}
                </h1>
            </div>

            <SettingPicProfile
                width={180}
                height={180}
                backgroundHeight={280}
                caller={'UserProfile'}
                coverImageIndex={setCoverImageIndex}
            />
            <div className={styles.tablayout}>
                <TabLayout
                    firstTab={t('profile')}
                    secondTab={t('security')}
                    ClassName={'mb-20'}
                    selectedTab={activeTab}
                    setSelectedTab={setActiveTab}
                />
            </div>
            <div className={styles.tabs_content_container}>
                <div
                    className={
                        activeTab === 1 ? settingsStyle.active : settingsStyle.inActive
                    }>
                    <ProfileForm coverImageIndex={coverImageIndex} />
                </div>
                <div
                    className={
                        activeTab === 2 ? settingsStyle.active : settingsStyle.inActive
                    }>
                    <SecurityForm />
                </div>
            </div>
        </div>
    );
}

export default UserProfile;
