import '../../../../App.css';
import React, { useContext } from 'react';
import styles from './SideBarSettings.module.css';
import Skeleton from 'react-loading-skeleton';
import UserContext from '../../../../context/UserContext';
import Imagify from '../../../../components/Imagify/Imagify';
import { useTranslation } from 'react-i18next';

function SideBar() {
    const { t } = useTranslation(['settings']);
    const { user, activeTabSettings, setActiveTabSettings } = useContext(UserContext);

    return (
        <div
            className={`${styles.SideBar} ${(activeTabSettings === 1 || activeTabSettings === 0) && styles.hidden}`}>
            <h1 className={styles.SideBarTitle}>{t('settings:title')}</h1>

            <div className={styles.settingsContainer}>
                <div
                    className={styles.settingItem}
                    onClick={() => setActiveTabSettings(0)}>
                    {user ? (
                        <Imagify
                            className={styles.settingItemImg}
                            src={`${user.profile_picture}`}
                            alt='Profile'
                        />
                    ) : (
                        <Skeleton className={styles.settingItemImg} circle={true} />
                    )}
                    <div className={styles.settingItemContent}>
                        <h1>
                            {(user && user.full_name) || (
                                <Skeleton width={160} height={18} />
                            )}
                        </h1>
                        <p>{t('settings:account_settings_title')}</p>
                    </div>
                </div>
                <div className={styles.hz} />
                <div
                    className={styles.settingItem}
                    onClick={() => setActiveTabSettings(1)}>
                    <Imagify
                        className={styles.settingItemImg}
                        src='/assets/icons/ic_setting_general.svg'
                        alt='General Setting'
                    />
                    <div className={styles.settingItemContent}>
                        <h1>{t('settings:general_settings_title')}</h1>
                        <p>{t('settings:general_settings_desc')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SideBar;
