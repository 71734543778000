import React, { useContext, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import ScreenLoading from '../ScreenLoading/ScreenLoading';

const ProtectedRoute = ({ children }) => {
    const { isLoggedIn } = useContext(UserContext);

    if (isLoggedIn) {
        return <Suspense fallback={<ScreenLoading />}>{children}</Suspense>;
    } else {
        return <Navigate to='/auth' />;
    }
};

export default ProtectedRoute;
