import '../../App.css';
import classes from './NoDataView.module.css';
import Imagify from '../Imagify/Imagify';
import React from 'react';

export default function NoDataView({ title, subtitle }) {
    return (
        <div className={classes.no_data_view}>
            <Imagify alt='No friends' src='/assets/images/sad.png' />
            <h1>{title}</h1>
            <p>{subtitle}</p>
        </div>
    );
}
