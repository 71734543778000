import '../../App.css';
import React, { useState } from 'react';
import classes from './Tournaments.module.css';
import TournamentSelection from './components/TournamentSelection/TournamentSelection';
import Ranking from './components/Ranking/Ranking';
import TournamentRoom from './components/TournamentRoom/TournamentRoom';
import HostTournament from './components/HostTournament/HostTournament';

function Tournaments() {
    const [HostTournamentOpen, setHostTournamentOpen] = useState(false);
    const [TournamentRoomOpen, setTournamentRoomOpen] = useState(-1);

    return (
        <div className={classes.Tournaments}>
            <Ranking />

            {TournamentRoomOpen !== -1 ? (
                <TournamentRoom
                    Room={TournamentRoomOpen}
                    setRoom={setTournamentRoomOpen}
                />
            ) : (
                <TournamentSelection
                    setRoom={setTournamentRoomOpen}
                    Host={HostTournamentOpen}
                    setHost={setHostTournamentOpen}
                />
            )}
            {HostTournamentOpen && (
                <HostTournament
                    Host={HostTournamentOpen}
                    setHost={setHostTournamentOpen}
                />
            )}
        </div>
    );
}

export default Tournaments;
