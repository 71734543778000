import '../../App.css';
import classes from './ScreenLoading.module.css';
import { useTranslation } from 'react-i18next';
import React from 'react';

export default function ScreenLoading() {
    const { t } = useTranslation(['common']);

    return (
        <div className={classes.loading_view}>
            <div className={classes.logo_progress_holder}>
                <div className={classes.logo_holder}>
                    <img alt='Logo' src='/assets/images/main_logo.svg' />
                    <h1>{t('common:app_name')}</h1>
                </div>

                <div className={classes.progress_bar}></div>
            </div>
        </div>
    );
}
