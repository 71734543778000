import '../../../../App.css';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../api';
import UserContext from '../../../../context/UserContext';
import Imagify from '../../../../components/Imagify/Imagify';
import authFormStyles from './AuthForm.module.css';
import commonStyles from '../common.module.css';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { authenticate42IntraUser, navigateTo42IntraLogin } from '../../Functions/42Auth';

const styles = { ...authFormStyles, ...commonStyles };

let activeToastId = null;

function AuthForm({ authStage, setAuthStage, userCredentials, setUserCredentials }) {
    const { t } = useTranslation(['authentication', 'common']);
    const intraCode = new URLSearchParams(window.location.search).get('code');
    const [showPassword, setShowPassword] = useState(false);
    const { username, password } = userCredentials;

    const { setIsLoggedIn } = useContext(UserContext);

    const handleChange = e => {
        const { name, value } = e.target;
        setUserCredentials({ ...userCredentials, [name]: value });
    };

    useEffect(() => {
        loadPreviousUser(t);
        if (intraCode) {
            handleLogin(false);
        }
    }, []);

    async function handleLogin(isForm) {
        if (isForm)
            authenticateUser(t, userCredentials, setIsLoggedIn, setAuthStage);
        else
            authenticate42IntraUser(
                t,
                intraCode,
                setIsLoggedIn,
                setAuthStage,
                setUserCredentials
            );
    }

    return (
        <div
            className={`${styles.auth_root_container} ${authStage === 0 ? styles.active : styles.inActive}`}>
            <div className={styles.header_container}>
                <div className={styles.logo_container}>
                    <img alt='Logo' src='/assets/images/main_logo.svg' />
                    <h1>{t('common:app_name')}</h1>
                </div>
                <h2 className='fs-2rem clr-white'>{t('authentication:login_title')}</h2>
                <h3 className='fs-1d2rem clr-white'>{t('authentication:login_desc')}</h3>
            </div>

            <form
                className={styles.auth_form}
                onSubmit={e => {
                    e.preventDefault();
                    handleLogin(true);
                }}>
                <div className={styles.auth_form_content}>
                    <div className={styles.input_layout_container}>
                        <label>{t('common:username')}</label>
                        <div>
                            <img alt='Username' src='/assets/icons/ic_email.svg' />
                            <input
                                required
                                placeholder={t('authentication:enter_usr')}
                                name='username'
                                value={username}
                                maxLength={20}
                                minLength={3}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className={styles.input_layout_container}>
                        <label>{t('common:password')}</label>

                        <div>
                            <img alt='Password' src='/assets/icons/ic_password.svg' />

                            <input
                                required
                                name='password'
                                placeholder={t('authentication:enter_pwd')}
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                maxLength={20}
                                minLength={6}
                                onChange={handleChange}
                            />

                            <img
                                style={{ cursor: 'pointer' }}
                                alt='Show Hide password'
                                src={
                                    showPassword
                                        ? '/assets/icons/ic_pass_off.svg'
                                        : '/assets/icons/ic_pass_on.svg'
                                }
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                    </div>

                    <button type='submit' className={styles.submit_btn}>
                        <h2>{t('authentication:signin')}</h2>
                    </button>
                </div>

                <div className={styles.or_divider}>
                    <div className={styles.divider_line}></div>
                    <h2>{t('common:or')}</h2>
                    <div className={styles.divider_line}></div>
                </div>

                <div className={styles.oauth_container}>
                    <div
                        onClick={() => authenticatePreviousUser(t, setIsLoggedIn)}
                        id='prev_usr_btn'
                        className={styles.login_prev_usr_btn}>
                        <div className={styles.login_prev_clear_usr_cntr}>
                            <img
                                src='/assets/icons/ic_cancel.svg'
                                alt='Remove user'
                                onClick={removePreviousUser}
                            />
                            <Imagify id='prev_usr_img' alt='User' />
                        </div>
                        <h1 id='prev_usr_name'>{t('authentication:login_with_42')}</h1>
                    </div>
                    <div
                        onClick={navigateTo42IntraLogin}
                        className={styles.login_intra42_btn}>
                        <img alt='42 Logo' src='/assets/icons/ic_42.svg' />
                        <h2>{t('authentication:login_with_42')}</h2>
                    </div>
                </div>

                <div className={styles.swap_auth_method_btn}>
                    <h2 onClick={() => setAuthStage(1)}>
                        {t('authentication:signup_switch')}
                    </h2>
                </div>
            </form>
        </div>
    );
}

async function authenticatePreviousUser(t, setIsLoggedIn) {
    if (activeToastId && toast.isActive(activeToastId)) return;

    const previousUserData = localStorage.getItem('previous_user');
    if (!previousUserData) {
        return;
    }

    const previousUser = JSON.parse(previousUserData);

    activeToastId = toast(t('authentication:info_logging_in'), {
        autoClose: false,
        isLoading: true,
        draggable: true,
        position: 'top-center'
    });

    try {
        const response = await api.post(
            process.env.REACT_APP_API_BASE_URL + '/auth/previous_user_login/',
            { hash: previousUser.hash }
        );

        if (response.status === 200) {
            if (response.data.previous_user) {
                localStorage.setItem('previous_user', response.data.previous_user);
            }
            toast.dismiss();
            setTimeout(() => setIsLoggedIn(true), 500);
            return;
        }
    } catch (error) {
        let errorMsg = error.response.data.message;

        if (error.response.status === 401) {
            localStorage.removeItem('previous_user');
            errorMsg = t('authentication:info_invalid');
            const prev_usr_btn = document.getElementById('prev_usr_btn');
            prev_usr_btn.style.display = 'none';
        }
        if (!errorMsg) {
            errorMsg = error.message;
        }
        toast.update(activeToastId, {
            render: errorMsg,
            type: 'error',
            isLoading: false,
            autoClose: 3000
        });
    }
}

function removePreviousUser() {
    localStorage.removeItem('previous_user');
    const prev_usr_btn = document.getElementById('prev_usr_btn');
    prev_usr_btn.style.display = 'none';
}

async function loadPreviousUser(t) {
    const prev_usr_img = document.getElementById('prev_usr_img');
    const prev_usr_name = document.getElementById('prev_usr_name');
    const prev_usr_btn = document.getElementById('prev_usr_btn');
    let previousUser = localStorage.getItem('previous_user');

    try {
        previousUser = JSON.parse(previousUser);
        if (previousUser.username && previousUser.user_pic && previousUser.hash) {
            prev_usr_img.src = previousUser.user_pic;
            prev_usr_name.innerText = t('authentication:login_continue_as', {
                name: previousUser.username
            });
            return;
        }
        throw new Error('Previous user data is corrupted');
    } catch (error) {
        prev_usr_btn.style.display = 'none';
        localStorage.removeItem('previous_user');
    }
}

async function authenticateUser(
    t,
    userCredentials,
    setIsLoggedIn,
    setAuthStage
) {
    if (toast.isActive(activeToastId)) return;

    activeToastId = toast(t('authentication:info_logging_in'), {
        autoClose: false,
        isLoading: true,
        draggable: true,
        position: 'top-center'
    });

    try {
        const response = await api.post('/auth/login/', {
            username: userCredentials.username,
            password: userCredentials.password
        });
        if (response.status === 200) {
            if (response.data.previous_user) {
                localStorage.setItem('previous_user', response.data.previous_user);
            }
            toast.dismiss(activeToastId);
            setTimeout(() => setIsLoggedIn(true), 500);
        }
    } catch (error) {
        if (error.response.status === 403) {
            toast.dismiss(activeToastId);
            setAuthStage(2);
            return;
        }
        toast.update(activeToastId, {
            render: t('authentication:info_invalid'),
            type: 'error',
            isLoading: false,
            autoClose: 3000
        });
    }
}

export default AuthForm;
