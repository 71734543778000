import '../../../../App.css';
import React, { useState, useEffect, useContext } from 'react';
import classes from './ContactProfile.module.css';
import TabLayout from '../../../../components/TabLayout/TabLayout';
import FriendCardInfo from '../../../../components/FriendCardInfo/FriendCardInfo';
import Imagify from '../../../../components/Imagify/Imagify';
import UserContext from '../../../../context/UserContext';
import {
    removeFriend,
    sendFriendRequest
} from '../../../Dashboard/Components/LevelContainer/LevelContainer';
import NoDataView from '../../../../components/NoDataView/NoDataView';
import api from '../../../../api';
import Skeleton from 'react-loading-skeleton';
import ChatSocketContext from '../../../../context/ChatSocketContext';
import LogsCard from '../../../../components/LogsCard/LogsCard';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

function ContactProfile() {
    const [activeTab, setActiveTab] = useState(1);
    const [isChallenge, setIsChallenge] = useState(false);
    const [friendsInfo, setFriendsInfo] = useState([]);
    const { t } = useTranslation(['common', 'messages']);
    const {
        user,
        userData,
        friendsList,
        setPlayingWith,
        playingWith,
        setBlockedStatus,
        blockedStatus,
        gameUserHistory,
        fetchUserGameHistory,
        setSelectedGame,
        setIsOnlineGame,
        setSelectedGameConnectivity
    } = useContext(UserContext);
    const { isProfile, setIsProfile, emit } = useContext(ChatSocketContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (activeTab !== 1) {
            const fetchFriendsInfo = async () => {
                try {
                    await api
                        .get(`/users/friends-list/?username=${userData.username}`)
                        .then(response => {
                            setFriendsInfo(response.data.friends);
                        });
                } catch (error) {
                    console.error('fetchFriendsInfo -> error', error);
                }
            };

            fetchFriendsInfo();
        }
    }, [activeTab]);

    useEffect(() => {
        setBlockedStatus(user?.blocked_users.includes(userData?.username));
        if (userData) fetchUserGameHistory(userData.id);
    }, [user, userData]);

    function handleGameRequest(game) {
        if (playingWith === null) {
            const friendInfo = friendsList.find(
                friend => friend.username === userData.username
            );
            setPlayingWith(friendInfo);
        }
        setSelectedGame(game);
        setSelectedGameConnectivity('online');
        setIsOnlineGame(true);
        navigate('/play');
    }

    const handleBlock = async () => {
        try {
            if (blockedStatus) {
                await api.post('/users/unblock-user/', { username: userData.username });
            } else {
                await api.post('/users/block-user/', { username: userData.username });
            }
        } catch (error) {
            console.error('handleBlock -> error', error);
        }
    };
    const { chattingWithQuery } = useParams();
    const isFriend = friendsList.find(friend => friend.username === chattingWithQuery);

    return (
        <div
            className={isProfile ? classes.active_profile : classes.contact_profile_root}>
            <div className={classes.contact_profile_card}>
                {isProfile && (
                    <Imagify
                        onClick={() => {
                            setIsProfile(false);
                        }}
                        src='/assets/icons/ic_exit.svg'
                        alt='exit'
                        className={classes.exit_profile}
                    />
                )}
                <div className={classes.contact_pfp_cover}>
                    <Imagify
                        className={classes.contact_cover}
                        src={
                            userData &&
                            t('resources:cover_images')[userData.cover_picture_index]
                        }
                        alt='Avatar'
                    />
                    <Imagify
                        className={classes.contact_pfp}
                        alt='profile'
                        src={userData && userData.profile_picture}
                    />
                </div>

                <div className={classes.contact_profile_name_status}>
                    {userData ? (
                        <h1>{`${userData?.first_name} ${userData?.last_name}`}</h1>
                    ) : (
                        <Skeleton width={150} />
                    )}
                </div>

                <div className={classes.actions__holder}>
                    <div
                        className={`${classes.profile_actions__holder} ${!isChallenge ? `${classes.show_actions}` : `${classes.hide_actions}`}`}>
                        <div className={classes.contact_profile_actions}>
                            {!isFriend ? (
                                <div
                                    className={classes.contact_profile_action_add_friend}
                                    onClick={e => {
                                        e.preventDefault();
                                        sendFriendRequest(userData && userData.username);
                                    }}>
                                    <Imagify
                                        src='/assets/icons/ic_add_friend.svg'
                                        alt='Send request'
                                    />
                                    <p>{t('messages:user_send_request')}</p>
                                </div>
                            ) : (
                                <div
                                    className={
                                        classes.contact_profile_action_remove_friend
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        removeFriend(
                                            userData && userData.username,
                                            emit,
                                            user
                                        );
                                    }}>
                                    <Imagify
                                        src='/assets/icons/ic_remove_friend.svg'
                                        alt='Remove friend'
                                    />
                                    <p>{t('messages:user_remove_friend')}</p>
                                </div>
                            )}

                            <div className={classes.separator} />

                            <div
                                className={classes.contact_profile_action_challenge}
                                onClick={() => setIsChallenge(true)}>
                                <Imagify
                                    src='/assets/icons/ic_challenge.svg'
                                    alt='Challenge'
                                />
                                <p>{t('messages:user_challenge')}</p>
                            </div>

                            <div className={classes.separator} />
                            <div
                                className={classes.contact_profile_action_block}
                                onClick={handleBlock}>
                                {blockedStatus ? (
                                    <>
                                        <Imagify
                                            src='/assets/icons/ic_remove_friend.svg'
                                            alt='unBlock'
                                        />
                                        <p>{t('messages:user_unblock')}</p>
                                    </>
                                ) : (
                                    <>
                                        <Imagify
                                            src='/assets/icons/ic_remove_friend_blocked.svg'
                                            alt='Block'
                                            style={{ fill: '#FE4343' }}
                                        />
                                        <p style={{ color: '#FE4343' }}>
                                            {t('messages:user_block')}
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className={classes.contact_profile_games}>
                            <div
                                className={classes.contact_profile_games__back}
                                onClick={() => setIsChallenge(false)}>
                                <img src='/assets/icons/ic_back.svg' alt='Back' />
                                <p>{t('common:back')}</p>
                            </div>
                            <div className={classes.contact_profile_games__available}>
                                <div
                                    className={classes.contact_profile_games__ping_pong}
                                    onClick={e => {
                                        e.preventDefault();
                                        handleGameRequest('PingPong');
                                    }}>
                                    <img
                                        src='/assets/icons/ic_pingpong.svg'
                                        alt='Ping Pong'
                                    />
                                    <p>{t('common:pingpong')}</p>
                                </div>
                                <div className={classes.separator} />
                                <div
                                    onClick={e => {
                                        e.preventDefault();
                                        handleGameRequest('TicTacToe');
                                    }}
                                    className={classes.contact_profile_games_tic_tac_toe}>
                                    <img
                                        src='/assets/icons/ic_tictactoe.svg'
                                        alt='Tic Tac Toe'
                                    />
                                    <p>{t('common:tictactoe')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <TabLayout
                    firstTab={t('common:history')}
                    secondTab={t('common:friends')}
                    ClassName={'mb-10'}
                    selectedTab={activeTab}
                    setSelectedTab={setActiveTab}
                />

                <div className={classes.contact_matches_history_card}>
                    {activeTab === 1 ? (
                        <div
                            className={`${classes.historyContainer} background_secondary`}>
                            {gameUserHistory.length > 0 ? (
                                gameUserHistory.map((game, index) => (
                                    <LogsCard key={index} Logs={game} />
                                ))
                            ) : (
                                <NoDataView
                                    title={t('common:no_history_for_user')}
                                    subtitle={t('common:no_history_desc_for_user')}
                                />
                            )}
                        </div>
                    ) : (
                        <ul id='ContactsList_ul' className={`${classes.contacts_ul}`}>
                            {friendsList.length > 0 ? (
                                friendsInfo.map(friendInfo => (
                                    <FriendCardInfo
                                        key={friendInfo.id}
                                        friendInfo={friendInfo}
                                        isFriendRequest={false}
                                    />
                                ))
                            ) : (
                                <NoDataView
                                    title={t('common:no_friends')}
                                    subtitle={t('common:no_friends_desc')}
                                />
                            )}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ContactProfile;
