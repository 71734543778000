import '../../../../App.css';
import React from 'react';
import classes from './NotFriend.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function NotFriend({ userData }) {
    const { t } = useTranslation(['chat']);
    const navigate = useNavigate();

    const handleClick = e => {
        e.preventDefault();
        navigate('/messages');
    };

    return (
        <div className={classes.not_found}>
            <Imagify
                className={classes.not_found_img}
                src='/assets/images/img_message.svg'
                alt='No chat'
            />
            <h1>{t('chat:chat_locked_friend_title', { name: userData?.first_name })}</h1>
            <p>{t('chat:chat_locked_friend_subtitle')}</p>
            <button onClick={handleClick}>
                <img
                    className={classes.back_arrow}
                    src='/assets/icons/ic_expand_arrow.svg'
                    alt='arrow'
                />{' '}
                <p>{t('chat:go_back')}</p>
            </button>
        </div>
    );
}

export default NotFriend;
