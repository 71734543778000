import '../../../../App.css';
import React, { useRef, useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChatSocketContext from '../../../../context/ChatSocketContext';
import Imagify from '../../../../components/Imagify/Imagify';
import UserContext from '../../../../context/UserContext';
import timeAnimation from './Animations/anim_message_sending.lottie';
import typingAnimation from './Animations/anim_typing.lottie';
import classes from './ChatMessages.module.css';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

function ChatMessages() {
    const navigate = useNavigate();
    const {
        user,
        userData,
        friendsList,
        setIsUserBlocked,
        isUserBlocked,
        blockedStatus
    } = useContext(UserContext);
    const {
        emit,
        messages,
        readOnly,
        setReadOnly,
        setNewMessage,
        newMessage,
        isProfile,
        setIsProfile,
        conversationLoading,
        setMessageLoading,
        messageLoading,
        isTyping,
        setIsTyping,
        loadButton,
        getMessages
    } = useContext(ChatSocketContext);
    const { chattingWithQuery } = useParams();
    const isFriend = friendsList.find(friend => friend.username === chattingWithQuery);
    const [sentMessage, setSentMessage] = useState('');
    const [isMeTyping, setIsMeTyping] = useState(false);
    const typingTimeoutRef = useRef(null);
    const { t } = useTranslation(['chat', 'messages', 'common']);

    useEffect(() => {
        if (isFriend) {
            setReadOnly(false);
        }
    }, [isFriend]);

    useEffect(() => {
        setIsTyping(false);
    }, [chattingWithQuery]);

    useEffect(() => {
        setIsUserBlocked(
            user?.blocked_users.includes(userData?.username) ||
                userData?.blocked_users.includes(user?.username)
        );
    }, [user, userData]);

    const handleLoadMore = () => {
        getMessages(isFriend, chattingWithQuery);
    };

    const handleChange = e => {
        setNewMessage(e.target.value);
        handleUserTyping();
    };

    const handleUserTyping = () => {
        if (!isMeTyping) {
            setIsMeTyping(true);
        } else {
            clearTimeout(typingTimeoutRef.current);
        }
        typingTimeoutRef.current = setTimeout(() => {
            setIsMeTyping(false);
        }, 1000);
    };

    useEffect(() => {
        emit('typing', {
            sender: user.username,
            receiver: chattingWithQuery,
            typing: isMeTyping
        });
    }, [isMeTyping]);

    const handleClick = e => {
        e.preventDefault();
        if (newMessage.length === 0 || newMessage.trim() === '' || !isFriend) return;
        setMessageLoading(true);
        setReadOnly(true);
        emit('chat_message', {
            message: newMessage,
            sender: user.username,
            receiver: chattingWithQuery
        });
        emit('typing', {
            sender: user.username,
            receiver: chattingWithQuery,
            typing: false
        });
        setIsMeTyping(false);
        clearTimeout(typingTimeoutRef.current);
        setSentMessage(newMessage);
        setNewMessage('');
    };

    return (
        <div
            className={
                chattingWithQuery && !isProfile
                    ? classes.active_chat
                    : classes.chat_messages_root
            }>
            <div className={classes.chat_message_card}>
                <div className={classes.chat_incoming_user_info}>
                    {userData ? (
                        <>
                            <Imagify
                                className={classes.incoming_user_pfp}
                                src={userData.profile_picture}
                                alt='profile'
                                onClick={() => setIsProfile(true)}
                            />
                            <div className={classes.chat_incoming_user_status}>
                                <h1>{`${userData.first_name} ${userData.last_name}`}</h1>
                                <p>
                                    {friendsList.find(
                                        friend => friend.username === chattingWithQuery
                                    )?.is_online
                                        ? t('common:online')
                                        : t('messages:last_seen', {
                                              time: moment(
                                                  userData.last_activity
                                              ).fromNow()
                                          })}
                                </p>
                            </div>
                        </>
                    ) : (
                        <div className={classes.chat_incoming_user_loading}>
                            <Skeleton circle={true} width={50} height={50} />
                            <div>
                                <Skeleton width={150} />
                                <Skeleton width={100} />
                            </div>
                        </div>
                    )}

                    <div
                        className={classes.chat_close_box}
                        onClick={() => navigate('/messages')}>
                        <img src='/assets/icons/ic_cancel.svg' alt='Close' />
                    </div>
                </div>
                {isUserBlocked && (
                    <div className={classes.locked_messages_container}>
                        <Imagify
                            src='/assets/images/img_locked.svg'
                            alt='Block'
                            className={classes.locked_messages_icon}
                        />
                        <div className={classes.locked_messages_texts}>
                            <h1>
                                {blockedStatus
                                    ? t('chat:chat_user_blocked_by_you')
                                    : t('chat:chat_user_blocked_by_them')}
                            </h1>
                            <p>
                                {blockedStatus
                                    ? t('chat:chat_cant_message_by_you')
                                    : t('chat:chat_cant_message_by_them')}
                            </p>
                        </div>
                    </div>
                )}
                <div
                    className={
                        isUserBlocked
                            ? `${classes.chat_messages} ${classes.blocked}`
                            : classes.chat_messages
                    }>
                    {!conversationLoading ? (
                        <>
                            {isTyping ? (
                                <div
                                    style={{ alignItems: 'flex-start' }}
                                    className={classes.chat_message}>
                                    <div className={classes.typing_animation}>
                                        <DotLottieReact
                                            style={{
                                                height: '18px'
                                            }}
                                            src={typingAnimation}
                                            autoplay
                                            loop
                                        />
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {messageLoading && (
                                <div
                                    style={{ alignItems: 'flex-end' }}
                                    className={`${classes.chat_message} ${classes.participant}`}>
                                    <div className={classes.chat_message_text}>
                                        <p style={{ opacity: '0.3' }}>{sentMessage}</p>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                        <DotLottieReact
                                            style={{
                                                width: '15px',
                                                height: '15px',
                                                opacity: '0.3'
                                            }}
                                            src={timeAnimation}
                                            autoplay
                                            loop
                                        />
                                        <span className={classes.chat_message_time}>
                                            {t('messages:message_sending')}
                                        </span>
                                    </div>
                                </div>
                            )}
                            {messages.map((message, index) =>
                                user.username !== message.sender ? (
                                    <div
                                        style={{ alignItems: 'flex-start' }}
                                        className={classes.chat_message}
                                        key={index}>
                                        <div className={classes.chat_message_text}>
                                            <p>{message.message}</p>
                                        </div>
                                        {messages[index - 1]?.sender !==
                                        message.sender ? (
                                            <span className={classes.chat_message_time}>
                                                {moment(message.created_at).format('LT')}
                                            </span>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div
                                        style={{ alignItems: 'flex-end' }}
                                        className={`${classes.chat_message} ${classes.participant}`}
                                        key={index}>
                                        <div className={classes.chat_message_text}>
                                            <p>{message?.message}</p>
                                        </div>
                                        {messages[index - 1]?.sender !== message.sender &&
                                        !messageLoading ? (
                                            <span className={classes.chat_message_time}>
                                                {moment(message.created_at).format('LT')}
                                            </span>
                                        ) : null}
                                    </div>
                                )
                            )}
                        </>
                    ) : (
                        <>
                            <div className={classes.lds_spinner}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </>
                    )}
                    {loadButton && (
                        <button
                            className={classes.load_more_btn}
                            onClick={() => {
                                handleLoadMore();
                            }}>
                            <img
                                src='/assets/icons/ic_expand_arrow.svg'
                                alt='Load More'
                            />
                            <h1>{t('messages:load_more')}</h1>
                        </button>
                    )}
                </div>
                <div
                    className={
                        isUserBlocked
                            ? `${classes.message_input_box} ${classes.blocked}`
                            : classes.message_input_box
                    }>
                    <form onSubmit={handleClick}>
                        <input
                            className={classes.message_input_field}
                            type='text'
                            placeholder={t('messages:type_msg')}
                            name='nesMessage'
                            value={newMessage}
                            onChange={handleChange}
                            autoComplete='off'
                            autoFocus
                            maxLength={500}
                            readOnly={readOnly || isUserBlocked ? 'readonly' : ''}
                            style={
                                readOnly || isUserBlocked
                                    ? { cursor: 'not-allowed' }
                                    : { cursor: 'text' }
                            }
                        />
                    </form>
                    <img
                        className={classes.message_send_button}
                        src={
                            newMessage.length !== 0 && !readOnly
                                ? '/assets/icons/ic_send_white.svg'
                                : '/assets/icons/ic_send_gray.svg'
                        }
                        alt='Send'
                        onClick={handleClick}
                    />
                </div>
            </div>
        </div>
    );
}
export default ChatMessages;
