import '../../../../App.css';
import React, { useContext, useEffect } from 'react';
import styles from './GameOver.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import UserContext from '../../../../context/UserContext';
import GameContext from '../../../../context/GameContext';
import animationData from './Animations/winning_effect.lottie';
import GameOverButtons from './GameOverButtons/GameOverButtons';
import SocketContext from '../../../../context/SocketContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

export default function GameOver() {
    const { coverImageUrl, user, setPlayingWith } = useContext(UserContext);
    const { setGameOver, gameResult, emitGame, setOpponent } = useContext(GameContext);
    const { t } = useTranslation(['common', 'game']);
    const navigate = useNavigate();
    const { emit } = useContext(SocketContext);

    const lottieStyle = {
        position: 'absolute',
        top: '0',
        left: '0'
    };

    useEffect(() => {
        return () => {
            setGameOver(false);
            setOpponent(null);
            setPlayingWith(null);
        };
    }, []);

    return (
        <div className={styles.root_view}>
            <DotLottieReact style={lottieStyle} src={animationData} autoplay loop />

            <Imagify
                src={coverImageUrl}
                alt={coverImageUrl}
                className={styles.coverImage}
            />

            <div className={styles.winnerInfoContainer}>
                <div className={styles.winnerProfilePictureContainer}>
                    <Imagify
                        src={`${gameResult.winner === gameResult.player1.username ? gameResult.player1.profile_picture : gameResult.player2.profile_picture}`}
                        className={styles.winnerProfilePicture}
                        alt='profile_pic'
                    />
                    <img
                        src='/assets/images/winner_bg.png'
                        className={styles.throneImage}
                        alt='winner_bg'
                    />
                </div>

                <div className={styles.winnerTextContainer}>
                    <h1>
                        {gameResult.winner === gameResult.player1.username
                            ? gameResult.player1.full_name
                            : gameResult.player2.full_name}
                    </h1>
                    <h2>{t('game:match_winner')}</h2>
                </div>
            </div>

            <div className={styles.playersRecapContainer}>
                <Imagify src={gameResult.player1.profile_picture} />
                <h1 className='text-center'>{t('game:match_results')}</h1>
                <Imagify src={gameResult.player2.profile_picture} />
            </div>

            <div className={styles.matchScore}>
                <div className={styles.final_score_view}>
                    <h1>{gameResult.player1.score} </h1>
                    <p>{t('game:goals_scored')}</p>
                </div>

                <div className={styles.line}></div>

                <div className={styles.final_score_view}>
                    <h1>{gameResult.player2.score} </h1>
                    <p>{t('game:goals_scored')}</p>
                </div>
            </div>

            <div className={styles.buttonsContainer}>
                <GameOverButtons
                    Picture={'/assets/icons/ic_game_draw.svg'}
                    firstText={t('game:or_you_can')}
                    secondText={
                        gameResult.game_mode === 'Global'
                            ? t('common:give_up')
                            : t('common:quit')
                    }
                    onClick={() => {
                        setGameOver(false);
                        setPlayingWith(null);
                        navigate('/play/');
                    }}
                />
                <GameOverButtons
                    Picture={
                        gameResult.player1.username === user.username
                            ? gameResult.player2.profile_picture
                            : gameResult.player1.profile_picture
                    }
                    firstText={`${gameResult.game_mode === 'tournament' ? t('game:join_next_match') : t('common:rematch')}`}
                    secondText={
                        gameResult.player1.username === user.username
                            ? gameResult.player2.username
                            : gameResult.player1.username
                    }
                    onClick={() => {
                        if (gameResult.game_mode === 'local') {
                            setGameOver(false);
                            navigate(
                                gameResult.game_type === 'ping_pong'
                                    ? '/play/ping-pong-local'
                                    : '/play/tic-tac-toe-local'
                            );
                            return;
                        }
                        emit('send_game_request', {
                            game_type: 'PingPong',
                            room_name:
                                'ping_pong_' +
                                user.username +
                                '_' +
                                (gameResult.player1.username === user.username
                                    ? gameResult.player2.username
                                    : gameResult.player1.username),
                            receiver:
                                gameResult.player1.username === user.username
                                    ? gameResult.player2.username
                                    : gameResult.player1.username
                        });
                        emitGame('private_ping_pong_game', {
                            game: 'ping_pong',
                            room_name:
                                'ping_pong_' +
                                user.username +
                                '_' +
                                (gameResult.player1.username === user.username
                                    ? gameResult.player2.username
                                    : gameResult.player1.username),
                            receiver:
                                gameResult.player1.username === user.username
                                    ? gameResult.player2.username
                                    : gameResult.player1.username
                        });
                        setGameOver(false);
                        navigate('/play/ping-pong-online');
                    }}
                />
            </div>
        </div>
    );
}
