import '../../../../App.css';
import React, { useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Dropdown.module.css';
import Notifications from '../Notifications/Notifications';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import UserContext from '../../../../context/UserContext';
import Imagify from '../../../../components/Imagify/Imagify';
import Skeleton from 'react-loading-skeleton';
import SocketContext from '../../../../context/SocketContext';

function Dropdown({
    notifications,
    setNotifications,
    showDropDownMenu,
    setShowDropDownMenu
}) {
    const { user } = useContext(UserContext);
    const wrapperRef = useRef(null);
    const { t } = useTranslation(['common']);
    const { notificationsArray } = useContext(SocketContext);

    useEffect(() => {
        function clickOutsideDropdown(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowDropDownMenu(false);
                setNotifications(false);
            }
        }
        document.addEventListener('mousedown', clickOutsideDropdown);
    }, [setNotifications, setShowDropDownMenu, wrapperRef]);

    const filteredNotifications = notificationsArray.filter(notification => notification.notify_type !== 'Pending');

    return (
        <div ref={wrapperRef} className={classes.dropdown}>
            <div
                className={classes.dropdown__content}
                onClick={() => {
                    notifications
                        ? setShowDropDownMenu(false)
                        : setShowDropDownMenu(!showDropDownMenu);
                    setNotifications(false);
                }}>
                <div className={classes.dropdown__content__image__holder}>
                    {user ? (
                        <Imagify
                            alt='Avatar'
                            src={user && user.profile_picture}
                            className={classes.dropdown__content__image}
                        />
                    ) : (
                        <Skeleton
                            className={classes.dropdown__content__image}
                            circle={true}
                            width={50}
                            height={50}
                        />
                    )}
                    <div className={classes.dropdown__content__status}></div>
                </div>
                <div className={classes.dropdown__content__text}>
                    {user ? (
                        <h5 className={classes.dropdown__content__title}>
                            {user && user.first_name}
                        </h5>
                    ) : (
                        <Skeleton
                            className={classes.dropdown__content__title}
                            width={80}
                        />
                    )}

                    {user ? (
                        filteredNotifications.length > 0 ? (
                            <div className={classes.dropdown__new_notifs_holder}>
                                <p className={classes.dropdown__content__subtitle}>
                                    {t('new_notifications', {
                                        count: filteredNotifications.length
                                    })}
                                </p>
                                <div className={classes.dropdown__notification_dot}></div>
                            </div>
                        ) : (
                            <p className={classes.dropdown__content__subtitle}>
                                {t('curr_level', { level: user.level })}
                            </p>
                        )
                    ) : (
                        <Skeleton
                            className={classes.dropdown__content__subtitle}
                            width={60}
                        />
                    )}
                </div>
                <div className={classes.dropdown__content__arrow__holder}>
                    <img
                        className={`${classes.dropdown__content__arrow} ${showDropDownMenu ? `${classes.showDropDown}` : ''}`}
                        src='/assets/icons/ic_expand_arrow.svg'
                        alt='arrow'
                    />
                </div>
            </div>

            <DropdownMenu
                showDropDownMenu={showDropDownMenu}
                setShowDropDownMenu={setShowDropDownMenu}
                notifications={notifications}
                setNotifications={setNotifications}
            />
            <Notifications notifications={notifications} />
        </div>
    );
}

export default Dropdown;
