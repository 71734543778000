import React from 'react';
import classes from './BalanceProgress.module.css';
import CircularProgressBar from '../../../../components/CircularProgressBar/CircularProgressBar';

export default function BalanceProgress({ user, t }) {
    return (
        <div className={classes.user_bp_stats}>
            <div className={classes.user_stat_bp_ctnr}>
                <div>
                    <h1>{t('ping_exp')}</h1>
                    <div className={classes.text_container}>
                        <h1>{user && user.game_pingpong_exp}</h1>
                        <p>xp</p>
                    </div>
                </div>

                <div>
                    <h1>{t('tic_exp')}</h1>
                    <div className={classes.text_container}>
                        <h1>{user && user.game_tictactoe_exp}</h1>
                        <p>xp</p>
                    </div>
                </div>
            </div>
            <div className={classes.user_level_progress_ctnr}>
                <CircularProgressBar percentage={user && user.progress_percentage} />
            </div>
        </div>
    );
}
