import React, { useContext } from 'react';
import '../../../../App.css';
import classes from './DropdownMenu.module.css';
import { useNavigate } from 'react-router-dom';
import api from '../../../../api';
import UserContext from '../../../../context/UserContext';
import { useTranslation } from 'react-i18next';

function DropdownMenu({ showDropDownMenu, setShowDropDownMenu, setNotifications }) {
    const navigate = useNavigate();
    const { t } = useTranslation(['common']);
    const { setIsLoggedIn } = useContext(UserContext);

    return (
        <div
            className={`${classes.dropdown__menu} ${showDropDownMenu ? `${classes.active}` : ''}`}>
            <ul className={classes.dropdown__menu__holder}>
                <li
                    className={classes.dropdown__menu__item}
                    onClick={() => {
                        setNotifications(true);
                        setShowDropDownMenu(false);
                    }}>
                    <img
                        className={classes.dropdown__menu__item__icon}
                        src='/assets/icons/ic_notification.svg'
                        alt='profile'
                    />
                    <span>{t('common:notifications')}</span>
                </li>

                <li
                    className={classes.dropdown__menu__item}
                    onClick={() => {
                        setShowDropDownMenu(false);
                        navigate('/settings');
                    }}>
                    <img
                        className={classes.dropdown__menu__item__icon}
                        src='/assets/icons/ic_settings.svg'
                        alt='settings'
                    />
                    <span>{t('common:settings')}</span>
                </li>

                <li
                    className={classes.dropdown__menu__item}
                    onClick={() => {
                        setShowDropDownMenu(false);
                        initLogout(setIsLoggedIn);
                    }}>
                    <img
                        className={classes.dropdown__menu__item__icon}
                        src='/assets/icons/ic_logout.svg'
                        alt='logout'
                    />
                    <span>{t('common:logout')}</span>
                </li>
            </ul>
        </div>
    );
}

function initLogout(setIsLoggedIn) {
    api.get('/auth/logout/')
        .then(() => {
            setIsLoggedIn(false);
        })
        .catch(() => {
        });
}

export default DropdownMenu;
