import React, { useState, useEffect, useRef, useContext } from 'react';
import GameContext from '../../../../context/GameContext';
import UserContext from '../../../../context/UserContext';
import styles from '../PingPong/PingPong.module.css';
import Waiting from './Waiting/Waiting';
import { useNavigate } from 'react-router-dom';

export default function PingPong({ setComScore, setUserScore }) {
    const navigate = useNavigate();

    const canvasRef = useRef(null);
    const ballRef = useRef(null);
    const myPaddleRef = useRef(null);
    const userPaddleRef = useRef(null);
    const keys = useRef({});
    const [StartGame, setStartGame] = useState(false);
    const {
        gameSocket,
        emitGame,
        GameListener,
        setGameOver,
        Opponent,
        setGameResult,
        setRequestGame,
        readyToStart,
        setReadyToStart,
        ConnectionLost,
        setConnectionLost
    } = useContext(GameContext);
    const { user } = useContext(UserContext);
    const paddle = useRef(null);

    const handleGameUpdate = data => {
        if (StartGame === false) setStartGame(true);
        if (!ballRef.current) return;
        ballRef.current.x = data.data.ball_position.x;
        ballRef.current.y = data.data.ball_position.y;

        if (data.data.paddle1 === user.username) {
            userPaddleRef.current.y = data.data.paddle2_position.y;
            myPaddleRef.current.y = data.data.paddle1_position.y;
            myPaddleRef.current.color = '#34c07d';
            userPaddleRef.current.color = '#f95a5a';
            paddle.current = 1;

            if (data.data.paddle1_score !== myPaddleRef.current.score)
                setUserScore(data.data.paddle1_score);
            if (data.data.paddle2_score !== userPaddleRef.current.score)
                setComScore(data.data.paddle2_score);
            myPaddleRef.current.score = data.data.paddle1_score;
            userPaddleRef.current.score = data.data.paddle2;

        } else {
            myPaddleRef.current.y = data.data.paddle1_position.y;
            userPaddleRef.current.y = data.data.paddle2_position.y;
            myPaddleRef.current.color = '#f95a5a';
            userPaddleRef.current.color = '#34c07d';
            paddle.current = 2;
            if (data.data.paddle1_score !== myPaddleRef.current.score)
                setComScore(data.data.paddle1_score);
            if (data.data.paddle2_score !== userPaddleRef.current.score)
                setUserScore(data.data.paddle2_score);
            myPaddleRef.current.score = data.data.paddle1_score;
            userPaddleRef.current.score = data.data.paddle2_score;
        }
    };

    const handleEndOfGame = data => {
        setGameOver(true);
        setGameResult(data.data);
        setRequestGame(false);
        navigate('/play');
    };

    const handleOpponentLostConnection = () => {
        setReadyToStart(false);
        setStartGame(false);
        setConnectionLost(true);
    };

    useEffect(() => {
        if (!gameSocket) return;
        GameListener({
            game_update: handleGameUpdate,
            end_of_game: handleEndOfGame,
            opponent_lost_connection: handleOpponentLostConnection
        });
    }, [gameSocket]);

    useEffect(() => {
        if (!StartGame) {
            return;
        }
        const canvas = canvasRef.current;
        let animationFrameId;
        const context = canvas.getContext('2d');

        canvas.width = 1080;
        canvas.height = 720;

        const KEY_UP = 'ArrowUp';
        const KEY_DOWN = 'ArrowDown';

        const handleKeyDown = e => {
            keys.current[e.key] = true;
        };

        const handleKeyUp = e => {
            keys.current[e.key] = false;
        };

        const handleTouchStart = e => {
            const touch = e.touches[0];
            if (touch.clientX < canvas.parentElement.clientWidth / 2) {
                keys.current[KEY_DOWN] = true;
            } else {
                keys.current[KEY_UP] = true;
            }
        };

        const handleTouchEnd = e => {
            const touch = e.changedTouches[0];
            if (touch.clientX < canvas.parentElement.clientWidth / 2) {
                keys.current[KEY_DOWN] = false;
            } else {
                keys.current[KEY_UP] = false;
            }
        };

        const handleContextMenu = e => {
            e.preventDefault();
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        canvas.addEventListener('touchstart', handleTouchStart);
        canvas.addEventListener('contextmenu', handleContextMenu);
        canvas.addEventListener('touchend', handleTouchEnd);

        ballRef.current = {
            x: canvas.width / 2,
            y: canvas.height / 2,
            radius: 10,
            color: '#fff',
            ballVelocityX: 5,
            ballVelocityY: 5,
            updateBall: function () {
                this.x -= this.ballVelocityX;
                this.y -= this.ballVelocityY;
            },
            drawBall: function () {
                context.fillStyle = this.color;
                context.strokeStyle = this.color;
                context.beginPath();
                context.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
                context.fill();
            }
        };

        myPaddleRef.current = {
            x: 0,
            y: canvas.height / 2 - 50,
            width: 15,
            height: 100,
            color: '#fff',
            paddleVelocity: 5,
            score: 0,
            updatePaddle: function () {
                if (keys.current[KEY_UP] && this.y > 0) {
                    emitGame('update_paddle', {
                        game: 'Ping Pong',
                        direction: -1,
                        paddle: paddle.current
                    });
                }
                if (keys.current[KEY_DOWN] && this.y < canvas.height - this.height) {
                    emitGame('update_paddle', {
                        game: 'Ping Pong',
                        direction: 1,
                        paddle: paddle.current
                    });
                }
            },
            drawPaddle: function () {
                context.beginPath();
                context.roundRect(this.x, this.y, this.width, this.height, 20);
                context.fillStyle = this.color;
                context.fill();
            }
        };

        userPaddleRef.current = {
            x: canvas.width - 15,
            y: canvas.height / 2 - 50,
            width: 15,
            height: 100,
            color: '#fff',
            paddleVelocity: 5,
            score: 0,
            updatePaddle: function () {
                if (keys.current[KEY_UP] && this.y > 0) {
                    emitGame('update_paddle', {
                        game: 'Ping Pong',
                        direction: -1,
                        paddle: paddle.current
                    });
                }
                if (keys.current[KEY_DOWN] && this.y < canvas.height - this.height) {
                    emitGame('update_paddle', {
                        game: 'Ping Pong',
                        direction: 1,
                        paddle: paddle.current
                    });
                }
            },
            drawPaddle: function () {
                context.beginPath();
                context.roundRect(this.x, this.y, this.width, this.height, 20);
                context.fillStyle = this.color;
                context.fill();
            }
        };

        function drawLine() {
            context.beginPath();
            context.setLineDash([3, 2]);
            context.moveTo(canvas.width / 2, 10);
            context.lineTo(canvas.width / 2, canvas.height / 2 - 50);
            context.strokeStyle = '#ffffff66';
            context.stroke();

            context.beginPath();
            context.moveTo(canvas.width / 2, canvas.height / 2 + 50);
            context.lineTo(canvas.width / 2, canvas.height - 10);
            context.strokeStyle = '#ffffff66';
            context.stroke();
        }

        function drawCircle() {
            context.beginPath();
            context.arc(canvas.width / 2, canvas.height / 2, 50, 0, 2 * Math.PI);
            context.stroke();
        }

        function gameUpdate() {
            if (paddle.current === 1) myPaddleRef.current.updatePaddle();
            else userPaddleRef.current.updatePaddle();
        }

        function drawBorder() {
            context.beginPath();
            context.moveTo(20, 0);
            context.lineTo(canvas.width - 20, 0);
            context.arcTo(canvas.width, 0, canvas.width, 20, 20);
            context.lineTo(canvas.width, canvas.height - 20);
            context.arcTo(
                canvas.width,
                canvas.height,
                canvas.width - 20,
                canvas.height,
                20
            );
            context.lineTo(20, canvas.height);
            context.arcTo(0, canvas.height, 0, canvas.height - 20, 20);
            context.lineTo(0, 20);
            context.arcTo(0, 0, 20, 0, 20);
            context.strokeStyle = '#fff';
            context.stroke();
        }

        function gameDraw() {
            myPaddleRef.current.drawPaddle();
            userPaddleRef.current.drawPaddle();
            ballRef.current.drawBall();
            drawLine();
            drawCircle();
            drawBorder();
        }

        function gameLoop() {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = 'transparent';
            context.fillRect(0, 0, canvas.width, canvas.height);

            animationFrameId = window.requestAnimationFrame(gameLoop);
            gameUpdate();
            gameDraw();
        }
        gameLoop();

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            canvas.removeEventListener('touchstart', handleTouchStart);
            canvas.removeEventListener('contextmenu', handleContextMenu);
            canvas.removeEventListener('touchend', handleTouchEnd);
            if (animationFrameId) window.cancelAnimationFrame(animationFrameId);
        };
    }, [gameSocket, GameListener, StartGame]);

    return (
        <div className={styles.container}>
            {StartGame ? (
                <canvas
                    ref={canvasRef}
                    className={styles.canvas}
                    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                />
            ) : (
                <Waiting
                    readyToStart={readyToStart}
                    emitGame={emitGame}
                    setStartGame={setStartGame}
                    waitingDuration={ConnectionLost ? 10 : 3}
                    Opponent={Opponent}
                    ConnectionLost={ConnectionLost}
                    GameListener={GameListener}
                />
            )}
        </div>
    );
}
