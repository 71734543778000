import '../../../../App.css';
import React, { useEffect, useState, useContext } from 'react';
import styles from './GeneralSettings.module.css';
import TabLayout from '../../../../components/TabLayout/TabLayout';
import BackgroundSelector from '../../../Game/components/GameCustomize/components/BackgroundSelector/BackgroundSelector';
import UserContext from '../../../../context/UserContext';
import { useTranslation } from 'react-i18next';

function GeneralSettings() {
    const { t } = useTranslation(['settings', 'common']);
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const { setActiveTabSettings } = useContext(UserContext);
    const {
        selectedGame,
        setSelectedGame,
        selectedGameConnectivity,
        setSelectedGameConnectivity,
        setSelectedBackground,
        selectedBackground
    } = useContext(UserContext);

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        if (i18n.language !== language) {
            localStorage.setItem('language', language);
            changeLanguage(language);
        }
    }, [language]);

    return (
        <div className={styles.general_settings_root_container}>
            <div className={styles.title_container}>
                <button
                    className={styles.back_icon}
                    onClick={() => setActiveTabSettings(2)}>
                    <img src='/assets/icons/ic_back.svg' alt='Back' />
                </button>
                <h1 className={styles.page_title}>
                    {t('settings:general_settings_title')}
                </h1>
            </div>

            <div className={styles.content_view}>
                <h1 className={styles.item_title}>{t('settings:def_game')}</h1>
                <TabLayout
                    firstTab={t('common:pingpong')}
                    secondTab={t('common:tictactoe')}
                    ClassName={styles.max_tablayout_width}
                    setSelectedTab={setSelectedGame}
                    selectedTab={selectedGame}
                    Id={'game'}
                />
            </div>

            <div className={styles.content_view}>
                <h1 className={styles.item_title}>{t('settings:def_game_connect')}</h1>
                <TabLayout
                    firstTab={t('common:online')}
                    secondTab={t('common:local')}
                    ClassName={styles.max_tablayout_width}
                    setSelectedTab={setSelectedGameConnectivity}
                    selectedTab={selectedGameConnectivity}
                    Id={'game_connectivity'}
                />
            </div>

            <div className={styles.content_view}>
                <h1 className={styles.item_title}>{t('settings:def_language')}</h1>
                <div className={styles.language_container}>
                    <div
                        style={language === 'en' ? { border: '1px solid #4441BA' } : {}}
                        onClick={() => setLanguage('en')}>
                        <h1>🇺🇸&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;English</h1>
                    </div>
                    <div
                        style={language === 'fr' ? { border: '1px solid #4441BA' } : {}}
                        onClick={() => setLanguage('fr')}>
                        <h1>🇫🇷&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Français</h1>
                    </div>
                    <div
                        style={language === 'es' ? { border: '1px solid #4441BA' } : {}}
                        onClick={() => setLanguage('es')}>
                        <h1>🇪🇸&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Español</h1>
                    </div>
                    <div
                        style={language === 'ar' ? { border: '1px solid #4441BA' } : {}}
                        onClick={() => setLanguage('ar')}>
                        <h1>🇲🇦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;العربية</h1>
                    </div>
                </div>
            </div>
            <div className={styles.content_view}>
                <h1 className={styles.item_title}>{t('settings:def_theme')}</h1>
                <BackgroundSelector
                    Selected={selectedBackground}
                    setSelected={setSelectedBackground}
                    id='general_settings'
                />
            </div>
        </div>
    );
}

export default GeneralSettings;
