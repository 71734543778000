import '../../../../App.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../../api';
import { navigateTo42IntraLogin } from '../../Functions/42Auth';
import signUpFormStyles from './SignUpForm.module.css';
import commonStyles from '../common.module.css';

const styles = { ...signUpFormStyles, ...commonStyles };

function SignUpForm({ authStage, setAuthStage, userCredentials, setUserCredentials }) {
    const { t } = useTranslation(['authentication', 'common', 'messages']);
    const [showPassword, setShowPassword] = useState(false);

    const { first_name, last_name, username, password } = userCredentials;

    const handleChange = e => {
        const { name, value } = e.target;
        setUserCredentials({ ...userCredentials, [name]: value });
    };

    const handleSignUp = async e => {
        e.preventDefault();
        const signUpSuccess = await createNewUser(t, userCredentials);
        if (signUpSuccess) {
            setAuthStage(0);
        }
    };

    return (
        <div
            className={`${styles.auth_root_container} ${authStage !== 1 ? styles.inActive : styles.active}`}>
            <div className={styles.header_container}>
                <div className={styles.logo_container}>
                    <img alt='Logo' src='/assets/images/main_logo.svg' />
                    <h1>{t('common:app_name')}</h1>
                </div>
                <h2 className='fs-2rem clr-white'>{t('authentication:sign_up_title')}</h2>
                <h3 className='fs-1d2rem clr-white'>
                    {t('authentication:sign_up_desc')}
                </h3>
            </div>

            <form className={styles.auth_form} onSubmit={handleSignUp}>
                <div className={styles.auth_form_content}>
                    <div className={styles.full_name_holder}>
                        <div className={styles.input_layout_container}>
                            <label>{t('common:first_name')}</label>
                            <div>
                                <img alt='Avatar' src='/assets/icons/ic_user.svg' />
                                <input
                                    placeholder={t('authentication:enter_fr_name')}
                                    name='first_name'
                                    value={first_name}
                                    maxLength={20}
                                    required
                                    onChange={handleChange}></input>
                            </div>
                        </div>

                        <div className={styles.input_layout_container}>
                            <label>{t('common:last_name')}</label>
                            <div>
                                <img alt='Avatar' src='/assets/icons/ic_user.svg' />
                                <input
                                    placeholder={t('authentication:enter_ls_name')}
                                    name='last_name'
                                    value={last_name}
                                    maxLength={20}
                                    required
                                    onChange={handleChange}></input>
                            </div>
                        </div>
                    </div>

                    <div className={styles.input_layout_container}>
                        <label>{t('common:username')}</label>
                        <div>
                            <img alt='Username' src='/assets/icons/ic_email.svg' />
                            <input
                                placeholder={t('authentication:enter_usr')}
                                name='username'
                                value={username}
                                maxLength={20}
                                required
                                onChange={handleChange}></input>
                        </div>
                    </div>

                    <div className={styles.input_layout_container}>
                        <label>{t('common:password')}</label>
                        <div>
                            <img alt='Password' src='/assets/icons/ic_password.svg' />

                            <input
                                id='password_input'
                                placeholder={t('authentication:enter_pwd')}
                                type={showPassword ? 'text' : 'password'}
                                name='password'
                                value={password}
                                maxLength={20}
                                required
                                onChange={handleChange}
                            />

                            <img
                                style={{ cursor: 'pointer' }}
                                alt='Show Hide password'
                                src={
                                    showPassword
                                        ? '/assets/icons/ic_pass_off.svg'
                                        : '/assets/icons/ic_pass_on.svg'
                                }
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                    </div>

                    <button type='submit' className={styles.submit_btn}>
                        <h2>{t('authentication:signup')}</h2>
                    </button>
                </div>

                <div className={styles.or_divider}>
                    <div className={styles.divider_line}></div>
                    <h2>{t('common:or')}</h2>
                    <div className={styles.divider_line}></div>
                </div>

                <div
                    onClick={navigateTo42IntraLogin}
                    className={styles.login_intra42_btn}>
                    <img alt='42 logo' src='/assets/icons/ic_42.svg' />
                    <h2>{t('authentication:signup_with_42')}</h2>
                </div>

                <div className={styles.swap_auth_method_btn}>
                    <h2 id='back_to_login' onClick={() => setAuthStage(0)}>
                        {t('authentication:login_switch')}
                    </h2>
                </div>
            </form>
        </div>
    );
}

async function createNewUser(t, userCredentials) {
    toast.dismiss();

    let errorMessage = '';
    const loadingToastId = toast(t('authentication:info_creating_account'), {
        autoClose: false,
        isLoading: true,
        draggable: true,
        position: 'top-center'
    });

    try {
        const response = await api.post('/auth/register/', userCredentials);
        if (response.status === 201) {
            toast.update(loadingToastId, {
                render: t('authentication:account_created'),
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            return true;
        }
    } catch (error) {
        if (error.response) {
            if (error.response.status === 409) {
                errorMessage = t('authentication:info_exists');
            } else if (error.response.data.error_code === 1) {
                errorMessage = t('messages:msg_error_username_number_must');
            } else if (error.response.data.error_code === 2) {
                errorMessage = t('messages:msg_error_username_len');
            } else if (error.response.data.error_code === 3) {
                errorMessage = t('messages:msg_error_pwd_len');
            } else if (error.response.data.error_code === 4) {
                errorMessage = t('messages:msg_error_first_name_len');
            } else if (error.response.data.error_code === 5) {
                errorMessage = t('messages:msg_error_last_name_len');
            } else if (error.response.data.error_code === 99) {
                errorMessage = t('messages:msg_error_regs_disabled');
            } else {
                errorMessage = t('authentication:unknown_error');
            }
        } else {
            errorMessage = error;
        }
    }

    setTimeout(() => {
        toast.update(loadingToastId, {
            render: errorMessage,
            type: 'error',
            isLoading: false,
            autoClose: 5000
        });
    }, 1000);

    return false;
}

export default SignUpForm;
