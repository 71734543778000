import React, { useEffect, useRef } from 'react';
import styles from './PingPongAuth.module.css';

export default function PingPong({ startTheGame, clickedGameboyArrow }) {
    const canvasRef = useRef(null);
    const ballRef = useRef(null);
    const myPaddleRef = useRef(null);
    const userPaddleRef = useRef(null);
    const keys = useRef({});

    useEffect(() => {
        if (clickedGameboyArrow === null) {
            keys.current = {};
        } else if (clickedGameboyArrow === 'up') {
            keys.current = { ArrowUp: true };
        } else if (clickedGameboyArrow === 'down') {
            keys.current = { ArrowDown: true };
        }
    }, [clickedGameboyArrow]);

    useEffect(() => {
        let animationId;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.width = 1080;
        canvas.height = 720;
        const KEY_UP = 'ArrowUp';
        const KEY_DOWN = 'ArrowDown';

        ballRef.current = {
            x: canvas.width / 2,
            y: canvas.height / 2,
            radius: 20,
            color: '#fff',
            ballVelocityX: 4,
            ballVelocityY: 4,
            updateBall: function () {
                if (!startTheGame) return;
                this.x -= this.ballVelocityX;
                this.y -= this.ballVelocityY;
            },
            drawBall: function () {
                context.fillStyle = this.color;
                context.strokeStyle = this.color;
                context.beginPath();
                context.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
                context.fill();
            }
        };

        function ballCollisionWithPaddle(paddle) {
            let dx = Math.abs(ballRef.current.x - paddle.getCenterX());
            let dy = Math.abs(ballRef.current.y - paddle.getCenterY());
            if (
                dx <= ballRef.current.radius + paddle.getHalfWidth() &&
                dy <= ballRef.current.radius + paddle.getHalfHeight()
            ) {
                ballRef.current.ballVelocityX *= -1;
                if (paddle.x === 0)
                    ballRef.current.x = paddle.x + paddle.width + ballRef.current.radius;
                else ballRef.current.x = paddle.x - ballRef.current.radius;

                if (
                    ballRef.current.ballVelocityY > 15 ||
                    ballRef.current.ballVelocityY < -15
                )
                    return;
                if (ballRef.current.ballVelocityY < 0) ballRef.current.ballVelocityY -= 1;
                else ballRef.current.ballVelocityY += 1;
                if (ballRef.current.ballVelocityX < 0) ballRef.current.ballVelocityX -= 1;
                else ballRef.current.ballVelocityX += 1;
            }
        }

        const handleContextMenu = e => {
            e.preventDefault();
        };

        const handleKeyDown = e => {
            keys.current[e.key] = true;
        };

        const handleKeyUp = e => {
            keys.current[e.key] = false;
        };

        const handleTouchEnd = e => {
            const touch = e.changedTouches[0];
            if (
                touch.clientX < canvas.parentElement.clientWidth / 2 &&
                touch.clientY < canvas.height / 2
            ) {
                keys.current[KEY_DOWN] = false;
            } else if (
                touch.clientX > canvas.parentElement.clientWidth / 2 &&
                touch.clientY < canvas.height / 2
            ) {
                keys.current[KEY_UP] = false;
            }
        };

        canvas.addEventListener('contextmenu', handleContextMenu);
        canvas.addEventListener('touchend', handleTouchEnd);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        const handleTouchStart = e => {
            const touch = e.touches[0];
            if (
                touch.clientX < canvas.parentElement.clientWidth / 2 &&
                touch.clientY < canvas.height / 2
            ) {
                keys.current[KEY_DOWN] = true;
            } else if (
                touch.clientX > canvas.parentElement.clientWidth / 2 &&
                touch.clientY < canvas.height / 2
            ) {
                keys.current[KEY_UP] = true;
            }
        };

        canvas.addEventListener('touchstart', handleTouchStart);

        myPaddleRef.current = {
            x: 0,
            y: canvas.height / 2 - 50,
            width: 30,
            height: 200,
            color: '#34c07d',
            paddleVelocity: 20,
            updatePaddle: function () {
                if (keys.current[KEY_UP] && this.y > 0 && this.y > 10) {
                    this.y -= this.paddleVelocity;
                }
                if (
                    keys.current[KEY_DOWN] &&
                    this.y < canvas.height - (this.height + 10)
                ) {
                    this.y += this.paddleVelocity;
                }
            },
            drawPaddle: function () {
                context.beginPath();
                context.roundRect(this.x, this.y, this.width, this.height, 20);
                context.fillStyle = this.color;
                context.fill();
            },
            getHalfWidth: function () {
                return this.width / 2;
            },
            getHalfHeight: function () {
                return this.height / 2;
            },
            getCenterX: function () {
                return this.x + this.getHalfWidth();
            },
            getCenterY: function () {
                return this.y + this.getHalfHeight();
            }
        };

        function playerAI() {
            userPaddleRef.current.y = ballRef.current.y - 50;
        }

        userPaddleRef.current = {
            x: canvas.width - 30,
            y: canvas.height / 2 - 50,
            width: 30,
            height: 200,
            color: '#f95a5a',
            paddleVelocity: 10,
            drawPaddle: function () {
                context.beginPath();
                context.roundRect(this.x, this.y, this.width, this.height, 20);
                context.fillStyle = this.color;
                context.fill();
            },
            getHalfWidth: function () {
                return this.width / 2;
            },
            getHalfHeight: function () {
                return this.height / 2;
            },
            getCenterX: function () {
                return this.x + this.getHalfWidth();
            },
            getCenterY: function () {
                return this.y + this.getHalfHeight();
            }
        };

        function increaseScore() {
            if (ballRef.current.x <= -ballRef.current.radius) {
                respawnBall();
            }
            if (ballRef.current.x >= canvas.width + ballRef.current.radius) {
                respawnBall();
            }
        }

        function ballCollisionWithTheEdges() {
            if (ballRef.current.y + ballRef.current.radius >= canvas.height) {
                ballRef.current.ballVelocityY *= -1;
            }
            if (ballRef.current.y - ballRef.current.radius <= 0) {
                ballRef.current.ballVelocityY *= -1;
            }
        }

        function respawnBall() {
            ballRef.current.x = canvas.width / 2;
            ballRef.current.y = canvas.height / 2;

            let random = Math.floor(Math.random() * 4) + 3;
            if (ballRef.current.ballVelocityY < 0) {
                ballRef.current.ballVelocityY = -random;
                ballRef.current.ballVelocityX = -random;
            } else {
                ballRef.current.ballVelocityY = random;
                ballRef.current.ballVelocityX = random;
            }
        }
        function drawCircle() {
            context.beginPath();
            context.arc(canvas.width / 2, canvas.height / 2, 50, 0, 2 * Math.PI);
            context.stroke();
        }

        function drawLine() {
            context.beginPath();
            context.setLineDash([3, 2]);
            context.moveTo(canvas.width / 2, 10);
            context.lineTo(canvas.width / 2, canvas.height / 2 - 50);
            context.strokeStyle = '#ffffff66';
            context.stroke();

            context.beginPath();
            context.moveTo(canvas.width / 2, canvas.height / 2 + 50);
            context.lineTo(canvas.width / 2, canvas.height - 10);
            context.strokeStyle = '#ffffff66';
            context.stroke();
        }

        function gameUpdate() {
            ballRef.current.updateBall();
            myPaddleRef.current.updatePaddle();
            ballCollisionWithTheEdges();
            playerAI();
            ballCollisionWithPaddle(myPaddleRef.current);
            ballCollisionWithPaddle(userPaddleRef.current);
            increaseScore();
        }

        function gameDraw() {
            myPaddleRef.current.drawPaddle();
            userPaddleRef.current.drawPaddle();
            ballRef.current.drawBall();
            drawLine();
            drawCircle();
        }

        function gameLoop() {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = 'transparent';
            context.fillRect(0, 0, canvas.width, canvas.height);
            animationId = window.requestAnimationFrame(gameLoop);
            gameUpdate();
            gameDraw();
        }
        gameLoop();

        return () => {
            if (animationId) window.cancelAnimationFrame(animationId);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            canvas.removeEventListener('touchstart', handleTouchStart);
            canvas.removeEventListener('touchend', handleTouchEnd);
            canvas.removeEventListener('contextmenu', handleContextMenu);
        };
    }, [canvasRef, startTheGame]);

    return (
        <canvas
            ref={canvasRef}
            className={styles.canvas}
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        />
    );
}
