import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import classes from '../FriendCardInfo.module.css';

export default function TimeTracker({ timestamp }) {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const updateTimestamp = () => {
            // protect against invalid timestamps
            let distance;
            if (!timestamp) {
                setTimeAgo('');
                return;
            }
            try {

                distance = formatDistanceToNow(new Date(timestamp), { addSuffix: true });
            }
            catch (e) {
                setTimeAgo('');
                return;
            }
            setTimeAgo(distance);
        };

        updateTimestamp();

        const intervalId = setInterval(updateTimestamp, 60000);

        return () => clearInterval(intervalId);
    }, [timestamp]);

    return <p className={classes.friend_experience}>{timeAgo}</p>;
}
