import React from 'react';
import '../../../../App.css';
import classes from './PreviewGameBoy.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import { useTranslation } from 'react-i18next';

function PreviewGameBoy({ game_type, theme }) {
    const { t } = useTranslation(['common', 'game']);

    let frame_theme = {
        background: theme
    };

    let game_style = {
        width: '100%',
        height: '100%'
    };
    let game_src = '/assets/images/pingpong_preview.svg';

    if (game_type !== 'PingPong') {
        game_style = {
            width: '100%',
            height: '80%'
        };
        game_src = '/assets/icons/ic_tictactoe.svg';
    }

    return (
        <div className={classes.PreviewGameBoy_holder}>
            <div className={classes.PreviewGameBoy_frame}>
                <div className={classes.PreviewGameBoy_screen_frame} style={frame_theme}>
                    <Imagify alt='Video Game' src={game_src} style={game_style} />
                </div>

                <div className={classes.PreviewGameBoy_text_holder}>
                    {game_type === 'PingPong' ? (
                        <h1>{t('common:pingpong')}</h1>
                    ) : (
                        <h1>{t('common:tictactoe')}</h1>
                    )}
                    <h2>{t('game:click_start')}</h2>
                </div>

                <div className={classes.PreviewGameBoy_top_buttons_holder}>
                    <img alt='Navigation' src='/assets/images/gameboy_left_buttons.svg' />
                    <img
                        alt='Navigation'
                        src='/assets/images/gameboy_right_buttons.svg'
                    />
                </div>
                <div className={classes.PreviewGameBoy_bottom_buttons_holder}>
                    <img alt='Start' src='/assets/images/gameboy_bottom_buttons.svg' />
                    <img alt='Sound holes' src='/assets/images/gameboy_sound_holes.svg' />
                </div>
            </div>
        </div>
    );
}

export default PreviewGameBoy;
