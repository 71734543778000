import styles from './PingPong.module.css';
import React, { useEffect, useRef, useState, useContext } from 'react';
import BeforeGameGameBoy from '../BeforeGameGameBoy/BeforeGameGameBoy';
import GameContext from '../../../../context/GameContext';
import UserContext from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PingPong({
    setUserScore,
    setComScore,
    comScore,
    userScore,
    setHistory
}) {
    const canvasRef = useRef(null);
    const ballRef = useRef(null);
    const myPaddleRef = useRef(null);
    const userPaddleRef = useRef(null);
    const keys = useRef({});
    const [initiateGame, setInitiateGame] = useState(false);
    const { setGameOver, setGameResult } = useContext(GameContext);
    const { user } = useContext(UserContext);
    const userScoreRef = useRef(userScore);
    const comScoreRef = useRef(comScore);
    const navigate = useNavigate();
    const { t } = useTranslation(['game', 'common']);

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            setInitiateGame(true);
        }, 5000);

        return () => clearInterval(timeoutId);
    }, []);

    useEffect(() => {
        if (!initiateGame) return;
        let animationId;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.width = 1080;
        canvas.height = 720;
        const KEY_UP = 'ArrowUp';
        const KEY_DOWN = 'ArrowDown';
        const W_KEY = 'w';
        const S_KEY = 's';

        ballRef.current = {
            x: canvas.width / 2,
            y: canvas.height / 2,
            radius: 10,
            color: '#fff',
            ballVelocityX: 4,
            ballVelocityY: 4,
            updateBall: function () {
                this.x -= this.ballVelocityX;
                this.y -= this.ballVelocityY;
            },
            drawBall: function () {
                context.fillStyle = this.color;
                context.strokeStyle = this.color;
                context.beginPath();
                context.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
                context.fill();
            }
        };

        function ballCollisionWithPaddle(paddle) {
            let dx = Math.abs(ballRef.current.x - paddle.getCenterX());
            let dy = Math.abs(ballRef.current.y - paddle.getCenterY());
            if (
                dx <= ballRef.current.radius + paddle.getHalfWidth() &&
                dy <= ballRef.current.radius + paddle.getHalfHeight()
            ) {
                ballRef.current.ballVelocityX *= -1;
                if (paddle.x === 0)
                    ballRef.current.x = paddle.x + paddle.width + ballRef.current.radius;
                else ballRef.current.x = paddle.x - ballRef.current.radius;

                if (
                    ballRef.current.ballVelocityY > 15 ||
                    ballRef.current.ballVelocityY < -15
                )
                    return;
                if (ballRef.current.ballVelocityY < 0) ballRef.current.ballVelocityY -= 1;
                else ballRef.current.ballVelocityY += 1;
                if (ballRef.current.ballVelocityX < 0) ballRef.current.ballVelocityX -= 1;
                else ballRef.current.ballVelocityX += 1;
            }
        }

        const handleContextMenu = e => {
            e.preventDefault();
        };

        const handleKeyDown = e => {
            keys.current[e.key] = true;
        };

        const handleKeyUp = e => {
            keys.current[e.key] = false;
        };

        const handleTouchEnd = e => {
            const touch = e.changedTouches[0];
            if (
                touch.clientX < canvas.parentElement.clientWidth / 2 &&
                touch.clientY < canvas.height / 2
            ) {
                keys.current[KEY_DOWN] = false;
            } else if (
                touch.clientX < canvas.parentElement.clientWidth / 2 &&
                touch.clientY > canvas.height / 2
            ) {
                keys.current[S_KEY] = false;
            }
            if (
                touch.clientX > canvas.parentElement.clientWidth / 2 &&
                touch.clientY > canvas.height / 2
            ) {
                keys.current[W_KEY] = false;
            } else if (
                touch.clientX > canvas.parentElement.clientWidth / 2 &&
                touch.clientY < canvas.height / 2
            ) {
                keys.current[KEY_UP] = false;
            }
        };

        canvas.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        canvas.addEventListener('touchend', handleTouchEnd);

        const handleTouchStart = e => {
            const touch = e.touches[0];
            if (
                touch.clientX < canvas.parentElement.clientWidth / 2 &&
                touch.clientY < canvas.height / 2
            ) {
                keys.current[KEY_DOWN] = true;
            } else if (
                touch.clientX < canvas.parentElement.clientWidth / 2 &&
                touch.clientY > canvas.height / 2
            ) {
                keys.current[S_KEY] = true;
            }
            if (
                touch.clientX > canvas.parentElement.clientWidth / 2 &&
                touch.clientY > canvas.height / 2
            ) {
                keys.current[W_KEY] = true;
            } else if (
                touch.clientX > canvas.parentElement.clientWidth / 2 &&
                touch.clientY < canvas.height / 2
            ) {
                keys.current[KEY_UP] = true;
            }
        };

        canvas.addEventListener('touchstart', handleTouchStart);

        myPaddleRef.current = {
            x: 0,
            y: canvas.height / 2 - 50,
            width: 10,
            height: 100,
            color: '#34c07d',
            paddleVelocity: 10,
            updatePaddle: function () {
                if (keys.current[KEY_UP] && this.y > 0 && this.y > 10) {
                    this.y -= this.paddleVelocity;
                }
                if (
                    keys.current[KEY_DOWN] &&
                    this.y < canvas.height - (this.height + 10)
                ) {
                    this.y += this.paddleVelocity;
                }
            },
            drawPaddle: function () {
                context.beginPath();
                context.roundRect(this.x, this.y, this.width, this.height, 20);
                context.fillStyle = this.color;
                context.fill();
            },
            getHalfWidth: function () {
                return this.width / 2;
            },
            getHalfHeight: function () {
                return this.height / 2;
            },
            getCenterX: function () {
                return this.x + this.getHalfWidth();
            },
            getCenterY: function () {
                return this.y + this.getHalfHeight();
            }
        };

        userPaddleRef.current = {
            x: canvas.width - 10,
            y: canvas.height / 2 - 50,
            width: 10,
            height: 100,
            color: '#f95a5a',
            paddleVelocity: 10,
            updatePaddle: function () {
                if (keys.current[W_KEY] && this.y > 0 && this.y > 10) {
                    this.y -= this.paddleVelocity;
                }
                if (keys.current[S_KEY] && this.y < canvas.height - (this.height + 10)) {
                    this.y += this.paddleVelocity;
                }
            },
            drawPaddle: function () {
                context.beginPath();
                context.roundRect(this.x, this.y, this.width, this.height, 20);
                context.fillStyle = this.color;
                context.fill();
            },
            getHalfWidth: function () {
                return this.width / 2;
            },
            getHalfHeight: function () {
                return this.height / 2;
            },
            getCenterX: function () {
                return this.x + this.getHalfWidth();
            },
            getCenterY: function () {
                return this.y + this.getHalfHeight();
            }
        };

        function increaseScore() {
            if (ballRef.current.x <= -ballRef.current.radius) {
                setComScore(prev => prev + 1);
                comScoreRef.current += 1;
                setHistory(prevHistory => [
                    {
                        type: 'play',
                        image: '/assets/images/img_unknown_user.svg',
                        username: 'Opponent',
                        message: t('game:local_opponent_scored', {
                            player: user.first_name,
                            mark: 'X'
                        })
                    },
                    ...prevHistory
                ]);
                if (comScoreRef.current === 5) {
                    setGameOver(true);
                    setGameResult({
                        winner: 'Opponent',
                        player1: {
                            username: user.username,
                            profile_picture: user.profile_picture,
                            full_name: user.full_name,
                            score: userScoreRef.current
                        },
                        player2: {
                            username: 'Opponent',
                            profile_picture: '/assets/images/img_unknown_user.svg',
                            full_name: 'Opponent',
                            score: comScoreRef.current
                        },
                        game_mode: 'local',
                        game_type: 'ping_pong'
                    });
                    navigate('/play');
                }
                respawnBall();
            }
            if (ballRef.current.x >= canvas.width + ballRef.current.radius) {
                setUserScore(prev => prev + 1);
                userScoreRef.current += 1;
                setHistory(prevHistory => [
                    {
                        type: 'play',
                        image: user && user.profile_picture,
                        username: user && user.username,
                        message: t('game:local_you_scored', {
                            player: user.first_name,
                            mark: 'X'
                        })
                    },
                    ...prevHistory
                ]);
                if (userScoreRef.current === 5) {
                    setGameOver(true);
                    setGameResult({
                        winner: user.username,
                        player1: {
                            username: user.username,
                            profile_picture: user.profile_picture,
                            full_name: user.full_name,
                            score: userScoreRef.current
                        },
                        player2: {
                            username: 'Opponent',
                            profile_picture: '/assets/images/img_unknown_user.svg',
                            full_name: 'Opponent',
                            score: comScoreRef.current
                        },
                        game_mode: 'local',
                        game_type: 'ping_pong'
                    });
                    navigate('/play');
                }
                respawnBall();
            }
        }

        function ballCollisionWithTheEdges() {
            if (ballRef.current.y + ballRef.current.radius >= canvas.height) {
                ballRef.current.ballVelocityY *= -1;
            }
            if (ballRef.current.y - ballRef.current.radius <= 0) {
                ballRef.current.ballVelocityY *= -1;
            }
        }

        function respawnBall() {
            ballRef.current.x = canvas.width / 2;
            ballRef.current.y = canvas.height / 2;
            if (userScoreRef.current === 5 || comScoreRef.current === 5) {
                ballRef.current.ballVelocityX = 0;
                ballRef.current.ballVelocityY = 0;
                return;
            }

            let random = Math.floor(Math.random() * 4) + 3;
            if (ballRef.current.ballVelocityY < 0) {
                ballRef.current.ballVelocityY = -random;
                ballRef.current.ballVelocityX = -random;
            } else {
                ballRef.current.ballVelocityY = random;
                ballRef.current.ballVelocityX = random;
            }
        }
        function drawCircle() {
            context.beginPath();
            context.arc(canvas.width / 2, canvas.height / 2, 50, 0, 2 * Math.PI);
            context.stroke();
        }

        function drawBorder() {
            context.beginPath();
            context.moveTo(20, 0);
            context.lineTo(canvas.width - 20, 0);
            context.arcTo(canvas.width, 0, canvas.width, 20, 20);
            context.lineTo(canvas.width, canvas.height - 20);
            context.arcTo(
                canvas.width,
                canvas.height,
                canvas.width - 20,
                canvas.height,
                20
            );
            context.lineTo(20, canvas.height);
            context.arcTo(0, canvas.height, 0, canvas.height - 20, 20);
            context.lineTo(0, 20);
            context.arcTo(0, 0, 20, 0, 20);
            context.strokeStyle = '#fff';
            context.stroke();
        }

        function drawLine() {
            context.beginPath();
            context.setLineDash([3, 2]);
            context.moveTo(canvas.width / 2, 10);
            context.lineTo(canvas.width / 2, canvas.height / 2 - 50);
            context.strokeStyle = '#ffffff66';
            context.stroke();

            context.beginPath();
            context.moveTo(canvas.width / 2, canvas.height / 2 + 50);
            context.lineTo(canvas.width / 2, canvas.height - 10);
            context.strokeStyle = '#ffffff66';
            context.stroke();
        }

        function gameUpdate() {
            ballRef.current.updateBall();
            myPaddleRef.current.updatePaddle();
            userPaddleRef.current.updatePaddle();
            ballCollisionWithTheEdges();
            ballCollisionWithPaddle(myPaddleRef.current);
            ballCollisionWithPaddle(userPaddleRef.current);
            increaseScore();
        }

        function gameDraw() {
            myPaddleRef.current.drawPaddle();
            userPaddleRef.current.drawPaddle();
            ballRef.current.drawBall();
            drawLine();
            drawCircle();
            drawBorder();
        }

        function gameLoop() {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = 'transparent';
            context.fillRect(0, 0, canvas.width, canvas.height);
            animationId = window.requestAnimationFrame(gameLoop);
            gameUpdate();
            gameDraw();
        }
        gameLoop();

        return () => {
            if (animationId) window.cancelAnimationFrame(animationId);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            canvas.removeEventListener('touchstart', handleTouchStart);
            canvas.removeEventListener('touchend', handleTouchEnd);
            canvas.removeEventListener('contextmenu', handleContextMenu);
        };
    }, [canvasRef, initiateGame]);

    return (
        <>
            {initiateGame ? (
                <div className={styles.container}>
                    <canvas
                        ref={canvasRef}
                        className={styles.canvas}
                        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                    />
                </div>
            ) : (
                <div className={styles.before_game_container}>
                    <BeforeGameGameBoy />
                </div>
            )}
        </>
    );
}
