import api from '../../../../../../api';
import React, { useState, useContext } from 'react';
import TwoFactorAuthView from './components/TwoFactorAuthView/TwoFactorAuthView';
import Imagify from '../../../../../../components/Imagify/Imagify';
import securityStyles from './SecurityForm.module.css';
import commonStyles from '../common.module.css';
import UserContext from '../../../../../../context/UserContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const styles = { ...securityStyles, ...commonStyles };

function SecurityForm() {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(prevState => !prevState);
    };
    const { user } = useContext(UserContext);
    const handleUpdatePassword = async e => {
        e.preventDefault();
        updatePassword(t);
    };

    const { t } = useTranslation(['settings']);

    return (
        <div className={styles.security_root_container}>
            <div className={styles.form_holder}>
                <form className={styles.form_container} onSubmit={handleUpdatePassword}>
                    <div className={styles.passwords_container}>
                        <div className={styles.input_layout_container}>
                            <label className={styles.label_item}>
                                {t('settings:pass_old')}
                            </label>

                            <div className={styles.info_item}>
                                <img alt='password' src='/assets/icons/ic_password.svg' />

                                <input
                                    required
                                    maxLength={32}
                                    id='input_old_password'
                                    type={passwordVisible ? 'text' : 'password'}></input>

                                <img
                                    className={styles.hideunhidepass_ic}
                                    alt='Show Hide password'
                                    src={
                                        passwordVisible
                                            ? '/assets/icons/ic_pass_off.svg'
                                            : '/assets/icons/ic_pass_on.svg'
                                    }
                                    onClick={() => togglePasswordVisibility()}
                                />
                            </div>
                        </div>

                        <div className={styles.input_layout_container}>
                            <label className={styles.label_item}>
                                {t('settings:pass_new')}
                            </label>

                            <div className={styles.info_item}>
                                <img alt='password' src='/assets/icons/ic_password.svg' />

                                <input
                                    required
                                    maxLength={32}
                                    id='input_new_password'
                                    type={passwordVisible ? 'text' : 'password'}></input>

                                <img
                                    className={styles.hideunhidepass_ic}
                                    alt='Show Hide password'
                                    src={
                                        passwordVisible
                                            ? '/assets/icons/ic_pass_off.svg'
                                            : '/assets/icons/ic_pass_on.svg'
                                    }
                                    onClick={() => togglePasswordVisibility()}
                                />
                            </div>
                        </div>
                    </div>

                    <button className={styles.save_changes_button} type='submit'>
                        <img alt='Done' src='/assets/icons/ic_check.svg' />
                        <h1>{t('settings:pass_update')}</h1>
                    </button>
                </form>
                <div
                    style={{
                        display: user === null || !user.is_intra_user ? 'none' : 'flex'
                    }}
                    className={styles.intra_no_pass_view}>
                    <Imagify alt='Locked' src='/assets/images/img_locked.svg' />
                    <h1>{t('settings:msg_intra_no_pass')}</h1>
                </div>
            </div>
            <TwoFactorAuthView />
        </div>
    );
}

async function updatePassword(t) {
    toast.dismiss();
    const data = {
        old_password: document.getElementById('input_old_password').value,
        new_password: document.getElementById('input_new_password').value
    };
    await api
        .post('/users/me/update_password/', data)
        .then(res => {
            if (res.status === 200) {
                toast.success(t('settings:msg_pass_updated'));
            }
        })
        .catch(err => {
            if (err.response.data.error_code === 0) {
                toast.error(t('settings:msg_pass_required'));
            } else if (err.response.data.error_code === 1) {
                toast.error(t('settings:msg_old_pass_incorrect'));
            } else {
                toast.error(t('settings:msg_pass_cant_update'));
            }
        });
}

export default SecurityForm;
