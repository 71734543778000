import React from 'react';
import classes from './TournamentCard.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import { useTranslation } from 'react-i18next';

function TournamentCard({ setRoom, tournament }) {
    const { t } = useTranslation(['tournaments', 'common']);

    return (
        <div
            className={classes.tournament_card}
            style={{
                backgroundImage: `url(${tournament.background_image})`
            }}
            onClick={() => {
                setRoom(tournament.id);
            }}>
            <div className={classes.tournament_card__holder}>
                <div className={classes.tournament_card__text}>
                    <h3 className={classes.tournament_card__title}>{tournament.name}</h3>
                    <p className={classes.tournament_card__subtitle}>
                        {tournament.game === 0 ? (
                            <>
                                {t('common:pingpong')}
                                <Imagify
                                    src='/assets/icons/ic_pingpong.svg'
                                    alt='Ping Pong'
                                />
                            </>
                        ) : (
                            <>
                                {t('common:tictactoe')}
                                <Imagify
                                    src='/assets/icons/ic_tictactoe.svg'
                                    alt='Ping Pong'
                                />
                            </>
                        )}
                    </p>
                </div>
                <div className={classes.active_players_top}>
                    <div className={classes.fraction}>
                        <span className={classes.fup}>
                            {tournament.active_participants}
                        </span>
                        <span className={classes.bar}></span>
                        <span className={classes.fdn}>4</span>
                    </div>
                    <div className={classes.active_player_text}>
                        <h1>{t('tournaments:active_players_1')} </h1>
                        <h1>{t('tournaments:active_players_2')}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TournamentCard;
