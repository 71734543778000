import '../../App.css';
import classes from './ShimmerList.module.css';
import Skeleton from 'react-loading-skeleton';
import React from 'react';

export default function ShimmerList({ count }) {
    return (
        <div className={classes.shimmer_container}>
            {Array(count)
                .fill(0)
                .map((_, i) => (
                    <div key={i} className={classes.shimmer_view}>
                        <Skeleton height={50} width={50} circle={true} />
                        <div className={classes.text}>
                            <Skeleton height={12} width={100} />
                            <Skeleton height={12} width={200} />
                        </div>
                    </div>
                ))}
        </div>
    );
}
