import '../../../../App.css';
import React, { useContext, useEffect, useState } from 'react';
import classes from '../TicTacToe/TicTacToe.module.css';
import GameContext from '../../../../context/GameContext';
import Imagify from '../../../../components/Imagify/Imagify';
import UserContext from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export default function TicTacToeO({ setHistory, history }) {
    const {
        gameSocket,
        emitGame,
        GameListener,
        setGameOver,
        setGameResult,
        Opponent,
        setRequestGame
    } = useContext(GameContext);
    const { user } = useContext(UserContext);
    const { t } = useTranslation(['game']);
    const [historyHolder, setHistoryHolder] = useState(null);
    const navigate = useNavigate();

    const handleClick = e => {
        if (!e.target.name) {
            toast.error(t('game:invalid_move'));
        }
        emitGame('tic_tac_toe_move', {
            cell: parseInt(e.target.name)
        });
    };

    const setGameHistory = data => {
        setHistoryHolder(data.data.history);
    };

    useEffect(() => {
        if (!historyHolder) return;
        if (historyHolder.username === user.username) {
            historyHolder.message = t('game:log_tic_you_play', {
                cell: historyHolder.cell
            });
        } else {
            historyHolder.message = t('game:log_tic_opponent_play', {
                opponent: Opponent.first_name,
                cell: historyHolder.cell
            });
        }
        setHistory([historyHolder, ...history]);
    }, [historyHolder]);

    useEffect(() => {
        if (!gameSocket) return;
        GameListener({
            tic_tac_toe_history: setGameHistory,
            tic_tac_toe_update: data => {
                setTicTacToeCells(data.data.board);
            },
            tic_tac_toe_winner: data => {
                setTicTacToeCells(data.data.board);
                setGameResult(data.data);
                setGameOver(true);
                setRequestGame(false);
                navigate('/play');
            },
            tic_tac_toe_tie: data => {
                setTicTacToeCells(data.data.board);
            }
        });
    }, [GameListener, gameSocket]);

    const [TicTacToeCells, setTicTacToeCells] = useState([
        ['', '', '', '', '', '', '', '', '']
    ]);

    return (
        <div className={classes.tictactoe}>
            <div className={classes.tictactoe__board}>
                <div className={classes.tictactoe__row}>
                    <button
                        className={classes.tictactoe__cell}
                        name='0'
                        onClick={handleClick}>
                        {TicTacToeCells[0] === 'X' || TicTacToeCells[0] === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    TicTacToeCells[0] === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={classes.tictactoe__cell}
                        name='1'
                        onClick={handleClick}>
                        {TicTacToeCells[1] === 'X' || TicTacToeCells[1] === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    TicTacToeCells[1] === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={classes.tictactoe__cell}
                        name='2'
                        onClick={handleClick}>
                        {TicTacToeCells[2] === 'X' || TicTacToeCells[2] === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    TicTacToeCells[2] === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                </div>
                <div className={classes.border_bottom__one}></div>
                <div className={classes.border_top__one}></div>
                <div className={classes.tictactoe__row}>
                    <button
                        className={classes.tictactoe__cell}
                        name='3'
                        onClick={handleClick}>
                        {TicTacToeCells[3] === 'X' || TicTacToeCells[3] === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    TicTacToeCells[3] === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={classes.tictactoe__cell}
                        name='4'
                        onClick={handleClick}>
                        {TicTacToeCells[4] === 'X' || TicTacToeCells[4] === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    TicTacToeCells[4] === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={classes.tictactoe__cell}
                        name='5'
                        onClick={handleClick}>
                        {TicTacToeCells[5] === 'X' || TicTacToeCells[5] === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    TicTacToeCells[5] === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                </div>
                <div className={classes.border_bottom__two}></div>
                <div className={classes.border_top__two}></div>
                <div className={classes.tictactoe__row}>
                    <button
                        className={classes.tictactoe__cell}
                        name='6'
                        onClick={handleClick}>
                        {TicTacToeCells[6] === 'X' || TicTacToeCells[6] === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    TicTacToeCells[6] === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={classes.tictactoe__cell}
                        name='7'
                        onClick={handleClick}>
                        {TicTacToeCells[7] === 'X' || TicTacToeCells[7] === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    TicTacToeCells[7] === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                    <button
                        className={classes.tictactoe__cell}
                        name='8'
                        onClick={handleClick}>
                        {TicTacToeCells[8] === 'X' || TicTacToeCells[8] === 'O' ? (
                            <Imagify
                                alt=''
                                src={
                                    TicTacToeCells[8] === 'X'
                                        ? '/assets/icons/ic_x.svg'
                                        : '/assets/icons/ic_o.svg'
                                }
                            />
                        ) : (
                            ''
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}
