import '../../App.css';
import classes from './Game.module.css';
import React, { useContext, useState, useEffect } from 'react';
import GameLogs from './components/GameLogs/GameLogs';
import OpponentsPanel from './components/OpponentsPanel/OpponentsPanel';
import GameCustomize from './components/GameCustomize/GameCustomize';
import GameContext from '../../context/GameContext';
import UserContext from '../../context/UserContext';
import SocketContext from '../../context/SocketContext';
import GameOver from './components/GameOver/GameOver';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function Game() {
    const navigate = useNavigate();
    const { t } = useTranslation(['game', 'common']);
    // eslint-disable-next-line no-unused-vars
    const [userScore, setUserScore] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [comScore, setComScore] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [history, setHistory] = useState([]);
    const { setPlayingWith, selectedGame, isOnlineGame, user, playingWith } =
        useContext(UserContext);
    const { gameOver, emitGame, setRequestGame } = useContext(GameContext);
    const { emit } = useContext(SocketContext);
    const [joinCooldowned, setJoinCooldowned] = useState(true);
    const [activeToastId, setActiveToastId] = useState(null);

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            setJoinCooldowned(false);
        }, 2000);
        return () => {
            setPlayingWith(null);
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div className={classes.games}>
            <div className={`${classes.gamesContainer} background_secondary`}>
                <div className={classes.gamesAreaHolder}>
                    {gameOver ? <GameOver /> : <GameCustomize />}
                </div>
                <div className={classes.panel_holder}>
                    <div className={`${classes.panel} background_primary`}>
                        <h3 className={classes.panel_title}>{t('common:pingerwar')}</h3>
                        <p className={classes.panel_subtitle}>{t('game:match_recap')}</p>
                        <OpponentsPanel userScore={userScore} comScore={comScore} />
                        <GameLogs history={history} />
                        <button
                            className={classes.panel_button}
                            onClick={e => {
                                if (joinCooldowned) {
                                    if (!toast.isActive(activeToastId)) {
                                        setActiveToastId(
                                            toast.error(t('game:join_cooldowned'))
                                        );
                                    }
                                    return;
                                }
                                e.preventDefault();
                                if (!isOnlineGame && selectedGame === 'TicTacToe') {
                                    navigate('/play/tic-tac-toe-local');
                                } else if (!isOnlineGame && selectedGame === 'PingPong') {
                                    navigate('/play/ping-pong-local');
                                } else if (isOnlineGame && selectedGame === 'TicTacToe') {
                                    if (!playingWith) {
                                        emitGame('public_tic_tac_toe_game', {});
                                    } else {
                                        emit('send_game_request', {
                                            game_type: 'TicTacToe',
                                            room_name:
                                                'tic_tac_toe_' +
                                                user.username +
                                                '_' +
                                                playingWith.username,
                                            receiver: playingWith.username
                                        });
                                        emitGame('private_tic_tac_toe_game', {
                                            room_name:
                                                'tic_tac_toe_' +
                                                user.username +
                                                '_' +
                                                playingWith.username,
                                            receiver: playingWith.username
                                        });
                                    }
                                    setRequestGame(true);
                                    navigate('/play/tic-tac-toe-online');
                                } else if (isOnlineGame && selectedGame === 'PingPong') {
                                    if (!playingWith) {
                                        emitGame('public_ping_pong_game', {
                                            game: 'ping_pong'
                                        });
                                    } else {
                                        emit('send_game_request', {
                                            game_type: 'PingPong',
                                            room_name:
                                                'ping_pong_' +
                                                user.username +
                                                '_' +
                                                playingWith.username,
                                            receiver: playingWith.username
                                        });
                                        emitGame('private_ping_pong_game', {
                                            game: 'ping_pong',
                                            room_name:
                                                'ping_pong_' +
                                                user.username +
                                                '_' +
                                                playingWith.username,
                                            receiver: playingWith.username
                                        });
                                    }
                                    setRequestGame(true);
                                    navigate('/play/ping-pong-online');
                                }
                            }}>
                            {t('game:start_game')}
                        </button>
                    </div>
                </div>
                <button
                    className={classes.panel_button_mobile}
                    onClick={e => {
                        e.preventDefault();
                        if (!isOnlineGame && selectedGame === 'TicTacToe') {
                            navigate('/play/tic-tac-toe-local');
                        } else if (!isOnlineGame && selectedGame === 'PingPong') {
                            navigate('/play/ping-pong-local');
                        } else if (isOnlineGame && selectedGame === 'TicTacToe') {
                            if (!playingWith) {
                                emitGame('public_tic_tac_toe_game', {});
                            } else {
                                emit('send_game_request', {
                                    game_type: 'TicTacToe',
                                    room_name:
                                        'tic_tac_toe_' +
                                        user.username +
                                        '_' +
                                        playingWith.username,
                                    receiver: playingWith.username
                                });
                                emitGame('private_tic_tac_toe_game', {
                                    room_name:
                                        'tic_tac_toe_' +
                                        user.username +
                                        '_' +
                                        playingWith.username,
                                    receiver: playingWith.username
                                });
                            }
                            setRequestGame(true);
                            navigate('/play/tic-tac-toe-online');
                        } else if (isOnlineGame && selectedGame === 'PingPong') {
                            if (!playingWith) {
                                emitGame('public_ping_pong_game', {
                                    game: 'ping_pong'
                                });
                            } else {
                                emit('send_game_request', {
                                    game_type: 'PingPong',
                                    room_name:
                                        'ping_pong_' +
                                        user.username +
                                        '_' +
                                        playingWith.username,
                                    receiver: playingWith.username
                                });
                                emitGame('private_ping_pong_game', {
                                    game: 'ping_pong',
                                    room_name:
                                        'ping_pong_' +
                                        user.username +
                                        '_' +
                                        playingWith.username,
                                    receiver: playingWith.username
                                });
                            }
                            setRequestGame(true);
                            navigate('/play/ping-pong-online');
                        }
                    }}>
                    {t('game:start_game')}
                </button>
            </div>
        </div>
    );
}

export default Game;
