import '../../../../App.css';
import React, { useContext, useEffect } from 'react';
import classes from './ChatMessageItem.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import UserContext from '../../../../context/UserContext';
import moment from 'moment';
import ChatSocketContext from '../../../../context/ChatSocketContext';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ChatMessageItem({ contact }) {
    const { user, setUserData, setCoverImageUrl, friendsList, fetchUser, fetchUserData } =
        useContext(UserContext);
    const { emit, setMessages, setOffset, setLoadButton } = useContext(ChatSocketContext);
    const { chattingWithQuery } = useParams();
    const { t } = useTranslation(['resources']);

    useEffect(() => {
        if (chattingWithQuery === contact.conversation.participant.username) {
            emit('mark_messages_seen', {
                conversation_id: contact.conversation.conversation_id,
                messagesOf: contact.conversation.participant.username,
                sender: user.username
            });
        }
    }, [chattingWithQuery]);

    const handleClick = username => {
        const conversation_id = contact.conversation.conversation_id;
        emit('mark_messages_seen', {
            conversation_id: conversation_id,
            messagesOf: username,
            sender: user.username
        });
        if (username === chattingWithQuery) return;
        fetchUserData(setUserData, username, setCoverImageUrl, t);
        fetchUser();
        setOffset(0);
        setMessages([]);
        setLoadButton(false);
    };

    return (
        <Link
            to={
                contact.conversation.participant.username !== chattingWithQuery
                    ? `/messages/${contact.conversation.participant.username}`
                    : '#'
            }>
            <li
                className={`${classes.contact_user} ${chattingWithQuery === contact.conversation.participant.username ? classes.active : ''}`}
                onClick={() => handleClick(contact.conversation.participant.username)}>
                <div className={classes.profile_pic}>
                    <Imagify
                        className={classes.profile_image}
                        src={contact.conversation.participant.profile_picture}
                        alt='profile'
                    />
                    <div
                        style={{
                            background: friendsList.find(
                                friend =>
                                    friend.username ===
                                    contact.conversation.participant.username
                            )?.is_online
                                ? '#00d315'
                                : '#d2d2d2'
                        }}
                        className={classes.status}></div>
                </div>
                <div className={classes.name_and_message}>
                    <div>
                        <h2>
                            {contact.conversation.participant.first_name}{' '}
                            {contact.conversation.participant.last_name}
                        </h2>
                        <div className={classes.small_ellipse}></div>
                        <p>
                            {moment(contact.conversation.latest_message.created_at)
                                .fromNow(false)
                                .replace('seconds', 'secs')
                                .replace('minutes', 'mins')
                                .replace('hours', 'hrs')}
                        </p>
                    </div>
                    <p className={classes.message}>
                        {contact.conversation.latest_message.message}
                    </p>
                </div>
                <div
                    className={
                        contact.conversation.unseen_messages > 0
                            ? classes.message_indicator
                            : ''
                    }>
                    <p>
                        {contact.conversation.unseen_messages > 0
                            ? contact.conversation.unseen_messages
                            : ''}
                    </p>
                </div>
                <div className={classes.bar_hover}></div>
            </li>
        </Link>
    );
}

export default ChatMessageItem;
