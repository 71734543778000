import api from '../../../../../../../../api';
import React, { useState, useContext, useEffect } from 'react';
import styles from './TwoFactorAuthView.module.css';
import Imagify from '../../../../../../../../components/Imagify/Imagify';
import UserContext from '../../../../../../../../context/UserContext';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export default function TwoFactorAuthView() {
    const [is2FAEnabled, set2FAEnabled] = useState(false);
    const { user } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [QRCode, setQrCode] = useState('');
    const { t } = useTranslation(['settings']);

    useEffect(() => {
        if (user && user.otp_verified === false) {
            generate2FAQrCode(setQrCode, setIsLoading, set2FAEnabled);
        } else {
            set2FAEnabled(true);
            setIsLoading(false);
        }
    }, [user]);

    if (is2FAEnabled) {
        return (
            <div className={styles.two_fauth_enabled_card}>
                <Imagify alt='Done' src='/assets/icons/ic_enable.svg' />
                <h1>{t('settings:2fa_enabled')}</h1>

                <button
                    className={styles.save_changes_button}
                    onClick={e => {
                        e.preventDefault();
                        disable2FA(set2FAEnabled, setQrCode, setIsLoading, user, t);
                    }}>
                    <img alt='Disable 2FA' src='/assets/icons/ic_disable.svg' />
                    <p>{t('settings:2fa_disable')}</p>
                </button>
            </div>
        );
    }

    return (
        <div className={styles.two_fauth_root_container}>
            <h1 className={styles.two_fauth_title}>{t('settings:2fa_title')}</h1>

            {isLoading ? (
                <div className={styles.loading_view}>
                    <Skeleton height={150} width={150} borderRadius={15} />
                    <div className={styles.loading_inner_view}>
                        <Skeleton height={20} width={200} />
                        <Skeleton borderRadius={10} height={50} width={250} />
                        <Skeleton height={20} width={100} />
                    </div>
                </div>
            ) : (
                <div className={styles.two_fauth_card}>
                    <Imagify
                        className={styles.two_fauth_qrcode}
                        alt='QR Code'
                        src={QRCode}
                    />

                    <form
                        className={styles.two_fauth_form}
                        onSubmit={e => {
                            e.preventDefault();
                            verify2FACode(set2FAEnabled, t);
                        }}>
                        <div className={styles.input_layout_container}>
                            <label>{t('settings:2fa_enter_code')}</label>
                            <div className={styles.info_item}>
                                <img alt='PIN' src='/assets/icons/ic_password.svg' />
                                <input
                                    required
                                    maxLength={6}
                                    id='2fa_code'
                                    type='2fa-code'
                                />
                            </div>
                        </div>

                        <button className={styles.save_changes_button} type='submit'>
                            <img alt='Enable 2FA' src='/assets/icons/ic_af.svg' />
                            <h1>{t('settings:2fa_enable')}</h1>
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
}

async function generate2FAQrCode(setQrCode, setIsLoading, set2FAEnabled) {
    try {
        const response = await api.get('/auth/generate_2fa/');
        if (response.status === 200) {
            setQrCode(`data:image/png;base64,${response.data.qr_code}`);
            setIsLoading(false);
            set2FAEnabled(false);
        }
    } catch (error) {
        setIsLoading(false);
    }
}

function verify2FACode(set2FAEnabled, t) {
    const code = document.getElementById('2fa_code').value;
    if (!code) {
        toast.info(t('settings:2fa_enter_your_code'));
        return;
    }
    api.post('/auth/verify_2fa/', { code: code })
        .then(response => {
            if (response.status === 200) {
                set2FAEnabled(true);
            }
        })
        .catch(() => {
            toast.error(t('settings:2fa_code_wrong'));
        });
}

function disable2FA(set2FAEnabled, setQrCode, setIsLoading, user, t) {
    api.get('/auth/disable_2fa/')
        .then(response => {
            if (response.status === 200) {
                set2FAEnabled(false);
                generate2FAQrCode(setQrCode, setIsLoading, set2FAEnabled);
                user.otp_verified = false;
                user.otp_enabled = false;
                toast.success(t('settings:2fa_disabled'));
            }
        })
        .catch(() => {
            toast.error(t('settings:2fa_cant_disable'));
        });
}
