import React, { useContext } from 'react';
import classes from './LobbyCard.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import GameContext from '../../../../context/GameContext';
import UserContext from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LobbyCard = ({ Lobby, tournamentId, game }) => {
    const { emitGame, setRequestGame } = useContext(GameContext);

    const navigate = useNavigate();
    const { user, setSelectedGame } = useContext(UserContext);
    const { t } = useTranslation(['game', 'common']);

    return (
        <div className={`${classes.lobby_card} `}>
            <div className={classes.lobbyLeftSide}>
                <Imagify
                    src={Lobby.player1_profile_picture}
                    alt='player'
                    className={classes.player_image}
                />
            </div>
            <div className={classes.middleContainer}>
                {Lobby.state !== 'FINISHED' ? (
                    <button
                        className={`${classes.join_button} ${Lobby.state !== 'FINISHED' ? classes.shaking : ''}`}
                        onClick={() => {
                            setRequestGame(true);
                            emitGame(
                                game === '0'
                                    ? 'private_ping_pong_game'
                                    : 'private_tic_tac_toe_game',
                                {
                                    room_name: Lobby.room_name,
                                    game: `${game === '1' ? 'tic-tac-toe' : 'ping_pong'}`,
                                    lobbyId: Lobby.id,
                                    opponent:
                                        Lobby.player1_username === (user && user.username)
                                            ? Lobby.player2_username
                                            : Lobby.player1_username,
                                    tournament_id: tournamentId
                                }
                            );
                            setSelectedGame(game === '0' ? 'PingPong' : 'TicTacToe');
                            navigate(
                                game === '0'
                                    ? '/play/ping-pong-online'
                                    : '/play/tic-tac-toe-online'
                            );
                        }}>
                        <Imagify src='/assets/icons/ic_challenge.svg' alt='Join Game' />
                        <p>{t('game:join')}</p>
                    </button>
                ) : (
                    <button className={classes.waiting_button}>
                        <img
                            src='/assets/icons/ic_done.svg'
                            alt='Done'
                            style={{
                                width: '25px'
                            }}
                        />
                    </button>
                )}
            </div>

            <div className={classes.lobbyRightSide}>
                <Imagify
                    src={Lobby.player2_profile_picture}
                    alt='player'
                    className={classes.player_image}
                />
            </div>
        </div>
    );
};

export default LobbyCard;
