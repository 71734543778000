import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

api.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        const currentPath = window.location.pathname;

        if (error.response.status === 401 && !currentPath.includes('/auth')) {
            if (originalRequest.url.endsWith('/auth/refresh_token/')) {
                // If we are already trying to refresh the token and it fails, redirect to login.
                window.location.href = `${process.env.REACT_APP_BASE_URL}/auth`;
                return Promise.reject(error); // Prevent further retries
            }

            if (!originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    await api.get('/auth/refresh_token/');
                    return api(originalRequest); // Retry the original request with the new token
                } catch (refreshError) {
                    window.location.href = `${process.env.REACT_APP_BASE_URL}/auth`;
                    return Promise.reject(refreshError);
                }
            }
        }
        return Promise.reject(error);
    }
);

export default api;
