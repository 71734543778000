import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../api';
import UserContext from './UserContext';

const ChatSocketContext = createContext(null);

export const ChatSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const { isLoggedIn } = useContext(UserContext);
    const [conversationsList, setConversationsList] = useState([]);
    const [messages, setMessages] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const [isProfile, setIsProfile] = useState(false);
    const [chattingWith, setChattingWith] = useState(null);
    const [offset, setOffset] = useState(0);
    const [conversationLoading, setConversationLoading] = useState(true);
    const [messageLoading, setMessageLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [loadButton, setLoadButton] = useState(false);

    const connect = () => {
        const newSocket = new WebSocket(process.env.REACT_APP_WS_URL + '/ws/chat/');
        newSocket.onopen = () => {
            setSocket(newSocket);
        };
    };

    const emit = (eventName, data) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ event: eventName, data }));
        }
    };

    const on = eventListeners => {
        if (socket) {
            socket.onmessage = event => {
                const data = JSON.parse(event.data);
                const matchingListener = eventListeners[data.event];
                if (matchingListener) {
                    matchingListener(data);
                }
            };
        }
    };

    const disconnectChat = () => {
        if (socket) {
            socket.close();
            setSocket(null);
        }
    };

    useEffect(() => {
        if (!isLoggedIn) return;
        connect();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [isLoggedIn]);

    const getMessages = async (isFriend, chattingWithQuery) => {
        try {
            if (!isFriend || conversationsList.length === 0) {
                setConversationLoading(false);
                return;
            }
            const conversation_id = conversationsList.find(
                conversation =>
                    conversation.conversation.participant.username === chattingWithQuery
            ).conversation.conversation_id;
            const response = await api.get(
                `/chat/messages/?conversation_id=${conversation_id}&offset=${offset}`
            );
            setConversationLoading(false);
            setLoadButton(response.data.messages_sent === 15);
            setMessages([...messages, ...response.data.messages]);
            setOffset(response.data.next_offset);
        } catch (error) {
            setConversationLoading(false);
            setMessages([]);
            setOffset(0);
            console.error('Error fetching messages:', error);
        }
    };

    return (
        <ChatSocketContext.Provider
            value={{
                socket,
                connect,
                emit,
                on,
                onclose,
                getMessages,
                messages,
                loaded,
                readOnly,
                conversationsList,
                newMessage,
                isProfile,
                chattingWith,
                offset,
                conversationLoading,
                messageLoading,
                isTyping,
                loadButton,
                setMessages,
                setConversationsList,
                setLoaded,
                setReadOnly,
                setIsProfile,
                setChattingWith,
                setOffset,
                setConversationLoading,
                setMessageLoading,
                setNewMessage,
                setIsTyping,
                setLoadButton,
                disconnectChat
            }}>
            {children}
        </ChatSocketContext.Provider>
    );
};

export default ChatSocketContext;
