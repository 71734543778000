import '../../App.css';
import classes from './CircularProgressBar.module.css';
import AnimatedCounter from '../AnimatedCounter/AnimatedCounter';
import { useTranslation } from 'react-i18next';
import React from 'react';

function CircularProgressBar({ percentage }) { 
    const { t } = useTranslation(['dashboard']);
    
    return (
        <div className={classes.container}>
            <div className={classes.firstCircle}>
                <svg
                    width='250'
                    height='250'
                    viewBox='-31.25 -31.25 312.5 312.5'
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    style={{
                        transform: 'rotate(-90deg)'
                    }}>
                    <circle className={classes.circleF} r='115' cx='125' cy='125' fill='none' stroke='#7E2731' strokeWidth='25px' strokeDashoffset='0'></circle>
                </svg>
            </div>
            <div className={classes.secondCircle}>
                <svg
                    width='250'
                    height='250'
                    viewBox='-31.25 -31.25 312.5 312.5'
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    style={{
                        transform: 'rotate(-90deg)'
                    }}>
                    <defs>
                        <filter id='drop-shadow' x='-20%' y='-20%' width='140%' height='140%'>
                            <feGaussianBlur in='SourceAlpha' stdDeviation='4' />
                            <feOffset dx='0' dy='0' result='offsetblur' />
                            <feComponentTransfer>
                                <feFuncA type='linear' slope='1' />
                            </feComponentTransfer>
                            <feMerge>
                                <feMergeNode />
                                <feMergeNode in='SourceGraphic' />
                            </feMerge>
                        </filter>
                    </defs>
                    <circle
                        className={classes.circleS}
                        style={{
                            '--percentage': `${904 - (904 * percentage) / 100}`
                        }}
                        r='130'
                        cx='125'
                        cy='125'
                        stroke='#fff'
                        strokeWidth='20'
                        strokeLinecap='round'
                        fill='none'
                        strokeDasharray='863px'
                        filter='url(#drop-shadow)'></circle>
                    ;
                </svg>
            </div>
            <div className={classes.secondShadow}></div>
            <div className={classes.innerCircle}>
                <AnimatedCounter endValue={percentage} joinWith='%' style={classes.innerCirclePercentage} />
                <p>{t('dashboard:current_progress')}</p>
            </div>
        </div>
    );
}

export default CircularProgressBar;
