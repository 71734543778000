import '../../../../App.css';
import styles from './ChartView.module.css';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto'; // DON'T REMOVE!!! Required for chart.js
import { Line } from 'react-chartjs-2';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../context/UserContext';

function ChartView() {
    const { t } = useTranslation(['dashboard', 'common']);
    const { gameHistory, user, gameUserHistory, userData } = useContext(UserContext);
    const [pingPongExp, setPingPongExp] = useState([]);
    const [ticTacToeExp, setTicTacToeExp] = useState([]);
    const [pingPongTimestamp, setPingPongTimestamp] = useState([]);
    const [ticTacToeTimestamp, setTicTacToeTimestamp] = useState([]);
    let data = {
        labels: ['', '', '', '', '', '', '', '', '', ''],
        datasets: [
            {
                label: 'Ping Pong',
                data: pingPongExp,
                borderColor: 'rgba(144, 238, 144, 1)',
                tension: 0.4,
                borderWidth: 7,
                pointStyle: true,
                borderCapStyle: 'round'
            },
            {
                label: 'TicTacToe',
                data: ticTacToeExp,
                borderColor: 'rgba(135, 206, 250, 1)',
                tension: 0.4,
                borderWidth: 7,
                pointStyle: true,
                borderCapStyle: 'round'
            }
        ]
    };

    useEffect(() => {
        if (user === null || (gameHistory.length === 0 && gameUserHistory.length === 0)) return;
        
        const history = userData ? gameUserHistory : gameHistory;
        const lastTenPongGames = history.filter(game => game.game_display === 'Ping Pong').slice(-10);
        const lastTenTicGames = history.filter(game => game.game_display === 'Tic Tac Toe').slice(-10);
 
        setPingPongExp([]);
        setTicTacToeExp([]);

        setPingPongExp(lastTenPongGames.map(game => userData ? (userData.username === game.player1_username ? game.player1_xp : game.player2_xp) : (user.username === game.player1_username ? game.player1_xp : game.player2_xp)));
        setTicTacToeExp(lastTenTicGames.map(game => userData ? (userData.username === game.player1_username ? game.player1_xp : game.player2_xp) : (user.username === game.player1_username ? game.player1_xp : game.player2_xp)));

        setPingPongTimestamp(lastTenPongGames.map(game => new Date(game.timestamp).toLocaleString()));
        setTicTacToeTimestamp(lastTenTicGames.map(game => new Date(game.timestamp).toLocaleString()));

        data.datasets[0].data = pingPongExp;
        data.datasets[1].data = ticTacToeExp;
    }, [user, userData, gameHistory, gameUserHistory]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
                display: true,
                labels: {
                    color: 'white',
                    boxHeight: 0
                }
            },
            title: {
                display: true
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label || '';
                        const timestamp = label === 'Ping Pong' ? pingPongTimestamp[context.dataIndex] : ticTacToeTimestamp[context.dataIndex];
                        const xp = context.raw;
                        return `${label}: ${xp} XP\n${timestamp}`;
                    }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: 'white'
                },
                grid: {
                    display: false,
                    color: 'rgba(255, 255, 255, 0.15)'
                }
            },
            y: {
                ticks: {
                    color: 'white'
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.15)'
                }
            }
        }
    };

    return (
        <div className={styles.graph_root_container}>
            <div className={styles.graph_container}>
                <h1 className='clr-white fs-1d3rem '>{t('last_played_games')}</h1>
                <Line
                    className={styles.user_win_rate_graph}
                    data={data}
                    options={options}
                />
            </div>
        </div>
    );
}

export default ChartView;
