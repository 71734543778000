import { toast } from 'react-toastify';
import api from '../../../api';

async function authenticate42IntraUser(
    t,
    intraCode,
    setIsLoggedIn,
    setAuthStage,
    setUserCredentials
) {
    if (!intraCode) {
        return;
    }

    const loadingToastId = toast(t('authentication:info_logging_in'), {
        autoClose: false,
        isLoading: true,
        position: 'top-center'
    });

    try {
        const response = await api.post('/auth/intra_login/', { code: intraCode });

        if (response.status === 200 || response.status === 201) {
            const previous_user = response.data.previous_user;
            localStorage.setItem('previous_user', previous_user);
            toast.dismiss(loadingToastId);
            setTimeout(() => setIsLoggedIn(true), 500);
            return;
        }
    } catch (error) {
        toast.dismiss(loadingToastId);
        if (error.response.status === 403) {
            setUserCredentials(userCredentials => ({
                ...userCredentials,
                username: error.response.data.username
            }));
            setAuthStage(2);
            return;
        }
    }

    toast.update(loadingToastId, {
        render: t('authentication:info_invalid'),
        type: 'error',
        isLoading: false,
        autoClose: 3000
    });
}

function navigateTo42IntraLogin() {
    const authPage =
        process.env.REACT_APP_INTRA_BASE_URL +
        '/oauth/authorize?client_id=' +
        process.env.REACT_APP_INTRA_UID +
        '&redirect_uri=' +
        process.env.REACT_APP_INTRA_REDIRECT_URI +
        '&response_type=code';
    window.location = authPage;
}

export { navigateTo42IntraLogin, authenticate42IntraUser };
