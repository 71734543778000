import './App.css';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { useContext, useEffect } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import Navbar from './components/Navbar/Navbar';
import Dashboard from './pages/Dashboard/Dashboard';
import Chat from './pages/Chat/Chat';
import Authentication from './pages/Authentication/Authentication';
import Settings from './pages/Settings/Settings';
import Tournaments from './pages/Tournaments/Tournaments';
import Game from './pages/Game/Game';
import UserContext from './context/UserContext';
import TicTacToeLocal from './pages/Game/pages/TicTacToeLocal/TicTacToeLocal';
import PingPongLocal from './pages/Game/pages/PingPongLocal/PingPongLocal';
import TicTacToeOnline from './pages/Game/pages/TicTacToeOnline/TicTacToeOnline';
import PingPongOnline from './pages/Game/pages/PingPongOnline/PingPongOnline';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import ScreenLoading from './components/ScreenLoading/ScreenLoading';
import Error from './pages/Error/Error';
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { SocketProvider } from './context/SocketContext';
import { ChatSocketProvider } from './context/ChatSocketContext';
import { GameProvider } from './context/GameContext';
import { ToastContainer } from 'react-toastify';

function App() {
    const { isLoggedIn, user, fetchUser, userLoaded } = useContext(UserContext);
	const language = localStorage.getItem('language') || 'en';

    useEffect(() => {
        if (user) return;
        fetchUser();
    }, [isLoggedIn, user, fetchUser]);

    return (
		<div className={`${isLoggedIn ? 'App' : 'NotLogged'} ${language}`}>
            <SocketProvider>
                <ChatSocketProvider>
                    <GameProvider>
                        <BrowserRouter>
							{userLoaded ? (
								<>
								{window.location.pathname !== '/auth' && window.location.pathname !== '/404' && isLoggedIn && (
									<>
										<Sidebar />
										<Navbar />
									</>
								)}
								<Routes>
									<Route path='/auth' element={isLoggedIn ? <Navigate to="/dashboard/me" /> : <Authentication />} />
									<Route path='/' element={isLoggedIn ? <Navigate to="/dashboard/me" /> : <Authentication />} />
									<Route path="/dashboard/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
									<Route path="/dashboard/:usernameSearchQuery" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
									<Route path="/messages/:chattingWithQuery" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
									<Route path="/messages/" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
									<Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
									<Route path="/tournaments" element={<ProtectedRoute><Tournaments /></ProtectedRoute>} />
									<Route path="/play" element={<ProtectedRoute><Game /></ProtectedRoute>} />
									<Route path="/play/ping-pong-online" element={<ProtectedRoute><PingPongOnline /></ProtectedRoute>} />
									<Route path="/play/ping-pong-local" element={<ProtectedRoute><PingPongLocal /></ProtectedRoute>} />
									<Route path="/play/tic-tac-toe-online" element={<ProtectedRoute><TicTacToeOnline /></ProtectedRoute>} />
									<Route path="/play/tic-tac-toe-local" element={<ProtectedRoute><TicTacToeLocal /></ProtectedRoute>} />
									<Route path="/404" element={<Error />} />
									<Route path='*' element={<Navigate to='/404' />} />
								</Routes>
								</>
							) :
							(
								<div style={{width: "100vw", height: "100vh"}}>
									<ScreenLoading />
								</div>
							)}
                        </BrowserRouter>
                    </GameProvider>
                </ChatSocketProvider>
            </SocketProvider>
            <ToastContainer limit={1} position='top-center' draggable={true} />
			<Analytics />
        </div>
    );
}

export default App;
