import React, { useEffect, useState, useRef } from 'react';
import classes from './SearchBar.module.css';
import '../../../../App.css';
import { useNavigate } from 'react-router-dom';
import api from '../../../../api';
import Imagify from '../../../../components/Imagify/Imagify';
import { useTranslation } from 'react-i18next';

function SearchBar() {
    const navigate = useNavigate();
    const { t } = useTranslation(['dashboard']);
    const [placeholder, setPlaceholder] = useState(t('dashboard:search_text'));
    const [results, setResults] = useState([]);
    const [focus, setFocus] = useState(false);
    const [query, setQuery] = useState('');
    const [usersFetching, setUsersFetching] = useState(false);
    const [notEmpty, setNotEmpty] = useState(false);

    const handleChange = e => {
        setQuery(e.target.value);
        if (e.target.value.length !== 0) {
            setNotEmpty(true);
        } else {
            setNotEmpty(false);
        }
    };

    const searchRef = useRef(null);

    const handleClickOutside = event => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setFocus(false);
        }
    };

    async function fetchUsers(username) {
        await api
            .get('/users/search/' + username)
            .then(response => {
                setResults(response.data);
                setUsersFetching(false);
            })
            .catch(() => {
                setUsersFetching(false);
                setResults([]);
            });
    }

    useEffect(() => {
        if (query.length === 0) {
            setFocus(false);
            setResults([]);
            return;
        }
        setUsersFetching(true);
        fetchUsers(query);
        setFocus(true);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [query]);

    const handleFocus = () => {
        if (query.length > 0) setFocus(true);
    };

    const changePlaceholder = () => {
        if (window.innerWidth <= 660) {
            setPlaceholder(t('dashboard:searchdots'));
        } else {
            setPlaceholder(t('dashboard:search_text'));
        }
    };

    useEffect(() => {
        changePlaceholder();
        window.addEventListener('resize', changePlaceholder);
        return () => {
            window.removeEventListener('resize', changePlaceholder);
        };
    }, []);

    return (
        <div ref={searchRef} className={classes.search}>
            <div className={`${classes.search_bar} background_primary`}>
                <img
                    src='/assets/icons/ic_search.svg'
                    alt='search icon'
                    className={classes.search_icon}
                />
                <input
                    type='text'
                    placeholder={placeholder}
                    value={query}
                    onChange={handleChange}
                    onFocus={handleFocus}
                />
                {notEmpty && (
                    <div className={classes.close_icon}>
                        <img
                            src='/assets/icons/ic_cancel_grey.svg'
                            alt='close icon'
                            onClick={() => {
                                setQuery('');
                                setNotEmpty(false);
                            }}
                        />
                    </div>
                )}
            </div>
            <div
                className={`${classes.search_results} ${
                    focus ? `${classes.focus}` : ''
                }`}>
                {usersFetching ? (
                    <p className={classes.empty_results}>
                        {t('dashboard:search_loading')}
                    </p>
                ) : results.length === 0 ? (
                    <p className={classes.empty_results}>
                        {t('dashboard:search_no_results')}
                    </p>
                ) : (
                    results.map((result, index) => (
                        <div
                            className={classes.result_container}
                            key={index}
                            onClick={e => {
                                e.preventDefault();
                                navigate(`/dashboard/${result.username}`);
                                setFocus(false);
                            }}>
                            <Imagify
                                src={result.profile_picture}
                                alt='profile'
                                className={classes.result_profile_img}
                            />
                            <div className={classes.result_info_container}>
                                <h1 className={classes.result_name}>{result.username}</h1>
                                <p className={classes.result_level}>
                                    {t('dashboard:level')} {result.level}
                                </p>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default SearchBar;
