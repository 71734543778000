import React, { useEffect, useRef, useState } from 'react';

export default function TimerDown({ GameListener, isTicTacToe = false, Seconds }) {
    const [seconds, setSeconds] = useState(isTicTacToe ? Seconds : 180);
    const lastUpdate = useRef(0);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    useEffect(() => {
        lastUpdate.current = 0;
    }, []);

    GameListener({
        game_update: data => {
            if (data.data.timer === lastUpdate.current) return;
            setSeconds(seconds - data.data.timer);
            lastUpdate.current = data.data.timer;
        }
    });

    const formattedSeconds =
        remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return isTicTacToe ? (
        <>
            <span
                style={{
                    color: `${Seconds < 10 ? '#f95a5a' : '#fff'}`
                }}>
                {Seconds % 60 < 10 ? `0${Seconds % 60}` : Seconds % 60}
            </span>
        </>
    ) : (
        <>
            <span>{minutes}</span>:<span>{formattedSeconds}</span>
        </>
    );
}
