import React, { useEffect, useState } from 'react';
import '../../../../../App.css';
import style from './Waiting.module.css';
import Pending from '../../../../../components/FriendCardInfo/Pending/Pending';
import BeforeGameGameBoy from '../../BeforeGameGameBoy/BeforeGameGameBoy';
import { useTranslation } from 'react-i18next';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
        return <Pending />;
    }

    return (
        <div className={style.timer}>
            <div className={style.value}>{remainingTime}</div>
        </div>
    );
};

export default function Waiting({
    waitingDuration,
    readyToStart,
    emitGame,
    setStartGame,
    Opponent,
    ConnectionLost,
    GameListener
}) {
    const { t } = useTranslation(['game']);
    const [message, setMessage] = useState(t('game:game_searching_for_opponent'));

    useEffect(() => {
        GameListener({
            opponent_timeout_loss: data => {
                setMessage(
                    `Opponent timeout in: ${30 - data.data.timer >= 10 ? 30 - data.data.timer : `0${30 - data.data.timer}`}`
                );
            }
        });
    }, [GameListener]);

    useEffect(() => {
        let timeoutId;

        if (readyToStart) {
            timeoutId = setTimeout(() => {
                setStartGame(true);
                emitGame('game_start_pong', { data: 'pong' });
            }, waitingDuration * 1000);
        }
        return () => clearInterval(timeoutId);
    }, [readyToStart]);

    return (
        <div className={style.waitingContainer}>
            {readyToStart || ConnectionLost ? (
                <>
                    {ConnectionLost ? (
                        <div className={style.connectionLost}>
                            <h1 className={style.startGameTitle}>
                                {Opponent.username} {t('game:connection_lost')}
                            </h1>
                            <h3 className={style.startGameTitle}>{t('game:waiting')}</h3>
                        </div>
                    ) : (
                        <h1 className={style.startGameTitle}>{t('game:get_ready')}</h1>
                    )}
                    <CountdownCircleTimer
                        style={{ width: '100%' }}
                        trailColor='transparent'
                        isPlaying
                        duration={waitingDuration}
                        colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                        colorsTime={ConnectionLost ? [10, 7, 3, 0] : [3, 2, 1, 0]}
                        onComplete={() => ({ shouldRepeat: false, delay: 0 })}>
                        {renderTime}
                    </CountdownCircleTimer>
                </>
            ) : (
                <>
                    <h1 className={style.startGameTitle}>{message}</h1>
                    <div className={style.before_game_container}>
                        <BeforeGameGameBoy />
                    </div>
                </>
            )}
        </div>
    );
}
