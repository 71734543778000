import '../../App.css';
import styles from './TabLayoutSelector.module.css';
import React, { useEffect, useState } from 'react';

export default function TabLayoutSelector({
    optionOneTitle,
    optionTwoTitle,
    optionOneIcon,
    optionTwoIcon,
    setSelectedOption,
    optionType = 'none',
    selectedOption,
    orientation = 'v',
    extraClass = '',
	setIsOnlineGame
}) {
    const [selectedTab, setSelectedTab] = useState(0);
    let selectorMargin = 10;
    let selectorActiveLocation = 'translateX(calc(100% - 19px)';

    useEffect(() => {
        if (optionType === 'none') {
            setSelectedOption(selectedTab);
        } else if (optionType === 'game') {
            setSelectedOption(selectedTab === 0 ? 'PingPong' : 'TicTacToe');
        } else if (optionType === 'online') {
            setSelectedOption(selectedTab === 0 ? 'online' : 'local');
			setIsOnlineGame(selectedTab === 0);
		}
    }, [selectedTab]);

    useEffect(() => {
        if (optionType === 'game') {
            setSelectedOption(selectedOption === 'PingPong' ? 'PingPong' : 'TicTacToe');
			setSelectedTab(selectedOption === 'PingPong' ? 0 : 1);
		}
        if (optionType === 'online') {
            setSelectedOption(selectedOption === 'online' ? 'online' : 'local');
			setSelectedTab(selectedOption === 'online' ? 0 : 1);
		}
    }, [selectedOption]);

    if (orientation !== 'v') {
        selectorMargin = 6;
        selectorActiveLocation = 'translateX(calc(100% - 11px)';
    }

    return (
        <div className={`${styles.tablayout_container} ${extraClass}`}>
            <div className={styles.options_holder}>
                <div
                    onClick={() => setSelectedTab(0)}
                    className={`${styles.option_container} ${orientation === 'v' ? styles.vertical_tab : ''}`}>
                    <img src={`/assets/icons/${optionOneIcon}.svg`} alt={optionOneIcon} />
                    <h1>{optionOneTitle}</h1>
                </div>
                <div
                    onClick={() => setSelectedTab(1)}
                    className={`${styles.option_container} ${orientation === 'v' ? styles.vertical_tab : ''}`}>
                    <img src={`/assets/icons/${optionTwoIcon}.svg`} alt={optionTwoIcon} />
                    <h1> {optionTwoTitle}</h1>
                </div>
            </div>
            <div
                className={`${styles.tab_selector} ${selectedTab === 1 ? styles.tabtwo : ''}`}
                style={{
                    '--selector-margin': `0 ${selectorMargin}px`,
                    '--selector-active-location': `${selectorActiveLocation}`
                }}></div>
        </div>
    );
}
