import '../../../../App.css';
import 'react-toastify/dist/ReactToastify.css';
import React, { useContext, useEffect, useState } from 'react';
import classes from './TournamentRoom.module.css';
import LogsCard from '../../../../components/LogsCard/LogsCard';
import Imagify from '../../../../components/Imagify/Imagify';
import LobbyCard from '../LobbyCard/LobbyCard';
import api from '../../../../api';
import SocketContext from '../../../../context/SocketContext';
import Button from '../../../../components/FriendsButton/FriendsButton';
import Skeleton from 'react-loading-skeleton';
import UserContext from '../../../../context/UserContext';
import NoDataView from '../../../../components/NoDataView/NoDataView';
import GameContext from '../../../../context/GameContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function TournamentRoom({ Room, setRoom }) {
    const { user } = useContext(UserContext);
    const { socket, messageListener } = useContext(SocketContext);
    const { setTournamentRooms } = useContext(GameContext);
    const [buttonState, setButtonState] = useState(false);
    const [userAlias, setUserAlias] = useState(null);
    const [joinState, setJoinState] = useState(true);
    const [data, setData] = useState(null);
    const { t } = useTranslation(['tournaments', 'common']);
    const [showNicknameInput, setShowNicknameInput] = useState(false);

    const addParticipant = newParticipant => {
        setData(prevTournament => {
            if (!prevTournament) {
                return {
                    ...prevTournament,
                    participants: {
                        player_1: newParticipant
                    }
                };
            }

            return {
                ...prevTournament,
                participants: {
                    ...prevTournament.participants,
                    [`player_${Object.keys(prevTournament.participants).length + 1}`]:
                        newParticipant
                },
                nicknames: [
                    ...prevTournament.nicknames,
                    [newParticipant.username, newParticipant.nickname]
                ]
            };
        });
    };

    const addLobby = newLog => {
        setData(prevTournament => ({
            ...prevTournament,
            Lobby: [...prevTournament.Lobby, newLog]
        }));
    };

    const addGameLogs = newLog => {
        setData(prevTournament => ({
            ...prevTournament,
            games_logs: [...prevTournament.games_logs, newLog]
        }));
    };

    function getUserNickName(username) {
        if (!data) return '';
        const nickname = data.nicknames.find(nickname => nickname[0] === username);
        return nickname ? nickname[1] : '';
    }

    const removeParticipant = usernameToRemove => {
        setData(prevTournament => {
            if (!prevTournament) return prevTournament;
            const updatedParticipants = Object.keys(prevTournament.participants)
                .filter(
                    key => prevTournament.participants[key].username !== usernameToRemove
                )
                .reduce((result, key, index) => {
                    result[`player_${index + 1}`] = prevTournament.participants[key];
                    return result;
                }, {});

            return {
                ...prevTournament,
                participants: updatedParticipants,
                nicknames: prevTournament.nicknames.filter(
                    nickname => nickname[0] !== usernameToRemove
                )
            };
        });
    };

    useEffect(() => {
        fetchRoomData(Room, setRoom, setData);
    }, []);

    const addWinner = newData => {
        setData(prevTournament => ({
            ...prevTournament,
            winner: newData
        }));
    };

    useEffect(() => {
        if (!socket) return;

        messageListener({
            inform_tournament_joined: data => {
                if (Room !== data.data.tournament_id) return;
                addParticipant(data.data);
            },
            inform_tournament_left: data => {
                if (Room !== data.data.tournament_id) return;
                removeParticipant(data.data.username);
                setData(prevTournament => ({
                    ...prevTournament,
                    state: 'WAITING'
                }));
                setButtonState(false);
            },
            inform_tournament_ready_to_start: () => {
                setButtonState(true);
            },
            inform_tournament_started: data => {
                addLobby(data.data);
                setData(prevTournament => ({
                    ...prevTournament,
                    state: 'STARTED'
                }));
            },
            tournament_match_update: data => {
                if (Room !== data.data.tournament_id) return;
                addGameLogs(data.data);
            },
            tournament_finished: data => {
                if (Room !== data.data.tournament_id) return;
                addWinner(data.data);
            }
        });
    }, [messageListener, socket]);

    function getWinner(player1, player2) {
        if (
            player1 === null ||
            player2 === null ||
            player1 === undefined ||
            player2 === undefined
        )
            return null;
        else if (data.games_logs.length === 0) return null;
        else {
            for (let i = 0; i < data.games_logs.length; i++) {
                if (
                    (data.games_logs[i].player1_username === player1.username &&
                        data.games_logs[i].player2_username === player2.username) ||
                    (data.games_logs[i].player1_username === player2.username &&
                        data.games_logs[i].player2_username === player1.username)
                ) {
                    if (
                        data.games_logs[i].player1_score >
                        data.games_logs[i].player2_score
                    ) {
                        return data.games_logs[i].player1_username === player1.username
                            ? player1
                            : player2;
                    } else {
                        return data.games_logs[i].player2_username === player1.username
                            ? player1
                            : player2;
                    }
                }
            }
        }
        return null;
    }

    useEffect(() => {
        if (!data) return;
        if (
            Object.values(data.participants).some(
                participant => participant.username === user.username
            )
        )
            setJoinState(false);

        if (data.state === 'READY') setButtonState(true);
    }, [data]);

    return (
        <div className={`${classes.TournamentRoom}`}>
            <div className={classes.title}>
                <div className={classes.tournament_title_holder}>
                    <div
                        onClick={() => {
                            setRoom(-1);
                        }}
                        className={classes.back}>
                        <Imagify
                            alt='Back arrow'
                            src='/assets/icons/ic_arrow.svg'></Imagify>
                    </div>
                    <h1>{data && data.name}</h1>
                </div>

                {data &&
                data.admin.username === user.username &&
                data.state !== 'STARTED' &&
                data.state !== 'FINISHED' ? (
                    <div className={classes.admin_buttons}>
                        <Button
                            Color2={buttonState ? '#12911F' : '#4441BA'}
                            Color1={buttonState ? '#12911F' : '#4441BA'}
                            className={'all-parent'}
                            Icon={
                                buttonState
                                    ? '/assets/icons/ic_start_tournament.svg'
                                    : '/assets/icons/ic_tournament_waiting.svg'
                            }
                            Text={
                                buttonState
                                    ? t('tournaments:start')
                                    : t('tournaments:waiting')
                            }
                            Spinner={buttonState ? false : true}
                            onClick={e => {
                                e.preventDefault();
                                if (!buttonState) return;
                                StartTournament(Room, setRoom);
                            }}
                        />
                        <Button
                            Color2={'#FE4343'}
                            Color1={'#FE4343'}
                            className={'all-parent'}
                            Icon={'/assets/icons/ic_remove.svg'}
                            Text={t('tournaments:remove')}
                            Spinner={false}
                            onClick={e => {
                                e.preventDefault();
                                DeleteTournament(Room, setRoom, setTournamentRooms);
                            }}
                        />
                    </div>
                ) : (
                    data &&
                    data.state !== 'STARTED' &&
                    data.state !== 'FINISHED' &&
                    (!showNicknameInput ? (
                        <Button
                            Color2={joinState ? '#12911F' : '#FE4343'}
                            Color1={joinState ? '#12911F' : '#FE4343'}
                            className={'ml-auto'}
                            Icon={
                                joinState
                                    ? '/assets/icons/ic_plus.svg'
                                    : '/assets/icons/ic_logout.svg'
                            }
                            Text={
                                joinState ? t('tournaments:join') : t('tournaments:exit')
                            }
                            imageClass={classes.icon_resize}
                            onClick={e => {
                                e.preventDefault();
                                if (!joinState) {
                                    ExitTournament(Room, setRoom, t);
                                } else {
                                    setShowNicknameInput(true);
                                }
                            }}
                        />
                    ) : (
                        <>
                            <div className={classes.input_box}>
                                <Imagify
                                    src='/assets/icons/ic_joystick.svg'
                                    alt='Joystick'
                                />
                                <input
                                    maxLength={20}
                                    minLength={3}
                                    required
                                    type='text'
                                    placeholder={t(
                                        'tournaments:input_nickname_place_holder'
                                    )}
                                    onChange={e => {
                                        setUserAlias(e.target.value);
                                    }}
                                />
                                <button
                                    className={classes.input_button}
                                    onClick={e => {
                                        e.preventDefault();
                                        JoinTournament(Room, t, setJoinState, userAlias);
                                        setShowNicknameInput(false);
                                    }}>
                                    <p>{t('tournaments:join')}</p>
                                </button>
                            </div>
                        </>
                    ))
                )}
            </div>
            <div className={`${classes.knockout_container} background_primary`}>
                <div className={classes.leftSide}>
                    <div className={classes.player__1}>
                        {data && data.participants.player_1 ? (
                            <Imagify
                                alt='Avatar'
                                src={
                                    data.participants.player_1.profile_picture
                                }></Imagify>
                        ) : (
                            <Skeleton height={45} width={45} circle={true} />
                        )}
                        <div className={classes.player_name}>
                            {data && data.participants.player_1 ? (
                                <h1>
                                    {getUserNickName(data.participants.player_1.username)}
                                </h1>
                            ) : (
                                <Skeleton height={20} width={100} />
                            )}
                        </div>
                    </div>

                    <div className={classes.player__2}>
                        {data &&
                        getWinner(
                            data.participants.player_1,
                            data.participants.player_2
                        ) !== null ? (
                            <Imagify
                                alt='Avatar'
                                src={
                                    getWinner(
                                        data.participants.player_1,
                                        data.participants.player_2
                                    ).profile_picture
                                }></Imagify>
                        ) : (
                            <Skeleton height={45} width={45} circle={true} />
                        )}
                        <div className={classes.player_name}>
                            {data &&
                            getWinner(
                                data.participants.player_1,
                                data.participants.player_2
                            ) !== null ? (
                                <h1>
                                    {getUserNickName(
                                        getWinner(
                                            data.participants.player_1,
                                            data.participants.player_2
                                        ).username
                                    )}
                                </h1>
                            ) : (
                                <Skeleton height={20} width={100} />
                            )}
                        </div>
                    </div>

                    <div className={classes.player__3}>
                        {data && data.participants.player_2 ? (
                            <Imagify
                                alt='Avatar'
                                src={
                                    data.participants.player_2.profile_picture
                                }></Imagify>
                        ) : (
                            <Skeleton height={45} width={45} circle={true} />
                        )}
                        <div className={classes.player_name}>
                            {data && data.participants.player_2 ? (
                                <h1>
                                    {getUserNickName(data.participants.player_2.username)}
                                </h1>
                            ) : (
                                <Skeleton height={20} width={100} />
                            )}
                        </div>
                    </div>
                    <div className={classes.knockout__lines}></div>
                </div>
                <div className={classes.center}>
                    <h1 className={classes.knockout_title}>
                        {t('tournaments:knockout_stage')}
                    </h1>
                    <div className={classes.center_container}>
                        <Imagify
                            className={classes.center_trophy}
                            alt='test'
                            src='/assets/images/winner_bg.png'></Imagify>
                        <div className={classes.center_info_container}>
                            {data && data.winner !== null ? (
                                <>
                                    <Imagify
                                        alt='Avatar'
                                        src={data.winner.profile_picture}></Imagify>
                                    <h1>{getUserNickName(data.winner.username)}</h1>
                                </>
                            ) : (
                                <div className={classes.winnerSkeleton}>
                                    <Skeleton height={60} width={60} circle={true} />
                                    <Skeleton height={20} width={100} />
                                </div>
                            )}
                        </div>
                        <div className={classes.center_container__overlay}></div>
                    </div>
                    <h1 className={classes.winner_title}>{t('tournaments:winner')}</h1>
                    <div className={classes.knockout__line_center}></div>
                </div>
                <div className={classes.rightSide}>
                    <div className={classes.player__1}>
                        {data && data.participants.player_3 ? (
                            <Imagify
                                alt='Avatar'
                                src={
                                    data.participants.player_3.profile_picture
                                }></Imagify>
                        ) : (
                            <Skeleton height={45} width={45} circle={true} />
                        )}
                        <div className={classes.player_name}>
                            {data && data.participants.player_3 ? (
                                <h1>
                                    {getUserNickName(data.participants.player_3.username)}
                                </h1>
                            ) : (
                                <Skeleton height={20} width={100} />
                            )}
                        </div>
                    </div>

                    <div className={classes.player__4}>
                        {data &&
                        getWinner(
                            data.participants.player_3,
                            data.participants.player_4
                        ) !== null ? (
                            <Imagify
                                alt='Avatar'
                                src={
                                    getWinner(
                                        data.participants.player_3,
                                        data.participants.player_4
                                    ).profile_picture
                                }></Imagify>
                        ) : (
                            <Skeleton height={45} width={45} circle={true} />
                        )}
                        <div className={classes.player_name}>
                            {data &&
                            getWinner(
                                data.participants.player_3,
                                data.participants.player_4
                            ) !== null ? (
                                <h1>
                                    {getUserNickName(
                                        getWinner(
                                            data.participants.player_3,
                                            data.participants.player_4
                                        ).username
                                    )}
                                </h1>
                            ) : (
                                <Skeleton height={20} width={100} />
                            )}
                        </div>
                    </div>
                    <div className={classes.player__3}>
                        {data && data.participants.player_4 ? (
                            <Imagify
                                alt='Avatar'
                                src={
                                    data.participants.player_4.profile_picture
                                }></Imagify>
                        ) : (
                            <Skeleton height={45} width={45} circle={true} />
                        )}
                        <div className={classes.player_name}>
                            {data && data.participants.player_4 ? (
                                <h1>
                                    {getUserNickName(data.participants.player_4.username)}
                                </h1>
                            ) : (
                                <Skeleton height={20} width={100} />
                            )}
                        </div>
                    </div>
                    <div className={classes.knockout__lines}></div>
                </div>
            </div>
            <div className={`${classes.matches} background_primary`}>
                <div className={classes.title_container}>
                    <div className={classes.leftLine}></div>
                    <h1 className={classes.match_details}>
                        {t('tournaments:match_details')}
                    </h1>
                    <div className={classes.rightLine}></div>
                </div>

                <div className={classes.content_container}>
                    <div className={classes.logs_container}>
                        <div className={`${classes.logs} background_primary`}>
                            <p>{t('tournaments:game_logs')}</p>
                        </div>
                        {data && data.games_logs.length > 0 ? (
                            data.games_logs.map(log => {
                                return <LogsCard Logs={log} key={log.id} />;
                            })
                        ) : (
                            <NoDataView
                                title={t('tournaments:no_game_logs')}
                                subtitle={t('tournaments:no_game_logs_desc')}
                            />
                        )}
                    </div>
                    <div className={classes.line_break}></div>
                    <div className={classes.lobby_container}>
                        <div className={`${classes.lobby} background_primary`}>
                            <p>{t('tournaments:lobby')}</p>
                        </div>
                        {data && data.Lobby.length > 0 ? (
                            data.Lobby.filter(
                                log =>
                                    log.player1_username === user.username ||
                                    log.player2_username === user.username
                            ).map(log => (
                                <LobbyCard
                                    key={log.id}
                                    Lobby={log}
                                    tournamentId={Room}
                                    game={data.game}
                                />
                            ))
                        ) : (
                            <NoDataView
                                title={t('tournaments:tournament_will_start')}
                                subtitle={t('tournaments:tournament_will_start_desc')}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

async function JoinTournament(room, t, setJoinState, userAlias) {
    await api
        .post('/tournaments/join-tournament/', {
            tournament_id: room,
            userNickname: userAlias
        })
        .then(() => {
            setJoinState(false);
            toast.success(t('tournaments:msg_joined'), {
                position: 'bottom-right',
                autoClose: 1000,
                draggable: true,
                closeOnClick: true
            });
        })
        .catch(() => {
            setJoinState(true);
            toast.error(t('tournaments:msg_failed_join'), {
                position: 'bottom-right',
                autoClose: 1000,
                draggable: true,
                closeOnClick: true
            });
        });
}

async function fetchRoomData(room, setRoom, setData) {
    await api
        .get('/tournaments/rooms/' + room + '/')
        .then(response => {
            setData(response.data);
        })
        .catch(() => {
            setRoom(-1);
        });
}

async function ExitTournament(room, setRoom, t) {
    await api
        .post('/tournaments/exit-tournament/', {
            tournament_id: room
        })
        .then(() => {
            toast.success(t('tournaments:msg_exited'), {
                position: 'bottom-right',
                autoClose: 1000,
                draggable: true,
                closeOnClick: true
            });
            setRoom(-1);
        })
        .catch(() => {
            setRoom(-1);
        });
}

async function StartTournament(room, setRoom) {
    await api
        .post('/tournaments/start-tournament/', {
            tournament_id: room
        })
        .then(() => {})
        .catch(() => {
            setRoom(-1);
        });
}

async function DeleteTournament(room, setRoom, setTournamentRooms) {
    await api
        .post('/tournaments/delete-tournament/', {
            tournament_id: room
        })
        .then(() => {
            setRoom(-1);
            setTournamentRooms(prevTournaments => {
                return prevTournaments.filter(tournament => tournament.id !== room);
            });
        })
        .catch(() => {});
}

export default TournamentRoom;
