import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../api';
import UserContext from './UserContext';

const GameContext = createContext(null);

export const GameProvider = ({ children }) => {
    const { isLoggedIn } = useContext(UserContext);
    const [gameSocket, setGameSocket] = useState(null);
    const [startGame, setStartGame] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [Opponent, setOpponent] = useState(null);
    const [gameResult, setGameResult] = useState(null);
    const [requestGame, setRequestGame] = useState(false);
    const [readyToStart, setReadyToStart] = useState(false);
    const [ConnectionLost, setConnectionLost] = useState(false);
    const [tournamentRooms, setTournamentRooms] = useState([]);

    const GameConnect = url => {
        const newSocket = new WebSocket(process.env.REACT_APP_WS_URL + url);
        newSocket.onopen = () => {
            setGameSocket(newSocket);
        };
    };

    const disconnectGame = () => {
        if (gameSocket) {
            gameSocket.close();
            setGameSocket(null);
        }
    };


    const emitGame = (eventName, data) => {
        if (gameSocket && gameSocket.readyState === WebSocket.OPEN) {
            gameSocket.send(JSON.stringify({ event: eventName, data }));
        }
    };

    async function getTournamentRooms(filter = 'all') {
        await api
            .get('/tournaments/get_tournaments/' + filter + '/')
            .then(res => {
                setTournamentRooms(res.data.tournaments);
            })
            .catch(() => {
            });
    }

    const on = eventListeners => {
        if (gameSocket) {
            gameSocket.onmessage = event => {
                const data = JSON.parse(event.data);
                const matchingListener = eventListeners[data.event];
                if (matchingListener) {
                    matchingListener(data);
                }
            };
        }
    };

    const GameListener = eventListeners => {
        if (gameSocket)
            gameSocket.addEventListener('message', event => {
                const data = JSON.parse(event.data);
                const matchingListener = eventListeners[data.event];
                if (matchingListener) {
                    matchingListener(data);
                }
            });
    };

    const GameClose = () => {
        if (gameSocket) {
            gameSocket.onclose = () => {
                setGameSocket(null);
            };
            gameSocket.onerror = () => {
                setGameSocket(null);
            };
            gameSocket.close();
            setGameSocket(null);
        }
    };

    useEffect(() => {
        if (isLoggedIn === false) return;
        getTournamentRooms();
        GameConnect('/ws/play/');
    }, [isLoggedIn]);

    return (
        <GameContext.Provider
            value={{
                readyToStart,
                ConnectionLost,
                gameSocket,
                requestGame,
                startGame,
                gameOver,
                Opponent,
                gameResult,
                tournamentRooms,
                setReadyToStart,
                setConnectionLost,
                setRequestGame,
                GameConnect,
                emitGame,
                on,
                GameClose,
                GameListener,
                setStartGame,
                setGameOver,
                setOpponent,
                setGameResult,
                setTournamentRooms,
                disconnectGame,
            }}>
            {children}
        </GameContext.Provider>
    );
};

export default GameContext;
