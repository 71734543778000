import React, { useState, useEffect } from 'react';

const AnimatedCounter = ({ endValue, joinWith, style }) => {
    const [nextValue, setNextValue] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            if (nextValue < endValue) {
                setNextValue(prevNextValue => prevNextValue + 1);
            } else {
                clearInterval(interval);
            }
        });

        return () => clearInterval(interval);
    }, [nextValue, endValue]);

    return (
        <p className={style}>
            {endValue}
            {joinWith}
        </p>
    );
};

export default AnimatedCounter;
