import '../../../../App.css';
import React, { useEffect, useContext, useState } from 'react';
import classes from './Contacts.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import Skeleton from 'react-loading-skeleton';
import ChatMessageItem from '../ChatMessageItem/ChatMessageItem';
import ShimmerList from '../../../../components/ShimmerList/ShimmerList';
import UserContext from '../../../../context/UserContext';
import ChatSocketContext from '../../../../context/ChatSocketContext';
import NoDataView from '../../../../components/NoDataView/NoDataView';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function Contacts() {
    const { chattingWithQuery } = useParams();
    const { t } = useTranslation(['common', 'messages']);
    const { user, setUserData, setCoverImageUrl, fetchUserData } = useContext(UserContext);
    const { loaded, conversationsList } = useContext(ChatSocketContext);
    const [input, setInput] = useState('');

    useEffect(() => {
        if (chattingWithQuery) {
            fetchUserData(setUserData, chattingWithQuery, setCoverImageUrl, t);
        }
    }, [chattingWithQuery]);

    return (
        <div className={chattingWithQuery ? classes.active_chat : classes.chat_contact}>
            <div className={classes.my_profile}>
                <div className={classes.profile_pic}>
                    {user ? (
                        <Imagify
                            className={classes.profile_image}
                            src={user.profile_picture}
                            alt='my profile'
                        />
                    ) : (
                        <Skeleton circle={true} width={50} height={50} />
                    )}
                    <div className={classes.status}></div>
                </div>
                <div className={classes.name_status}>
                    {user ? (
                        <h1>
                            {user.first_name} {user.last_name}
                        </h1>
                    ) : (
                        <Skeleton width={150} />
                    )}
                    <p> {t('available')}</p>
                </div>
            </div>

            <div className={classes.contact_list}>
                <div className={classes.search_input_container}>
                    <img src='/assets/icons/ic_search.svg' alt='search icon' />
                    <input
                        type='text'
                        id='myInput'
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        placeholder={t('common:search')}
                    />
                </div>
                <div id='ContactsList_ul' className={classes.contacts_ul}>
                    {conversationsList && conversationsList.length > 0 ? (
                        conversationsList
                            .filter(contact => {
                                const inputLower = input.toLowerCase();
                                const participant = contact.conversation.participant;
                                const username = participant.username;
                                const fullName = `${participant.first_name} ${participant.last_name}`;
                                return (
                                    username.toLowerCase().includes(inputLower) ||
                                    fullName.toLowerCase().includes(inputLower)
                                );
                            })
                            .map(contact => (
                                <ChatMessageItem
                                    key={contact.conversation.conversation_id}
                                    contact={contact}
                                />
                            ))
                    ) : loaded ? (
                        <NoDataView
                            title={t('messages:no_messages_title')}
                            subtitle={t('messages:no_messages_subtitle')}
                        />
                    ) : (
                        <ShimmerList count={5} />
                    )}
                </div>
            </div>
            <div className={classes.line_break}></div>
        </div>
    );
}

export default Contacts;
