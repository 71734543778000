import '../../App.css';
import style from './FriendsButton.module.css';
import Imagify from '../Imagify/Imagify';
import React from 'react';

export default function FriendsButton({
    Color1 = '#4441ba',
    onClick,
    Text,
    Icon,
    Color2 = '#4441ba',
    className,
    Spinner = false,
    imageClass
}) {
    return (
        <button
            className={`${style.signupBtn} ${className}`}
            onClick={onClick}
            style={{ backgroundColor: `${Color1}` }}>
            {Text}
            <span className={style.arrow} style={{ backgroundColor: `${Color2}` }}>
                <Imagify
                    src={Icon}
                    alt='icon'
                    className={`${Spinner ? style.infinite_smooth_spinner : ''} ${imageClass}`}
                />
            </span>
        </button>
    );
}
