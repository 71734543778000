import '../../../../App.css';
import React from 'react';
import classes from './ChatNotFound.module.css';
import Imagify from '../../../../components/Imagify/Imagify';
import { useTranslation } from 'react-i18next';

function ChatNotFound() {
    const { t } = useTranslation(['messages']);
    return (
        <div className={classes.chat_not_found}>
            <Imagify src='/assets/images/img_message.svg' alt='No chat' />
            <h1>{t('messages:no_chat_title')}</h1>
            <p>{t('messages:no_chat_subtitle')}</p>
        </div>
    );
}

export default ChatNotFound;
